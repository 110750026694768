import React from "react";
import { Text, View, StyleSheet, Font } from "@react-pdf/renderer";

Font.register({
  family: "Open Sans",
  fonts: [
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
    },

    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf",
      fontWeight: 600,
    },
  ],
});
const styles = StyleSheet.create({
  parent: {
    marginTop: 8,
    borderBottomColor: "black",
    //borderTopColor: "black",
    //borderTopWidth: 1,
    backgroundColor: "white",
    borderBottomWidth: 1,
    //marginBottom: 10,
    padding: 12,
  },
  container: {
    flexDirection: "row",
    height: 15,
    // marginTop: 25,
    // borderBottomColor: "black",
    // borderTopColor: "black",
    // borderTopWidth: 2,
    // backgroundColor: "white",
    // borderBottomWidth: 2,
    // marginBottom: 30,
    // padding: 20,
    textAlign: "center",

    flexGrow: 1,
  },

  data: {
    fontFamily: "Open Sans",
    fontWeight: 600,
    width: "45%",
    //   textTransform: "capitalize",
    color: "black",
    fontSize: 9,
    textAlign: "left",
  },
  data1: {
    fontFamily: "Open Sans",
    fontWeight: 600,
    width: "45%",
    textTransform: "capitalize",
    color: "black",
    fontSize: 9,
    textAlign: "left",
  },
  dataq: {
    width: "55%",
    color: "black",
    fontSize: 9,
    fontFamily: "Open Sans",
    fontWeight: 600,
    textAlign: "right",
  },
  dataqq: {
    width: "55%",
    color: "black",
    fontFamily: "Open Sans",
    fontWeight: 600,
    fontSize: 9,
    textAlign: "right",
  },
});
const InvoiceBillName = ({ singleDataList, headerPrint }) => (
  <View
    style={[
      styles.parent,
      { backgroundColor: headerPrint ? "#ffffb3" : "white" },
    ]}
  >
    <View style={styles.container}>
      <Text style={[styles.data, { color: "black" }]}>
        <b>Bill ID : HX{singleDataList.id}</b>
      </Text>
      <Text style={styles.dataq}>
        <Text style={styles.dataq}>PID: HX{singleDataList.patient}</Text>
      </Text>
    </View>
    <View style={styles.container}>
      <Text style={styles.data1}>
        Patient Name : {singleDataList.patient_name} ( {singleDataList.age} /
        {singleDataList.gender} )
      </Text>

      <Text style={styles.dataq}>
        <Text style={styles.text}>
          Referred By: {singleDataList.doctor_name}
        </Text>
      </Text>
    </View>
    <View style={styles.container}>
      <Text style={styles.data}>
        Billing Date :{" "}
        {new Date(singleDataList.transaction_date)
          .toLocaleString("en-in", { hour12: true })
          .split("  ")}
      </Text>
      <Text style={styles.dataqq}> {singleDataList.degree}</Text>
      {/* <Text style={styles.dataq}>Billing Time:</Text> */}
    </View>
    <View style={styles.container}>
      <Text style={styles.dataqq}></Text>
    </View>

    {/* <View style={styles.container}>
    
     
    </View> */}
  </View>
);

export default InvoiceBillName;
