import React from "react";
import { Text, Image, View, StyleSheet } from "@react-pdf/renderer";
import footer from "../../../src/footerLab.jpeg";
import { Font } from "@react-pdf/renderer";

Font.register({
  family: "Ubuntu",
  fonts: [
    {
      src: "https://fonts.gstatic.com/s/questrial/v13/QdVUSTchPBm7nuUeVf7EuStkm20oJA.ttf",
    },
    {
      src: "https://fonts.gstatic.com/s/questrial/v13/QdVUSTchPBm7nuUeVf7EuStkm20oJA.ttf",
      fontWeight: "bold",
    },
    {
      src: "https://fonts.gstatic.com/s/questrial/v13/QdVUSTchPBm7nuUeVf7EuStkm20oJA.ttf",
      fontWeight: "normal",
      fontStyle: "italic",
    },
  ],
});
const styles = StyleSheet.create({
  signContainer: {
    //marginTop: "10px",
    fontSize: 9,
  },
  image: {
    minWidth: "100%",
    maxHeight: "60px",
    objectFit: "cover",
  },
  sign1: {
    fontWeight: "extrabold",
  },
});

const InvoiceImageFooter = () => {
  return (
    <>
      <View fixed>
        <Image style={styles.image} src={footer}></Image>
        <Text style={styles.sign1}></Text>
      </View>
    </>
  );
};

export default InvoiceImageFooter;
