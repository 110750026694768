import React, { useState, useEffect } from "react";
import Header from "../../Components/Header/Header";
import { Button } from "@material-ui/core";
import ReactToPdf from "../../Pages/CombineReactToPdf";
import { PDFDownloadLink } from "@react-pdf/renderer";
//import { Oval } from "react-loader-spinner";
import PatientEntrySkeleton from "../../Components/SkeletonComponents/PatientEntrySkeleton";
import { getRequest } from "../../Components/Constants/ApiCall";

const ReportTransactionWise = (props) => {
  const [report, setReport] = useState({});
  const [patientData, setPatientData] = useState([]);
  const [headerPrint, setHeaderPrint] = useState(false);

  const getReport = async () => {
    try {
      const response = await getRequest(
        `/lab/report/transaction/${props.match.params.id}`
      );
      const data = await response.json();
      if (response.status === 200) arrangeJSON(data);
    } catch (err) {
      console.log(err);
    }
  };

  const getPatientData = async () => {
    const response2 = await getRequest(
      `/lab/transaction/${props.match.params.id}`
    );
    const data = await response2.json();
    if (response2.status === 200) {
      setPatientData(data[0]);

      console.log("pd", data);
    }
  };

  const arrangeJSON = (data) => {
    const report = {};
    data.map((item) => (report[item.test_name] = []));
    data.map((item) => report[item.test_name].push(item));
    console.log("report keys", Object.keys(report));
    // eslint-disable-next-line array-callback-return
    Object.keys(report).map((item) => {
      var order = report[item][0].sequence.split(",");
      var sorted_data = report[item].sort(function (a, b) {
        return (
          order.indexOf(a.routine.toString()) -
          order.indexOf(b.routine.toString())
        );
        
      });
      report[item] = sorted_data;
      console.log("sortedData",sorted_data)

    });
    console.log("REPORT", report);
    console.log("reports", Object.keys(report).length);
    setReport(report);
  };
  useEffect(() => {
    getReport();
    getPatientData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <Header />
      <div className="inner_page box">
        <div class="container">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title float-left">
               Update Test Report
              </h4>
              <div class="form-check"  className="float-right">
                <input
                  type="checkbox"
                  class="form-check-input"
                  id="exampleCheck1"
                  onChange={() => setHeaderPrint(!headerPrint)}
                />
                <label class="form-check-label" for="exampleCheck1">
                  Print for share
                </label>
              </div>
              <div className="float-right">
                {" "}
                <PDFDownloadLink
                  document={
                    <ReactToPdf
                      singleDataList={patientData}
                      headerPrint={headerPrint}
                      reportData={report}
                    />
                  }
                  fileName={patientData.patient_name}
                >
                  {({ loading }) =>
                    loading ? (
                      <Button variant="outlined" color="secondary">
                       keep wait
                      </Button>
                    ) : (
                      <Button variant="outlined" color="primary">
                        Generate Pdf
                      </Button>
                    )
                  }
                </PDFDownloadLink>
              </div>
            </div>

            <div class="modal-body">
              <div
                className="no-gutters mb-3 hx-input_control"
                style={{ backgroundColor: "#eee" }}
              >
                <div class="d-flex justify-content-between">
                  <div>
                    <p className="p-2 mb-0 text-primary">
                      PID: HX{patientData.patient}
                    </p>
                    <p
                      className="p-2 text-primary"
                      style={{ marginTop: "-10px" }}
                    >
                      Name:{" "}
                      <b>
                        {patientData.patient_name} ({patientData.age},{" "}
                        {patientData.gender} )
                      </b>
                    </p>
                    <p
                      className="p-2 mb-0 text-primary"
                      style={{ marginTop: "-20px" }}
                    >
                      <b>Booked:</b>
                    </p>
                  </div>
                  <div>
                    <p className="p-2 mb-0 mr-2 mt-2 text-primary">
                      <b>
                        Ref.Doctor: {patientData.doctor_name}
                        <br /> {patientData.degree}
                      </b>
                    </p>
                    <p className="p-2 mb-0 mr-2 mt-2 text-primary">
                      <label>Sample collected on</label>
                      <input
                        type="date"
                        className="form-control calendar "
                        name="dob"
                        id="dov"
                        placeholder=""
                      />
                    </p>
                  </div>
                </div>
              </div>

              <div class="container table-responsive">
                {report.length === 0 ? (
                  <>
                    <PatientEntrySkeleton />
                  </>
                ) : (
                  <table class="table">
                    <thead>
                      <tr className="table-bordered">
                        <th scope="col">Test Parameter</th>
                        <th scope="col">Values</th>
                        <th scope="col">Units</th>
                        <th scope="col">Biological Ref. Interval</th>
                      </tr>
                    </thead>

                    <tbody>
                      {Object.entries(report).map(([key, value]) => (
                        <>
                          {value.length !== 1 ? (
                            <>
                              <tr
                                style={{ backgroundColor: "#F4F4F4" }}
                                className="table-bordered"
                                key={value.id}
                              >
                                <th scope="col">
                                  {value.length === 1 ? "" : key}
                                </th>
                                <th scope="col"></th>
                                <th scope="col"></th>
                                <th scope="col"></th>
                              </tr>
                            </>
                          ) : (
                            <>
                              <tr style={{ border: "2px solid black" }}></tr>
                            </>
                          )}

                          <>
                            {value.map((item, index) => (
                              <tr className="table-bordered" key={index}>
                                <td>{item.routine_name}</td>
                                <td>{item.value}</td>
                                <td>
                                  {" "}
                                  {item.unit === "--" ||
                                  item.unit === "---" ||
                                  item.unit === "-"
                                    ? ""
                                    : item.unit}
                                </td>
                                <td>
                                  {item.refrence === "--" ||
                                  item.refrence === "---" ||
                                  item.refrence === "-"
                                    ? ""
                                    : item.refrence}
                                </td>
                              </tr>
                            ))}
                          </>
                        </>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
            {/* <Button
                variant="contained"
                color="primary"
                onClick={(e) => updateReport(e)}
              >
                Update Report
              </Button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportTransactionWise;
