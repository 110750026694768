/* eslint-disable no-redeclare */
import React, { useState, useEffect } from "react";
import { getRequest, postRequest } from "../Components/Constants/ApiCall";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Header from "../Components/Header/Header";

const ManageAccount = () => {
  const [show, setShow] = useState(false);
  const [userId, setuserId] = useState("");
  const [userName, setName] = useState("");
  const [permissions, setPermissions] = useState({
    ap: false, //add patient
    pl: false, //patient master list
    lr: false, // Lab reporting
    lpa: false, // Lab Pending approved
    lta: false, //Lab transaction All
    te: false, //lab transaction by Entry
    ma: false, // Manage Account
    tl: false, // test List
    ldt: false, //Lab Dues Transactions
    vpe: false, // View Patient by Entry
  });
  const [user, setUser] = useState([]);
  console.log("id1", userId);

  const getUserData = async () => {
    try {
      const response = await getRequest(`/auth/useraccess/1`);
      const data = await response.json();
      if (response.status === 200 || response.status === 201) {
        const newUserData = data.filter((item) => item.access_type === "lab");
        setUser(newUserData);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const updateHandler = async (id, index, name) => {
    try {
      setuserId(id);
      setTimeout(() => {
        setShow(true);
      }, 1000);

      const permissionsCopy = { ...permissions };
      // eslint-disable-next-line no-undef
      if (user[index].access_role["role"]) {
        console.log("inde", user[index].access_role);
        user[index].access_role["role"].map((item) =>
          permissionsCopy[item.toLowerCase()] === true
            ? (permissionsCopy[item.toLowerCase()] = false)
            : (permissionsCopy[item.toLowerCase()] = true)
        );
        console.log("perCopy", permissionsCopy);

        setPermissions({ ...permissionsCopy });
        setName(name);
        console.log("index", index);
      } else {
      }
    } catch (err) {
      console.log(err);
    }
  };
  console.log("permit", userName, userId);
  const updateForm = async (e, id) => {
    e.preventDefault();

    try {
      console.log("id11", userId);

      const role = Object.keys(permissions).filter(
        (item) => permissions[item] === true
      );
      const body = { access_role: { role: role } };
      console.log("Ad", body);

      var res = await postRequest(
        `/auth/useraccess/${id}`,
        JSON.stringify(body),
        "PUT",
        true
      );
      var responseData = await res.json();
      console.log("account_user", responseData);
      console.log("Response", res, body);
      if (res.status === 200 || res.status === 201) {
        // alert("Successfully Added Permission ");
        // alert("Successfully Added Permission ");
        toast.success("Successfully Added Permission", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        // window.location.reload();
      }
    } catch (error) {
      console.log(error);
    }
  };

  // const updateChanges = async (e, id) => {
  //   e.preventDefault();
  //   try {
  //     let formData = new FormData();
  //     formData.append("full_name", formdata.full_name);
  //     formData.append("mobile", formdata.mobile);
  //     fetch(`${api_url}/auth/profile/update/${id}`, {
  //       method: "PATCH",
  //       header: {},
  //       body: formData,
  //     }).then(function (response) {
  //       if (response.status === 200 || response.status === 201) {
  //         alert("Updated Successfully");
  //         return response.json();
  //       }
  //     });
  //   } catch (error) {}
  // };
  useEffect(() => {
    getUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Header />
      <ToastContainer />
      <div className="inner_page box">
        <h4 className="text-center pt-2">Settings Page</h4>
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
              <div className="box_container">
                {user?.map((data, index) => {
                  return (
                    <div className="py-2 my-2">
                      <div
                        className="card-body p-2 border"
                        onClick={() =>
                          updateHandler(data.id, index, data.user_name)
                        }
                      >
                        <div className="card-text d-flex">
                          <div className="hx-avatar">
                            <img
                              src="https://media.istockphoto.com/vectors/user-icon-flat-isolated-on-white-background-user-symbol-vector-vector-id1300845620?k=20&m=1300845620&s=612x612&w=0&h=f4XTZDAv7NPuZbG0habSpU0sNgECM0X7nbKzTUta3n8="
                              className="rounded-circle"
                              width={60}
                              height={60}
                              alt="a"
                            />
                          </div>
                          <div className="col pr-1 pr-lg-2">
                            <div className="d-flex mb-0 mb-lg-1 align-items justify-content-between">
                              {/* <span
                                className="hx-text-20 doctor_name text-capitalize search-dom"
                                style={{ color: "#4E555B" }}
                              >
                                {data.user} 
                              </span> */}
                              <span className="text-uppercase text-primary role_name text-capitalize search-dom">
                                {data.access_type}
                              </span>
                            </div>
                            <div className="d-flex flex-wrap justify-content-between">
                              <p className="d-flex align-items-center m-0 mr-3">
                                <i className="material-icons mr-2 hx-txt-16">
                                  email
                                </i>
                                <span className="search-dom text-gray">
                                  {data.user_name}
                                </span>
                              </p>
                              <p className="d-flex align-items-center m-0 hx-fcw-100">
                                <i className="material-icons mr-2 hx-txt-16">
                                  <i
                                    class="fa fa-phone"
                                    style={{ fontSize: "14px" }}
                                  ></i>
                                </i>
                                <span className="doctor_phone search-dom">
                                  {data.mobile}
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            {show && (
              <div className="col-sm-6">
                <div className="header_part py-2 my-2">
                  <nav class="navbar navbar-expand-sm bg-info navbar-light">
                    <span class="navbar-brand"></span>
                    <ul className="navbar-nav">
                      <li class="nav-item">
                        <span class="nav-link text-light">{userName}</span>
                      </li>
                    </ul>
                    <ul class="navbar-nav ml-auto">
                      <li class="nav-item">
                        <span class="nav-link text-light">Permissions</span>
                      </li>
                    </ul>
                  </nav>
                  <div className="border p-2">
                    <form>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value="ap"
                          onClick={() =>
                            setPermissions({
                              ...permissions,
                              ap: !permissions.ap,
                            })
                          }
                          checked={permissions.ap}
                          id="defaultCheck1"
                        />
                        <label class="form-check-label" for="defaultCheck1">
                          Add Patient
                        </label>
                        <p className="text-gray">
                          This permission allows the user to add a new patient
                          in to the system.
                        </p>
                      </div>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value="vpe"
                          checked={permissions.vpe}
                          id="defaultCheck1"
                          onClick={() =>
                            setPermissions({
                              ...permissions,
                              vpe: !permissions.vpe,
                            })
                          }
                        />
                        <label class="form-check-label" for="defaultCheck1">
                          View Patient By User
                        </label>
                        <p className="text-gray">
                          This permission allows the user to view those patients
                          which entered by himself .
                        </p>
                      </div>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value="pl"
                          checked={permissions.pl}
                          id="defaultCheck1"
                          onClick={() =>
                            setPermissions({
                              ...permissions,
                              pl: !permissions.pl,
                            })
                          }
                        />
                        <label class="form-check-label" for="defaultCheck1">
                          Patient Master List
                        </label>
                        <p className="text-gray">
                          This permission allows the user to access the patient
                          master list.{" "}
                        </p>
                      </div>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value="lr"
                          checked={permissions.lr}
                          onClick={() =>
                            setPermissions({
                              ...permissions,
                              lr: !permissions.lr,
                            })
                          }
                        />
                        <label class="form-check-label" for="defaultCheck1">
                          Lab Reporting
                        </label>
                        <p className="text-gray">
                          This permission allows the user to entry reports and
                          modify lab tests.
                        </p>
                      </div>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value="lpa"
                          onClick={() =>
                            setPermissions({
                              ...permissions,
                              lpa: !permissions.lpa,
                            })
                          }
                          checked={permissions.lpa}
                          id="defaultCheck1"
                        />
                        <label class="form-check-label" for="defaultCheck1">
                          Lab Pending Approved{" "}
                        </label>
                        <p className="text-gray">
                          This permission allows the user to view the pending
                          approved tests.
                        </p>
                      </div>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value="te"
                          onClick={() =>
                            setPermissions({
                              ...permissions,
                              te: !permissions.te,
                            })
                          }
                          checked={permissions.te}
                          id="defaultCheck1"
                        />
                        <label class="form-check-label" for="defaultCheck1">
                          Lab Transaction By User
                        </label>
                        <p className="text-gray">
                          This permission allows the user to view the
                          transaction entry by himself.{" "}
                        </p>
                      </div>
                      {/* <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value={true}
                        id="defaultCheck1"
                      />
                      <label class="form-check-label" for="defaultCheck1">
                        Manage Accounts
                      </label>
                      <p className="text-gray">
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Eos sunt
                      </p>
                    </div> */}
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value="tl"
                          onClick={() =>
                            setPermissions({
                              ...permissions,
                              tl: !permissions.tl,
                            })
                          }
                          checked={permissions.tl}
                          id="defaultCheck1"
                        />
                        <label class="form-check-label" for="defaultCheck1">
                          Test List{" "}
                        </label>
                        <p className="text-gray">
                          This permission allows the user to access the Tests
                          List.{" "}
                        </p>
                      </div>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value="lta"
                          onClick={() =>
                            setPermissions({
                              ...permissions,
                              lta: !permissions.lta,
                            })
                          }
                          checked={permissions.lta}
                          id="defaultCheck1"
                        />
                        <label class="form-check-label" for="defaultCheck1">
                          Lab Transaction All
                        </label>
                        <p className="text-gray">
                          This permission allows the user to access all the
                          transactions.
                        </p>
                      </div>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value="ldt"
                          onClick={() =>
                            setPermissions({
                              ...permissions,
                              ldt: !permissions.ldt,
                            })
                          }
                          checked={permissions.ldt}
                          id="defaultCheck1"
                        />
                        <label class="form-check-label" for="defaultCheck1">
                          Lab Dues Transactions
                        </label>
                        <p className="text-gray">
                          This permission allows the user to view all the dues
                          transactions.{" "}
                        </p>
                      </div>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value="ma"
                          onClick={() =>
                            setPermissions({
                              ...permissions,
                              ma: !permissions.ma,
                            })
                          }
                          checked={permissions.ma}
                          id="defaultCheck1"
                        />
                        <label class="form-check-label" for="defaultCheck1">
                          Manage Account{" "}
                        </label>
                        <p className="text-gray">
                          This permission allows the admin to access all staffs
                          permissions .
                        </p>
                      </div>
                      <button
                        type="submit"
                        class="btn btn-primary"
                        onClick={(e) => updateForm(e, userId)}
                      >
                        Update
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageAccount;
