import React from "react";

import {
  Page,
  Text,
  Image,
  View,
  Document,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import header from "../../src/headerLab.jpeg";
//import footer from "../../src/footerLab.jpeg";
import InvoiceItemsTable from "../Components/CombineReportReactToPdfComponents/InvoiceItemsTable";
import InvoiceBillName from "../Components/CombineReportReactToPdfComponents/InvoiceBillName";
import InvoiceTableFooter from "../Components/CombineReportReactToPdfComponents/InvoiceTableFooter";
import InvoiceImageFooter from "../Components/CombineReportReactToPdfComponents/InvoiceImageFooter";
import font from "../Fonts/Roboto/Roboto-Bold.ttf";
// const borderColor = "#22598e";
Font.register({
  family: "Roboto",
  format: "truetype",
  src: font,
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 0,
    paddingBottom: 185,
    paddingHorizontal: 0,
  },
  footerContainer: {
    //marginTop:50,
    position: "absolute",
    bottom: 100,
    right: "5%",
  },
  footerContainerLeft: {
    //marginTop:50,
    position: "absolute",
    bottom: 115,
    left: "10%",
  },
  leftFooterElement: {
    fontFamily:'Roboto',
    fontSize: 9,
  },
  rows: {
    marginTop: "14%",
    fontWeight: "extrabold",
  },
  title: {
    fontSize: 22,
    textAlign: "center",
  },
  text: {
    margin: 10,
    fontSize: 14,
    textAlign: "justify",
  },
  image: {
    maxWidth: "100%",
    maxHeight: "100%",
    objectFit: "cover",
  },
  footers: {
    marginTop: "10%",
  },
  header: {
    fontSize: 12,
    marginBottom: 10,
    textAlign: "center",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    top: 138,
    right: 32,
    //textAlign: "center",
    color: "grey",
  },
  imageContent: {
    position: "absolute",
    bottom: "0",
  },
  titleContainer: {
    flexDirection: "row",
    marginTop: 10,
  },
  address: {
    color: "black",
    marginTop: "1",
    marginBottom: "2",
    fontSize: 10,
    textAlign: "center",
  },
  phones: {
    color: "black",
    marginBottom: "12",
    fontSize: 10,
    textAlign: "center",
  },
  reportTitle: {
    color: "#22598e",
    letterSpacing: 2,
    fontSize: 20,
    textAlign: "center",
    textTransform: "uppercase",
  },
  invoiceNoContainer: {
    flexDirection: "row",
    marginTop: 20,
    justifyContent: "flex-end",
  },
  invoiceDateContainer: {
    marginTop: 10,
    flexDirection: "row",
    justifyContent: "flex-end",
  },

  signImgContainer: {
    marginVertical: 1,
    marginHorizontal: 120,
    position: "absolute",
    bottom: "14%",
    zIndex: 41,
    right: "13%",
  },
  label: {
    fontSize: 12,
  },
  headerContainer: {
    marginTop: 25,
  },
  billTo: {
    marginTop: 20,
    paddingBottom: 3,
    // fontFamily: "Helvetica-Oblique",
  },

  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 0,
    paddingVertical: 3,
  },
});

const ReactToPdf = ({ reportData, singleDataList, headerPrint }) => {
  console.log("a", reportData);
  return (
    <Document>
      <Page
        style={[
          styles.body,
          {
            backgroundColor: headerPrint ? "#ffffb3" : "white",
            paddingHorizontal: headerPrint ? 0 : 15,
          },
        ]}
        size="A4"
        wrap
      >
        {headerPrint ? (
          <View fixed>
            <Image style={styles.image} src={header}></Image>
          </View>
        ) : (
          <Text style={styles.rows} fixed></Text>
        )}

        <View fixed>
          <InvoiceBillName
            singleDataList={singleDataList}
            headerPrint={headerPrint}
          />
        </View>

        <View style={styles.tableContainer}>
          <InvoiceItemsTable
            reportData={reportData}
            headerPrint={headerPrint}
          />
        </View>
        <View style={styles.footerContainerLeft} fixed>
          <Text style={styles.leftFooterElement}>Approved By</Text>
        </View>
        <View style={styles.footerContainer} fixed>
          <InvoiceTableFooter />
        </View>

        {headerPrint && (
          <View style={styles.imageContent} fixed>
            <InvoiceImageFooter />
          </View>
        )}
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

export default ReactToPdf;
