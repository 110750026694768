import React, { useState, useEffect } from "react";
import { getRequest, postRequest } from "../Constants/ApiCall";
import { Button } from "@material-ui/core";
import ReactToPdf from "../../Pages/ReportReactToPdf";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Link } from "react-router-dom";
import Header from "../Header/Header";
import PatientEntrySkeleton from "../SkeletonComponents/PatientEntrySkeleton";
import { useParams } from "react-router-dom";

const Index = (props) => {
  var { id, test_name } = useParams();

  console.log("userparams", id, test_name);
  const [reportData, setReportData] = useState([]);
  const [billData, setBillData] = useState([]);
  //  const [isTrue, setIsTrue] = useState({});

  // const [permissionCheck, setPermissionCheck] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [show, setShow] = useState(false);
  const [patientData, setPatientData] = useState([]);
  const [open, setOpen] = useState(false);

  // const [showskeleton, setShowSkeleton] = useState(true);
  const [billingDataLoaded, setBillingDataLoaded] = useState(false);
  const [testName, setTestName] = useState([]);
  // const { test } = props.location.query;
  const getTestData = () => {
    try {
      setTestName(JSON.parse(props.location.query.test));
    } catch (err) {
      console.log(err);
    }
  };
  const getBillIdData = async () => {
    try {
      const response = await getRequest(`/lab/report/${id}`);
      const data = await response.json();
      if (response.status === 200) {
        var routine_list = data.data.map(function (obj) {
          return obj.routine;
        });

        console.log("routine_list", routine_list);
        console.log("sequence", data.bill_data.sequence);
        var order = data.bill_data.sequence.split(",");
        console.log("order", order);
        var sorted_data = data.data.sort(function (a, b) {
          return (
            order.indexOf(a.routine.toString()) -
            order.indexOf(b.routine.toString())
          );
        });
        setReportData(sorted_data);
        setBillingDataLoaded(true);
      }
      console.log("report-first", data);
    } catch (err) {
      console.log(err);
    }
  };

  const getPatientData = async () => {
    console.log("ba", reportData);
    var trans_id = reportData.length > 0 ? reportData[0]["transaction"] : {};
    const response2 = await getRequest(`/lab/transaction/${trans_id}`);
    const data = await response2.json();
    if (response2.status === 200) {
      setPatientData(data[0]);
      setBillingDataLoaded(true);
    }
    const respo = await getRequest(`/lab/billing/transaction/${trans_id}`);
    const datas = await respo.json();
    console.log("g", datas);
    if (respo.status === 200) {
      const newData = datas.filter((item) => item.test_name === test_name);
      console.log("newDataKeyValue", newData);
      if (newData[0]) {
        setBillData(newData[0]);
      }
    }
  };
  console.log("patientDataAPi", patientData.id);

  useEffect(() => {
    if (!billingDataLoaded) {
      getBillIdData();
    } else {
      getPatientData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billingDataLoaded]);

  const updateReport = async (e) => {
    e.preventDefault();

    try {
      const response = await postRequest(
        `/lab/report/${id}`,
        JSON.stringify(reportData),
        "PUT",
        true
      );

      var responseData = await response.json();
      if (response.status === 200) {
        console.log(response, responseData);
        alert("Updated Successfully");
        console.log("report-done", reportData);
      } else {
        alert("Approved Test can't be updated");
        setShow(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const updateFormRows = (index, name, value) => {
    let rows = [...reportData];
    let row = { ...rows[index] };
    row[name] = value;
    rows[index] = row;
    setReportData(rows);
  };
  // const rangeRows = (index, name, value) => {
  //   let newRows = [...reportData];
  //   let secondRows = { ...newRows[index] };
  //   const getRange = secondRows["range"];
  //   setIsTrue(JSON.parse(getRange));

    // console.log("aparse",newGetRange);
    // const dataass = { a: [120, 42], b: [13, 45] };
    // console.log(dataass);
    // if (value && patientData.gender) {
    //   console.log("As",getRange.n);
    //   if (patientData.gender === "male" && getRange.includes("m")) {
    //     const maleData = [...getRange.m];
    //     if (value >= Math.min(...maleData) && value <= Math.max(...maleData)) {
    //       setIsTrue(true);
    //     } else {
    //       setIsTrue(false);
    //     }

    //     console.log("maleData", maleData);
    //     alert("male or m getRange is available");
    //   } else if (patientData.gender === "female" && getRange.includes("f")) {
    //     const femaleData = [...getRange.f];
    //     if (
    //       value >= Math.min(...femaleData) &&
    //       value <= Math.max(...femaleData)
    //     ) {
    //       setIsTrue(true);
    //     } else {
    //       setIsTrue(false);
    //     }
    //     console.log("femaleData", femaleData);
    //     alert("female are available and also range");
    //   } else if (
    //     patientData.gender === "female" ||
    //     (patientData.gender === "male" && getRange.includes("n"))
    //   ) {
    //     const normalRange = [getRange.n];
    //     if (
    //       value >= Math.min(...normalRange) &&
    //       value <= Math.max(...normalRange)
    //     ) {
    //       setIsTrue(true);
    //     } else {
    //       setIsTrue(false);
    //     }
    //     console.log("normalRange", normalRange);

    //     alert("its female or male & range in normal", patientData.gender);
    //   }
    // } else {
    //   alert("fill all inputs");
    // }
    // console.log("A", getRange);
  //   console.log("rangeRows", patientData.gender);
  // };
  //console.log("getrage",isTrue)
  const handleClickOpen = () => {
    if (billData && billData.isapproved === true) {
      toast.error("Approved Test Can't be edit", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setOpen(true);
    }
  };
  useEffect(() => {
    getTestData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billData]);

  const handleCheck = async (id) => {
    console.log("id", id);

    const body = {
      status: "reporting done",
    };
    const response = await postRequest(
      `/lab/billing/${id}`,
      JSON.stringify(body),
      "PUT",
      true
    );
    console.log("appr", body);

    const data = await response.json();
    if (response.status === 200 || response.status === 201) {
      toast.success("Approved Successfully", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      console.log("DataBill", data);
      setOpen(false);
    }
  };

  if (reportData && patientData && billData) {
    return (
      <div>
        <ToastContainer />

        <Header />
        <br />
        <div className="inner_page box">
          <div className="container">
            <div class="modal-content">
              <div class="modal-header">
                <Dialog
                  open={open}
                  onClose={() => setOpen(false)}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    <div class="modal-dialog modal-confirm">
                      <div class="modal-content">
                        <div class="modal-header flex-column">
                          <div class="icon-box">
                            <i class="material-icons">
                              <i className="fa fa-warning"></i>
                            </i>
                          </div>
                          <h4 class="modal-title w-100">Are you sure?</h4>
                          <button
                            type="button"
                            class="close"
                            onClick={() => setOpen(false)}
                            data-dismiss="modal"
                            aria-hidden="true"
                          >
                            &times;
                          </button>
                        </div>
                        <div class="modal-body">
                          <p style={{ fontSize: "17px" }}>
                            Do you really want to approve this test? This
                            process cannot be undone.
                          </p>
                        </div>
                        <div class="modal-footer justify-content-center">
                          <button
                            type="button"
                            class="btn btn-secondary"
                            onClick={() => setOpen(false)}
                            data-dismiss="modal"
                          >
                            NO
                          </button>
                          <button
                            type="button"
                            onClick={() => handleCheck(id)}
                            class="btn btn-danger"
                          >
                            Yes
                          </button>
                        </div>
                      </div>
                    </div>{" "}
                  </DialogTitle>
                  <DialogContent>
                    {/* <DialogContentText id="alert-dialog-description">
                      You want to approve this test. Approved test can't be
                      edited
                    </DialogContentText> */}
                  </DialogContent>
                  {/* <DialogActions>
                    <Button onClick={() => setOpen(false)}>No</Button>
                    <Button onClick={() => handleCheck(id)}>Yes</Button>
                  </DialogActions> */}
                </Dialog>

                <h4 class="modal-title float-left">Update Test Report</h4>
                <div class="fdataorm-check float-right">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    onClick={handleClickOpen}
                    //  onClick={() => handleCheck(id)}
                    checked={billData && billData.isapproved}
                    id="defaultCheck1"
                  />
                  <label
                    class="form-check-label"
                    for="defaultCheck1"
                    style={{ cursor: "pointer" }}
                  >
                    Approved
                  </label>
                </div>
                <div className="float-right">
                  {" "}
                  <PDFDownloadLink
                    document={
                      <ReactToPdf
                        reportData={reportData}
                        singleDataList={patientData}
                      />
                    }
                    fileName={patientData.patient_name}
                  >
                    {({ loading }) =>
                      loading ? (
                        <Button variant="outlined" color="secondary">
                          Generating Pdf...
                        </Button>
                      ) : (
                        <Button variant="outlined" color="primary">
                          Generate Pdf
                        </Button>
                      )
                    }
                  </PDFDownloadLink>
                </div>
              </div>

              <div class="modal-body">
                <div
                  className="no-gutters mb-3 hx-input_control"
                  style={{ backgroundColor: "#eee" }}
                >
                  <div class="d-flex justify-content-between">
                    <div>
                      <p className="p-2 mb-0 text-primary">BID: HX{id}</p>
                      <p
                        className="p-2 text-primary"
                        style={{ marginTop: "-10px" }}
                      >
                        Name:{" "}
                        <b>
                          {patientData.patient_name} ({patientData.age},{" "}
                          {patientData.gender} )
                        </b>
                      </p>
                      <p
                        className="p-2 mb-0 text-primary"
                        style={{ marginTop: "-20px" }}
                      >
                        <b>Booked:</b>
                      </p>
                    </div>
                    <div>
                      <p className="p-2 mb-0 mr-2 mt-2 text-primary">
                        <b>
                          Ref.Doctor: {patientData.doctor_name}
                          <br /> {patientData.degree}
                        </b>
                      </p>
                      <p className="p-2 mb-0 mr-2 mt-2 text-primary">
                        <label>Sample collected on</label>
                        <input
                          type="date"
                          className="form-control calendar "
                          name="dob"
                          id="dov"
                          placeholder=""
                        />
                      </p>
                    </div>
                  </div>
                </div>

                <div class="container table-responsive">
                  {reportData.length === 0 ? (
                    <>
                      <PatientEntrySkeleton />
                    </>
                  ) : (
                    <table class="table">
                      <thead>
                        <tr className="table-bordered">
                          <th scope="col">Test Parameter</th>
                          <th scope="col">Values</th>
                          <th scope="col">Units</th>
                          <th scope="col">Biological Ref. Interval</th>

                          {/* <th scope="col">Actions</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {reportData.map((k, index) => {
                          return (
                            <tr key={k.id}>
                              <td>{k.routine_name}</td>

                              <td>
                                <input
                                  style={
                                    billData && billData.isapproved === true
                                      ? {
                                          pointerEvents: "none",
                                          background: "wheat",
                                          border: "1px solid wheat",
                                        }
                                      : {
                                          pointerEvents: "visible",
                                          background: "#bec9cf",
                                          padding: "5px",

                                          border: "1px solid #7b8185",
                                        }
                                  }
                                  type="text"
                                  value={k.value}
                                  // onBlur={(e) =>
                                  //   rangeRows(index, "value", e.target.value)
                                  // }
                                  onChange={(e) =>
                                    updateFormRows(
                                      index,
                                      "value",
                                      e.target.value
                                    )
                                  }
                                />
                              </td>
                              <td>
                                {/* <input
                                type="text"
                                value={k.remarks}
                                onChange={(e) =>
                                  updateFormRows(
                                    index,
                                    "remarks",
                                    e.target.value
                                  )
                                }
                                
                              /> */}
                                {k.unit}
                              </td>
                              <td>{k.refrence}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
              <Button
                variant="contained"
                color="primary"
                onClick={(e) => updateReport(e)}
              >
                Update Report
              </Button>
              <div className="row ml-3 py-2 px-2" style={{ fontSize: "9px" }}>
                {testName &&
                  testName.map((data, item) => (
                    <Link to={`/labreports/${data.test_name}/${data.id}`}>
                      <Button
                        variant="contained"
                        color="default"
                        onClick={() => {
                          setBillingDataLoaded(false);
                          setReportData([]);
                        }}
                      >
                        {" "}
                        {data.test_name}
                      </Button>
                    </Link>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    console.log("loading....");

    return;
  }
};

export default Index;
