import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

// const borderColor = "#90e5fc";
const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    borderBottomColor: "#22598e",
    backgroundColor: "#22598e",
    borderBottomWidth: 2,
    alignItems: "center",
    height: 25,
    //textAlign: "center",
    fontStyle: "bold",
    flexGrow: 1,
  },

  groupName: {
    fontSize: 9,
    textTransform:"capitalize",
    fontWeight:"bold",
    width: "70%",
    color: "white",
    paddingLeft: 9,
    // borderRightColor: borderColor,
    // borderRightWidth: 1,
  },
  // mrp: {
  //   fontSize: 9,
  //   color: "white",
  //   textTransform:"capitalize",
  //   width: "20%",
  //   paddingLeft: 9,
  //   fontWeight:"bold",
  //   // borderRightColor: borderColor,
  //   // borderRightWidth: 1,
  // },
 
  // discount: {
  //   width: "20%",
  //   textTransform:"capitalize",
  //   fontWeight:"bold",
  //   fontSize: 9,
  //   paddingLeft: 9,
  //   color: "white",
  // },
  paidAmount: {
    textTransform:"capitalize",
    width: "20%",
    paddingLeft: 9,
    fontSize: 9,
    fontWeight:"bold",
    color: "white",
  },
  serialNumber: {
    textTransform:"capitalize",
    width: "10%",
    paddingLeft: 9,
    fontSize: 9,
    fontWeight:"bold",
    color: "white",
  },
});

const InvoiceTableHeader = () => (
  <View style={styles.container}>
    <Text style={styles.serialNumber}>S.NO</Text>
    <Text style={styles.groupName}>Test Name</Text>
    {/* <Text style={styles.mrp}>Price</Text> */}
    {/* <Text style={styles.discount}>Discount</Text> */}
    <Text style={styles.paidAmount}>Amount</Text>
  </View>
);

export default InvoiceTableHeader;
  