import React, { useState, useEffect } from "react";
import { Button } from "@material-ui/core";
//import { FaDeviantart, FaTimes } from "react-icons/fa";
import { ThreeDots } from "react-loader-spinner";
import PatientEntrySkeleton from "../Components/SkeletonComponents/PatientEntrySkeleton";
import { postRequest, getRequest } from "../Components/Constants/ApiCall";
import Header from "../Components/Header/Header";
import { useHistory } from "react-router-dom";
import { BsExclamationCircle } from "react-icons/bs";

const LabDetails = (props) => {
  const history = useHistory();
  const [patientId] = useState(props.match.params.id);
  const [searchTerm, setSearchTerm] = useState("");
  const [businessData, setBusinessData] = useState([]);
  const [doctorList, setDoctorList] = useState([]);
  const [tests, setTest] = useState([]);
  const [query] = useState("");
  const [paymentForm, setPaymentForm] = useState({
    discount: 0,
    business: "",
    total_amount: "0",
    patient: props.match.params.id,
    transaction_type: "offline",
    paid_amount: "",
    reffred_by: "1",
    reffered_doctor: "",
    transaction_description: "a",
    due_amount: 0,
  });
  const [Items, setItems] = useState([]);
  //const [show, setShow] = useState(false);
  const [total, setTotal] = useState(0);

  const [formError, setFormError] = useState({});
  // var { id } = useParams();
  const [loading, setLoading] = useState(false);
  // const [formInfo, setForm] = useState({
  //   business: business_id_name,
  //   patient: id,
  //   mrp: "",
  //   discount: 0,
  //   test: "",

  //   billing_type: "payment",
  //   final_amount: "",
  //   discount_description: 0,
  //   quantity: 1,
  // });

  const getLabTests = async () => {
    try {
      const response = await getRequest(`/lab/test?search=${query}`);
      const data = await response.json();
      if (response.status === 200) {
        let toggle_expand = [];
        data.map((item) =>
          // eslint-disable-next-line no-useless-computed-key
          toggle_expand.push({ ...item, ["isActive"]: false })
        );
        setTest(toggle_expand);
        console.log("Test", tests);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const getDoctorMasterList = async () => {
    try {
      const response = await getRequest(`/doctor/global`);
      const data = await response.json();
      if (response.status === 200) {
        setDoctorList(data);
        console.log("DoctorList", data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const getBusiness = async () => {
    try {
      const response = await getRequest(`/business`);
      const data = await response.json();
      if (response.status === 200) {
        setBusinessData(data);
        console.log("business", data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const formValidation = () => {
    setFormError({});
    var formErrorData = {};
    var formValidated = true;
    if (!paymentForm.reffered_doctor) {
      formErrorData["docError"] = "This field cannot be blank";
      formValidated = false;
    }
    if (paymentForm.paid_amount < 0) {
      formErrorData["paidError"] = "Amount must be in positive";
      formValidated = false;
    }
    if (!paymentForm.business) {
      formErrorData["clinicError"] = "This field cannot be blank";
      formValidated = false;
    }
    if (isNaN(paymentForm.discount)) {
      formErrorData["discountError"] = "Discount must be in interger";
      formValidated = false;
    }
    if (isNaN(paymentForm.paid_amount)) {
      formErrorData["paidError"] = "Paid amount must be in interger";
      formValidated = false;
    }
    if (paymentForm.discount < 0) {
      formErrorData["discountError"] = "Discount must be in positive value";
      formValidated = false;
    }
    setFormError(formErrorData);
    return formValidated;
  };

  const paymentFunc = async (e) => {
    try {
      if (formValidation()) {
        // setLoading(true)
        const body = {
          billing: Items,
          transaction: paymentForm,
        };
        e.preventDefault();
        //setBusinessId(paymentForm.business);
        const response = await postRequest(
          `/lab/transaction/payment/${paymentForm.business}`,
          JSON.stringify(body),
          "POST",
          true
        );
        console.log("payment", body);
        console.log("payment1", paymentForm);
        const data = await response.json();
        console.log("PAYMENT", data, response.status);

        if (response.status !== 201) return alert(data.detail);
        setLoading(true);
        alert("Payment Done Successfully!!");
        history.push(`/lab/transactions/entry-wise`);
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getBusiness();
    getDoctorMasterList();

    // calculateAmount()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getLabTests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const calculateAmount = (billingData) => {
    const amounts = billingData.map((ls) => parseInt(ls.price));
    console.log("amounts", amounts);

    const t = amounts.reduce((acc, item) => {
      return acc + item;
    }, 0);
    setTotal(t);
    setPaymentForm({
      ...paymentForm,
      paid_amount: t,
    });
  };

  const addData = (data, index) => {
    console.log("Add", data);
    //   setSearchTerm({ searchTerm: "" });
    const testData = [...tests];
    if (testData[index]["isActive"]) {
      testData[index]["isActive"] = false;
      setTest(testData);

      return;
    } else {
      testData[index]["isActive"] = true;
      setTest(testData);
    }

    const allData = {
      ...data,
      index: index,
      patient: patientId,
      test: data.id,
      price: data.price,
    };

    setItems((Items) => [...Items, allData]);
    calculateAmount([...Items, allData]);
    console.log("array", Items);
  };

  const onDelete = (id) => {
    //console.log("hello", id);

    const testData = [...tests];
    const finalData = Items.filter((ls) => {
      if (ls.id === id) {
        testData[ls.index]["isActive"] = false;
      }
      return ls.id !== id;
    });

    console.log(testData);
    setTest([...testData]);
    setItems(finalData);
    calculateAmount(finalData);
  };
  console.log(Items);
  return (
    <div>
      <Header hideHomeHeader={false} />
      <br />
      <div className="inner_page box ">
        <div
          className="table-responsive "
          style={{
            height: "340px",
            margin: "0% 5% 2% 5%",
            width: "90%",
            overflowY: "scroll",
            boxShadow:
              "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
          }}
        >
          <div className="col-sm-12 input-group input-group-md input-group-navbar p-2">
            <input
              className="form-control"
              id="searchInput"
              type="search"
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
              placeholder=" Search test"
            />

            <button className="btn" type="button">
              <i className="fas fa-search" style={{ fontSize: "14px" }} />
            </button>
          </div>
          {tests.length === 0 ? (
            <>
              <PatientEntrySkeleton />
            </>
          ) : (
            <table class="table">
              <thead>
                <tr className="table-bordered">
                  <th scope="col">ID</th>
                  <th scope="col">Test Name</th>
                  <th scope="col">Department</th>
                  <th scope="col">Price</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {tests
                  // eslint-disable-next-line array-callback-return
                  .filter((data) => {
                    if (searchTerm === "") {
                      return data;
                    } else if (
                      Object.values(data)
                        .join("")
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
                    ) {
                      return data;
                    }
                  })
                  .map((data, index) => (
                    <>
                      <tr key={index} id={index}>
                        <td>{data.id}</td>
                        <td>{data.name}</td>
                        <td>{data.department_name}</td>
                        <td>{data.price}</td>
                        <td>
                          <Button
                            variant="outlined"
                            color={data.isActive ? "secondary" : "primary"}
                            onClick={() => addData(data, index)}
                          >
                            {data.isActive ? "Added" : "Add"}
                          </Button>
                        </td>
                      </tr>
                    </>
                  ))}
              </tbody>
            </table>
          )}
        </div>
        <br />
        {Items.length > 0 ? (
          <>
            <div class="px-5 table-responsive">
              <table class="table">
                <thead>
                  <tr className="table-bordered">
                    <th scope="col">Test Name</th>
                    <th scope="col">Price</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {Items.map((ls, index) => {
                    return (
                      <>
                        <tr key={index} id={index}>
                          <td>{ls.name}</td>
                          <td>{ls.price}</td>
                          <td>
                            <Button
                              variant="contained"
                              style={{ fontSize: "12px" }}
                              color="primary"
                              onClick={() => onDelete(ls.id)}
                            >
                              Delete
                            </Button>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
                <div style={{ position: "absolute", right: "9%" }}>
                  <h5 className="text-muted">
                    Payable Amount: {total}
                    /-
                  </h5>
                </div>
              </table>
              <br />
              <br />
              <div className="mb-4">
                <div className="row mb-5">
                  <div className="col-sm-2 form-group">
                    <label>Select Doctor: </label>
                    <select
                      onChange={(e) =>
                        setPaymentForm({
                          ...paymentForm,
                          reffered_doctor: e.target.value.split("|")[0],
                        })
                      }
                      className="form-control custom-select calendar"
                    >
                      <option selected disabled hidden>
                        Choose doctor
                      </option>

                      {doctorList?.map((item, index) => (
                        <option key={index} value={`${item.id}|${item.name}`}>
                          {item.name} ({item.degree} )
                        </option>
                      ))}
                    </select>

                    <span className="small text-danger opacity-4">
                      {formError.docError}
                    </span>
                  </div>
                  <div className="col-sm-2 form-group">
                    <label>Select Clinic: </label>
                    <select
                      onChange={(e) =>
                        setPaymentForm({
                          ...paymentForm,
                          business: e.target.value.split("|")[0],
                        })
                      }
                      className="form-control custom-select calendar"
                    >
                      <option selected disabled hidden>
                        Choose clinic
                      </option>

                      {businessData.map((item, index) => (
                        <option key={index} value={`${item.id}|${item.name}`}>
                          {item.name}
                        </option>
                      ))}
                    </select>

                    <span className="small text-danger opacity-4">
                      {formError.clinicError}
                    </span>
                  </div>
                  <div className="col-sm-2 form-group">
                    <label>Discount:</label>
                    <input
                      type="text"
                      className="form-control calendar"
                      placeholder="Enter here.."
                      value={paymentForm.discount}
                      onChange={(e) =>
                        setPaymentForm({
                          ...paymentForm,
                          discount: e.target.value,
                          paid_amount: total - e.target.value,
                        })
                      }
                    />
                    <span className="small text-danger opacity-4">
                      {formError.discountError}
                    </span>
                  </div>
                  <div className="col-sm-2 form-group">
                    <label>Paid Amount:</label>
                    <input
                      type="text"
                      className="form-control calendar"
                      placeholder="Enter here.."
                      value={paymentForm.paid_amount}
                      onChange={(e) =>
                        setPaymentForm({
                          ...paymentForm,
                          paid_amount: e.target.value,
                        })
                      }
                    />
                    <span className="small text-danger opacity-4">
                      {formError.paidError}
                    </span>
                  </div>
                  <div className="col-sm-2 form-group">
                    <label>Transaction Type:</label>

                    <select
                      id="inputState"
                      class="form-control"
                      onChange={(e) =>
                        setPaymentForm({
                          ...paymentForm,
                          transaction_type: e.target.value,
                        })
                      }
                    >
                      <option value="offline" selected>
                        Offline
                      </option>
                      <option value="online">Online</option>
                    </select>
                    <span className="small text-danger opacity-4">
                      {formError.discountError}
                    </span>
                  </div>
                  <div
                    style={{
                      marginTop: "3%",

                      color: "white",
                    }}
                    // class="modal-css"
                  >
                    {!loading ? (
                      <Button
                        variant="outlined"
                        type="submit"
                        color="primary"
                        onClick={(e) => paymentFunc(e)}
                        // href="#myModal"
                        // data-toggle="modal"
                      >
                        Make Payment
                      </Button>
                    ) : (
                      <Button
                        variant="outlined"
                        type="submit"
                        color="primary"
                        // onClick={(e) => paymentFunc(e)}
                        // href="#myModal"
                        // data-toggle="modal"
                      >
                        <ThreeDots color="blue" height={25} width={65} />
                      </Button>
                    )}
                  </div>
                </div>

                <div id="myModal" className="modal fade">
                  <div className="modal-dialog modal-confirm">
                    <div className="modal-content">
                      <div className="modal-header flex-column">
                        <div className="icon-box">
                          <BsExclamationCircle style={{ fontSize: "70px" }} />
                        </div>
                        <h4 className="modal-title w-100">Are you sure?</h4>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-hidden="true"
                        >
                          &times;
                        </button>
                      </div>
                      <div className="modal-body">
                        <p style={{ fontSize: "19px" }}>
                          Do you want to proceed with these operation? This
                          process cannot be undone.
                        </p>
                      </div>
                      <div className="modal-footer justify-content-center">
                        <button
                          type="button"
                          data-dismiss="modal"
                          aria-hidden="true"
                          className="btn btn-danger"
                        >
                          Confirm Payment
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}

        {/* <div className="col-sm-12 form-group mb-0">
          <div>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              onClick={(e) => CreateBill(e)}
            >
              Submit
            </Button>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default LabDetails;
