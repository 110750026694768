import React, { useState, useEffect } from "react";
import { getRequest } from "../Components/Constants/ApiCall";
import Header from "../Components/Header/Header";

const RoutineListDashboard = (props) => {
  const [routineData, setRoutineData] = useState([]);
  // const [searchTerm, setSearchTerm] = useState("");
  // const [searchTerm1, setSearchTerm1] = useState("");
   // eslint-disable-next-line no-unused-vars
   const [routineList, setRoutineList] = useState([]);

  const getLabDataList = async () => {
    try {
      const response = await getRequest(
        `/lab/billing/tests/${props.match.params.id}`
      );
      const data = await response.json();
      if (response.status === 200) {
        setRoutineData(data);
      }
      console.log(data);
    } catch (err) {
      console.log(err);
    }
  };

  const getRoutineList = async () => {
    try {
      const response = await getRequest(`/lab/routine`);
      const data = await response.json();
      if (response.status === 200) {
        setRoutineList(data);
      }
      console.log(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getLabDataList();
    getRoutineList();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <Header />
      <div className="inner_page box">
        <div class="container">
          <h3 className="text-center">Routine List</h3>
          {/* <div className="row">
            <div className="col-sm-6 input-group input-group-md input-group-navbar py-3">
              <input
                type="date"
                data-date-format="dd-mm-yyyy"
                className="form-control calendar "
                name="dob"
                id="dov"
                placeholder=""
              />

              <div className="pl-2">
                {" "}
                <Button variant="contained" color="primary">
                  search by date
                </Button>
              </div>
            </div>

            <div className="col-sm-4 input-group input-group-md input-group-navbar py-3 ml-auto">
              <input
                className="form-control"
                id="searchInput"
                type="search"
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                }}
                placeholder=" Search by patient"
              />

              <button className="btn" type="button">
                <i className="fas fa-search" style={{ fontSize: "14px" }} />
              </button>
            </div>
          </div> */}
          <br />
          {routineData.length > 0 ? (
            <>
              <div
                class="table-responsive"
                style={{ width: "100%", height: "370px", overflow: "scroll" }}
              >
                {/* <div className="col-sm-4 input-group input-group-md input-group-navbar py-3 ml-auto">
                  <input
                    className="form-control"
                    id="searchInput"
                    type="search"
                    onChange={(e) => {
                      setSearchTerm1(e.target.value);
                    }}
                    placeholder=" Search by patient"
                  />

                  <button className="btn" type="button">
                    <i className="fas fa-search" style={{ fontSize: "14px" }} />
                  </button>
                </div> */}
                <table class="table">
                  <thead>
                    <tr className="table-bordered">
                      <th scope="col">Routine ID</th>
                      <th scope="col">Routine Name</th>
                      <th scope="col">Unit</th>
                      <th scope="col">Reference</th>

                    </tr>
                  </thead>
                  <tbody>
                    {routineData
                      // eslint-disable-next-line array-callback-return

                      .map((data, index) => (
                        <>
                          <tr key={index} id={index}>
                            <td>{data.id}</td>
                            <td>{data.name}</td>
                            <td>{data.unit}</td>
                            <td>{data.refrence}</td>
                          </tr>
                        </>
                      ))}
                  </tbody>
                </table>

                <div></div>
              </div>
            </>
          ) : (
            <>
              <div className="ml-sm-5">
                <div className="ml-sm-5">
                  {/* <FaNetworkWired
               className=""
               style={{ fontSize: "120px", opacity: "0.4" }}
             /> */}
                  <img
                    style={{
                      width: "30%",
                      opacity: "0.8",
                      marginLeft: "30%",
                    }}
                    className="img-fluid mb-4 mt-4 mr-5"
                    src="https://i.ibb.co/197k5Gb/inbox.png"
                    alt="dae"
                  />
                  <p
                    className="text-muted text-center"
                    style={{ marginLeft: "-8%" }}
                  >
                    No transactions yet !
                  </p>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default RoutineListDashboard;
