import React, { useState, useEffect } from "react";
import { getRequest } from "../Components/Constants/ApiCall";
import { Link } from "react-router-dom";
import Header from "../Components/Header/Header";
import { Button } from "@material-ui/core";

const Test = () => {
  const [labData, setLabData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const getLabDataList = async () => {
    try {
      const response = await getRequest(`/lab/test`);
      const data = await response.json();
      if (response.status === 200) {
        setLabData(data);
      }
      console.log(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getLabDataList();
  }, []);
  return (
    <div>
      <Header />
      <div className="inner_page box">
        <div class="container">
          <h3 className="text-center">Tests List</h3>
          <div className="row">
            <div className="col-sm-4 input-group input-group-md input-group-navbar py-3 ml-auto">
              <input
                className="form-control"
                id="searchInput"
                type="search"
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                }}
                placeholder=" Search by test"
              />

              <button className="btn" type="button">
                <i className="fas fa-search" style={{ fontSize: "14px" }} />
              </button>
            </div>
          </div>
          <br />
          {labData.length > 0 ? (
            <>
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr className="table-bordered">
                      <th scope="col">ID</th>
                      <th scope="col">Test Name</th>
                      <th scope="col">Department</th>
                      <th scope="col">Price</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {labData
                      // eslint-disable-next-line array-callback-return
                      .filter((data) => {
                        if (searchTerm === "") {
                          return data;
                        } else if (
                          Object.values(data)
                            .join("")
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase())
                        ) {
                          return data;
                        }
                      })
                      .map((data, index) => (
                        <>
                          <tr key={index} id={index}>
                            <td>{data.id}</td>
                            <td>{data.name}</td>
                            <td>{data.department_name}</td>
                            <td>{data.price}</td>
                            <td>
                              <Link to={`/lab/routine/${data.id}`}>
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  size="small"
                                  //  onClick={() => getRoutinePage(data.id)}
                                >
                                  Get Routines
                                </Button>
                              </Link>
                            </td>
                          </tr>
                        </>
                      ))}
                  </tbody>
                </table>

                <div></div>
              </div>
            </>
          ) : (
            <>
              <div className="ml-sm-5">
                <div className="ml-sm-5">
                  {/* <FaNetworkWired
               className=""
               style={{ fontSize: "120px", opacity: "0.4" }}
             /> */}
                  <img
                    style={{
                      width: "30%",
                      opacity: "0.8",
                      marginLeft: "30%",
                    }}
                    className="img-fluid mb-4 mt-4 mr-5"
                    src="https://i.ibb.co/197k5Gb/inbox.png"
                    alt="dae"
                  />
                  <p
                    className="text-muted text-center"
                    style={{ marginLeft: "-8%" }}
                  >
                    No transactions yet !
                  </p>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Test;
