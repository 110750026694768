import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { Font } from "@react-pdf/renderer";
import font from "../../Fonts/Roboto/Roboto-Bold.ttf";
// import fontsans from "../../Fonts/open-sans/OpenSans-Regular.ttf";
// const borderColor = "#22598e";
// Font.register({
//   family: "Open Sans",
//   format: "truetype",
//   src: fontsans,
// });

Font.register({
  family: "Roboto",
  format: "truetype",
  src: font,
  fontWeight: 500,
});

Font.register({
  family: "Open Sans",
  fonts: [
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
    },

    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf",
      fontWeight: 600,
    },
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-800.ttf",
      fontWeight: 800,
    },
  ],
});
// const borderColor = "#22598e";
const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    // paddingBottom: 5,
    // paddingTop: 10,
    // borderBottomColor: "#22598e",
    // borderBottomWidth: 1,
    alignItems: "center",
    minHeight: 17,
    // height: 24,
    fontWeight: "bold",
    flexGrow: 1,
  },
  rowbg: {
    flexDirection: "row",
    // borderBottomColor: "#22598e",
    // borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    // fontFamily: "Open Sans",
    // fontWeight: 800,
    marginTop: 2,
    backgroundColor: "#F4F4F4",
    //backgroundColor: "#FFFF9F",
    flexGrow: 1,
  },
  groupName_serial: {
    fontSize: 10,
    width: "10%",
    paddingLeft: 9,
  },
  groupName: {
    fontSize: 10,
    width: "55%",
   // fontFamily: "Open Sans",
    paddingLeft: 9,
  },
  groupName1: {
    fontSize: 10,
    width: "55%",
    fontFamily: "Open Sans",
    fontWeight: 800,
    paddingLeft: 9,
  },

  valueUi: {
//    fontFamily: "Open Sans",
    //fontWeight: 600,
    fontSize: 10,

    // textAlign: "center",
    width: "20%",
    // borderRightColor: borderColor,
    // borderRightWidth: 1,
  },

  discount: {
    width: "25%",
    // textAlign: "center",
    paddingLeft: 14,
    fontSize: 10,
    // borderRightColor: borderColor,
    // borderRightWidth: 1,
  },
  paidAmount: {
    width: "20%",
    //textAlign: "center",
    fontSize: 10,
    paddingLeft: 14,
  },
});

const InvoiceTableRow = ({ reportData, headerPrint }) => {
  // const [usedata, setData] = useState([])

  const rows = Object.entries(reportData).map(([key, value]) => (
    <>
      {value.length === 1 ? (
        <>
          <tr></tr>
        </>
      ) : (
        <View
          style={[
            styles.rowbg,
            { backgroundColor: headerPrint ? "#ffffb3" : "#F4F4F4" },
          ]}
          key={value.id}
        >
          <Text style={styles.groupName1}>{value.length === 1 ? "" : key}</Text>
          <Text style={styles.valueUi}></Text>
          <Text style={styles.discount}></Text>
        </View>
      )}
      {value.map((item, index) => (
        <View
          style={[
            styles.row,
            { backgroundColor: headerPrint ? "#ffffb3" : "white" },
          ]}
          key={index}
        >
          <Text style={styles.groupName}>{item.routine_name}</Text>
          <Text style={styles.valueUi}>
            {item.value}{" "}
            {item.unit === "--" || item.unit === "---" || item.unit === "-"
              ? ""
              : item.unit}
          </Text>
          <Text style={styles.discount}>
            {item.refrence === "--" ||
            item.refrence === "---" ||
            item.refrence === "-"
              ? ""
              : item.refrence}
          </Text>
        </View>
      ))}
    </>
  ));

  return <Fragment>{rows}</Fragment>;
};

export default InvoiceTableRow;
