import React, { useState, useEffect } from "react";
import Header from "../Components/Header/Header";
import { getRequest } from "../Components/Constants/ApiCall";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { GrTransaction } from "react-icons/gr";
import ReactToPdf from "./LabReactToPdf";
import { PDFDownloadLink } from "@react-pdf/renderer";
import LocalPrintshopRoundedIcon from "@mui/icons-material/LocalPrintshopRounded";
import SubjectRoundedIcon from "@mui/icons-material/SubjectRounded";
import DueAmountModal from "../Components/DueAmountModal/Index";
import RefundAmountModal from "../Components/RefundAmountModal/Index";
import Tooltip from "@mui/material/Tooltip";
import AccountBalanceWalletRoundedIcon from "@mui/icons-material/AccountBalanceWalletRounded";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import PatientEntrySkeleton from "../Components/SkeletonComponents/PatientEntrySkeleton";
//import AddTransaction from "../Components/AddTransaction/Index";

const LabTransactionByUser = (props) => {
  const [transactiondata, setTransactionData] = useState([]);
  // const [transactionDataFilter, setTransactionDataFilter] = useState([]);
  const [query, setQuery] = useState(new Date().toISOString().slice(0, 10));
  const [searchTerm, setSearchTerm] = useState("");
  const [expanded, setExpanded] = useState(false);
  const [dueId, setDueId] = useState("");
  const [totalPrice, setTotalPrice] = useState();
  //const [businessData, setBusinessData] = useState([]);
  const [referral, setReferral] = useState();

  const [degreeReferral, setDegree] = useState();
  const [open, setOpen] = useState(false);
  const [opens, setOpenR] = useState(false);
  const [discountMain, setDiscount] = useState();
  const [showskeleton, setShowSkeleton] = useState(true);

  const [patientTrans, setPatientTrans] = useState([]);
  const [singleData, setSingleData] = useState({
    patient_name: "",
    gender: "",
    test_name: "",
    //  reffred_by: "",
    business: "",
    patient: "",
    test: "",
    billing_type: "",
    billing_stage: "",
    mrp: "",
    transaction: "",
    discount: "",
    final_amount: "",
    discount_description: "",
    user: "",
    quantity: "",
    id: "",
  });

  const [reportingDate] = useState({
    todayDate: new Date().toDateString().slice(0, 10),
    todayTime: new Date().toLocaleTimeString().slice(0, 11),
  });

  console.log(searchTerm);
  const getTransactionData = async () => {
    try {
      const response = await getRequest(
        `/lab/transaction/user?search=${query}`,
        true
      );
      const data = await response.json();
      setShowSkeleton(false);
      if (response.status === 200) {
        let transaction_data = [];
        data.map((item) =>
          transaction_data.push({
            ...item,
            expanded_data: [],
            // eslint-disable-next-line no-useless-computed-key
            ["expand"]: false,
          })
        );
        setTransactionData(transaction_data);
        // setTransactionDataFilter(transaction_data);
      }
      console.log(data);
    } catch (err) {
      console.log(err);
    }
  };
  const collapseBody = async (
    id,
    index,
    discountMain,
    paid_amountMain,
    doctorName,
    degree
  ) => {
    try {
      setReferral(doctorName);
      setDegree(degree);
      setDiscount(discountMain);
      setTotalPrice(paid_amountMain);
      if (transactiondata[index]["expand"]) {
        transactiondata[index]["expand"] = false;
        setTransactionData(transactiondata);
        setExpanded(!expanded);
        return;
      } else {
        transactiondata[index]["expand"] = true;
        setTransactionData(transactiondata);
        setExpanded(!expanded);
      }
      const response = await getRequest(`/lab/billing/transaction/${id}`);
      const data = await response.json();
      if (response.status === 200) {
        setPatientTrans(data);

        setExpanded(!expanded);
        transactiondata[index]["expanded_data"] = data;

        setTransactionData(transactiondata);

        console.log("PATIENT TRANSACTION", data);
        if (data.length >= 0) {
          setSingleData(data[0]);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const totalAmount = transactiondata.map((data) => parseInt(data.paid_amount));
  console.log("TOTAL", totalAmount);

  const total = totalAmount.reduce((acc, item) => {
    return acc + item;
  }, 0);

  //filter by business
  const dueAmountFunc = (id) => {
    console.log("dueee", id);
    setDueId(id);
    setOpen(true);
  };

  const refundAmountFunc = (id) => {
    console.log("refund", id);
    setDueId(id);
    setOpenR(true);
  };

  // const filterClinic = (id, value) => {
  //   console.log("filter", id, value);
  //   const filterData = [...transactionDataFilter];
  //   const filterBusiness = filterData.filter((item, index) => {
  //     return item.business_name === value;
  //   });

  //   setTransactionData(filterBusiness);
  // };
  // const changePage = (id, name) => {
  //   router.push(`/lab/dues-history/${name}/${id}`);
  // };

  // const deleteTransaction = async (id) => {
  //   try {
  //     const response = await postRequest(
  //       `/lab/transaction/${id}`,
  //       " ",
  //       "DELETE",
  //       true
  //     );

  //     const data = await response.json();
  //     console.log(data.status);
  //   } catch (err) {
  //     console.log(err);
  //   }

  //   console.log("t_id", id);
  // };
  useEffect(() => {
    getTransactionData();
    // getBusiness();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <DueAmountModal
        open={open}
        close={setOpen}
        dueId={dueId}
        getTransactionData={getTransactionData}
      />
      <RefundAmountModal
        open={opens}
        close={setOpenR}
        dueId={dueId}
        getTransactionData={getTransactionData}
      />
      <Header />
      <div className="inner_page box">
        <div class="container">
          <h2 className="text-center py-2">
            <GrTransaction style={{ marginRight: "4px" }} />
            Lab Transactions
          </h2>
          <div className="row">
            <div className="col-sm-6 input-group input-group-md input-group-navbar py-3">
              <input
                type="date"
                data-date-format="dd-mm-yyyy"
                className="form-control calendar "
                name="dob"
                id="dov"
                onChange={(event) => setQuery(event.target.value)}
                value={query}
                placeholder=""
              />

              <div className="pl-2">
                {" "}
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => getTransactionData()}
                >
                  search by date
                </Button>
              </div>
            </div>

            <div className="col-sm-4 input-group input-group-md input-group-navbar py-3 ml-auto">
              <input
                className="form-control"
                id="searchInput"
                type="search"
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                }}
                placeholder=" Search by patient"
              />

              <button className="btn" type="button">
                <i className="fas fa-search" style={{ fontSize: "14px" }} />
              </button>
            </div>
          </div>
          {/* <AddTransaction /> */}

          <br />
          {transactiondata.length > 0 ? (
            <>
              <div
                class="table"
                style={{
                  fontSize: "15px",
                  whiteSpace: "nowrap",
                  width: "100%",
                  height: "360px",
                  overflowX: "scroll",
                }}
              >
                {showskeleton ? (
                  <>
                    <PatientEntrySkeleton />
                  </>
                ) : (
                  <>
                    {" "}
                    <table class="table">
                      <thead>
                        <tr className="table-bordered">
                          <th scope="col">S. No</th>
                          <th scope="col">ID</th>
                          <th scope="col">Date & Time</th>
                          <th scope="col">Patient Name</th>
                          {/* <th scope="col">Clinic</th> */}
                          <th scope="col">Referred By</th>

                          <th scope="col">Total Amount</th>
                          <th scope="col">Discount</th>

                          <th scope="col">Paid Amount</th>
                          <th scope="col">Due Amount</th>

                          <th scope="col">Actions</th>
                        </tr>
                      </thead>
                      {transactiondata.length > 0 ? (
                        <>
                          <tbody>
                            {transactiondata
                              // eslint-disable-next-line array-callback-return
                              .filter((data) => {
                                if (searchTerm === "") {
                                  return data;
                                } else if (
                                  Object.values(data)
                                    .join("")
                                    .toLowerCase()
                                    .includes(searchTerm.toLowerCase())
                                ) {
                                  return data;
                                }
                              })
                              .map((data, index) => (
                                <>
                                  {
                                    <tr
                                      style={{
                                        backgroundColor: " #f7f7f7",
                                        color: "black",
                                      }}
                                      key={index}

                                      //className="tables-css"
                                    >
                                      <td>{index + 1} </td>{" "}
                                      <td style={{ cursor: "pointer" }}>
                                        {data.id}{" "}
                                      </td>{" "}
                                      <td>
                                        {" "}
                                        {new Date(
                                          data.transaction_date
                                        ).toLocaleString("en-in", { hour12: true })}
                                      </td>
                                      <td>{data.patient_name} </td>{" "}
                                      {/* <td>{data.business_name} </td> */}
                                      <td>
                                        {data.doctor_name}
                                        {/* <br />
                                    {data.degree} */}
                                      </td>
                                      <td>{data.total_amount}</td>
                                      <td>{data.discount}</td>
                                      <td>
                                        {data.paid_amount > 0 ? (
                                          <Tooltip title="Refund" size={12}>
                                            <Button
                                              variant="outlined"
                                              onClick={() =>
                                                refundAmountFunc(data.id)
                                              }
                                              color="primary"
                                              size="small"
                                              startIcon={
                                                <AccountBalanceWalletRoundedIcon />
                                              }
                                            >
                                              {data.paid_amount}
                                            </Button>
                                          </Tooltip>
                                        ) : (
                                          <Tooltip
                                            //  title="You have no Dues"
                                            size={10}
                                          >
                                            <Button
                                              disabled
                                              variant="outlined"
                                              color="default"
                                              size="small"
                                            >
                                              {data.paid_amount}
                                            </Button>
                                          </Tooltip>
                                        )}
                                      </td>
                                      <td>
                                        {data.due_amount > 0 ? (
                                          <Tooltip
                                            title="Pay Dues Amount"
                                            size={10}
                                          >
                                            <Button
                                              variant="outlined"
                                              onClick={() =>
                                                dueAmountFunc(data.id)
                                              }
                                              color="secondary"
                                              size="small"
                                              startIcon={<CurrencyRupeeIcon />}
                                            >
                                              {data.due_amount}
                                            </Button>
                                          </Tooltip>
                                        ) : (
                                          <Tooltip
                                            title="You have no Dues"
                                            size={20}
                                          >
                                            <Button
                                              disabled
                                              variant="outlined"
                                              color="default"
                                              size="small"
                                            >
                                              {data.due_amount}
                                            </Button>
                                          </Tooltip>
                                        )}
                                      </td>
                                      <td>
                                        <div className="d-flex justify-content space-between p-2">
                                          <span>
                                            <Tooltip
                                              title="show bill"
                                              size={20}
                                            >
                                              <Button
                                                onClick={() =>
                                                  collapseBody(
                                                    data.id,
                                                    index,
                                                    data.discount,
                                                    data.paid_amount,
                                                    data.doctor_name,
                                                    data.degree
                                                  )
                                                }
                                                variant="outlined"
                                                color="primary"
                                                style={{ fontSize: "10px" }}
                                                size="small"
                                              >
                                                <LocalPrintshopRoundedIcon />
                                              </Button>
                                            </Tooltip>
                                          </span>
                                          <Link to={`/report/${data.id}`}>
                                            <span className="ml-2">
                                              <Tooltip
                                                title="view Report"
                                                size={15}
                                              >
                                                <Button
                                                  variant="outlined"
                                                  color="primary"
                                                  size="small"
                                                >
                                                  <SubjectRoundedIcon />
                                                </Button>
                                              </Tooltip>
                                            </span>
                                          </Link>
                                        </div>
                                      </td>
                                      {/* <i
                                        class="fa fa-trash"
                                        onClick={() =>
                                          deleteTransaction(data.id)
                                        }
                                      ></i>{" "} */}
                                      {/* </Link> */}
                                    </tr>
                                  }
                                  <tr>
                                    <td colSpan={8} style={{ padding: "0%" }}>
                                      {data.expand && (
                                        <>
                                          <div>
                                            <div class="table-responsive">
                                              {data.expand &&
                                              data.expanded_data.length ===
                                                0 ? (
                                                <>
                                                  <PatientEntrySkeleton />
                                                </>
                                              ) : (
                                                <>
                                                  <table class="table">
                                                    <thead>
                                                      <tr className="table-bordered">
                                                        {/* <th scope="col">Transaction Type</th> */}
                                                        <th scope="col">
                                                          Test Name
                                                        </th>
                                                        <th scope="col">
                                                          Price
                                                        </th>

                                                        <th scope="col">
                                                          {" "}
                                                          <PDFDownloadLink
                                                            document={
                                                              <ReactToPdf
                                                                patientTrans={
                                                                  patientTrans
                                                                }
                                                                discountMain={
                                                                  discountMain
                                                                }
                                                                totalPrice={
                                                                  totalPrice
                                                                }
                                                                degreeReferral={
                                                                  degreeReferral
                                                                }
                                                                total={total}
                                                                transactiondata={
                                                                  transactiondata
                                                                }
                                                                referral={
                                                                  referral
                                                                }
                                                                reportingDate={
                                                                  reportingDate
                                                                }
                                                                singleData={
                                                                  singleData
                                                                }
                                                              />
                                                            }
                                                            fileName={
                                                              singleData.patient_name
                                                            }
                                                          >
                                                            {({ loading }) =>
                                                              loading ? (
                                                                <Button
                                                                  variant="outlined"
                                                                  color="secondary"
                                                                >
                                                                  Pdf...
                                                                </Button>
                                                              ) : (
                                                                <Button
                                                                  variant="outlined"
                                                                  color="primary"
                                                                >
                                                                  Billing Pdf
                                                                </Button>
                                                              )
                                                            }
                                                          </PDFDownloadLink>
                                                        </th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      {data.expanded_data.map(
                                                        (data, index) => (
                                                          <tr key={index}>
                                                            {/* <td>{data.transaction_type} </td> */}
                                                            <td>
                                                              {data.test_name}
                                                            </td>

                                                            <td>
                                                              {data.price}
                                                            </td>
                                                          </tr>
                                                        )
                                                      )}
                                                    </tbody>
                                                    {/* <div style={{ position: "absolute", right: "7%" }}>
                <h5 className="text-muted">Total Amount: {total}/-</h5>
              </div> */}
                                                  </table>
                                                </>
                                              )}
                                            </div>
                                          </div>
                                        </>
                                      )}
                                    </td>
                                  </tr>
                                </>
                              ))}
                          </tbody>
                        </>
                      ) : (
                        <>
                          <div className="ml-sm-5">
                            <div className="ml-sm-5">
                              {/* <FaNetworkWired
               className=""
               style={{ fontSize: "120px", opacity: "0.4" }}
             /> */}
                              <img
                                style={{
                                  width: "30%",
                                  opacity: "0.8",
                                  marginLeft: "30%",
                                }}
                                className="img-fluid mb-4 mt-4 mr-5"
                                src="https://i.ibb.co/197k5Gb/inbox.png"
                                alt="dae"
                              />
                              <p
                                className="text-muted text-center"
                                style={{ marginLeft: "-8%" }}
                              >
                                No transaction today!
                              </p>
                            </div>
                          </div>
                        </>
                      )}

                      <div style={{ position: "absolute", right: "5%" }}>
                        <h5 className="text-muted mt-5">
                          Total Amount: {total}/-
                        </h5>
                      </div>
                    </table>
                  </>
                )}
                <div></div>
              </div>
            </>
          ) : (
            <>
              <div className="ml-sm-5">
                <div className="ml-sm-5">
                  {/* <FaNetworkWired
               className=""
               style={{ fontSize: "120px", opacity: "0.4" }}
             /> */}
                  <img
                    style={{
                      width: "30%",
                      opacity: "0.8",
                      marginLeft: "30%",
                    }}
                    className="img-fluid mb-4 mt-4 mr-5"
                    src="https://i.ibb.co/197k5Gb/inbox.png"
                    alt="dae"
                  />
                  <p
                    className="text-muted text-center"
                    style={{ marginLeft: "-8%" }}
                  >
                    No transactions yet !
                  </p>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default LabTransactionByUser;
