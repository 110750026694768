import React, { useState, useEffect } from "react";
import Header from "../Components/Header/Header";
import { getRequest } from "../Components/Constants/ApiCall";

import { Button } from "@material-ui/core";
//import AddTransaction from "../Components/AddTransaction/Index";
import { useParams } from "react-router";
import ReactToPdf from "./LabReactToPdf";
import { PDFDownloadLink } from "@react-pdf/renderer";

const LabPatientTransaction = (props) => {
  const [patientTrans, setPatientTrans] = useState([]);
  const [singleData, setSingleData] = useState({
    patient_name: "",
    gender: "",
    test_name: "",
    reffred_by: "",
    business: "",
    patient: "",
    test: "",
    billing_type: "",
    billing_stage: "",
    mrp: "",
    transaction: "",
    discount: "",
    final_amount: "",
    discount_description: "",
    user: "",
    quantity: "",
    id: "",
  });
  const [reportingDate] = useState({
    todayDate: new Date().toDateString().slice(0, 10),
    todayTime: new Date().toLocaleTimeString().slice(0, 11),
  });

  var { patient_name } = useParams();
  const getpatientTrans = async () => {
    try {
      const response = await getRequest(
        `/lab/billing/transaction/${props.match.params.id}`
      );
      const data = await response.json();
      if (response.status === 200) {
        setPatientTrans(data);
        console.log("PATIENT TRANSACTION", data);
        if (data.length >= 0) {
          setSingleData(data[0]);
         
        }
        console.log(
          "date & time",
          reportingDate.todayDate,
          reportingDate.todayTime
        );
      }

      // console.log("PATIENT TRANSACTION", patientTrans);
      // console.log("singleData",singleData)
    } catch (err) {
      console.log(err);
    }
  };

  const totalAmount = patientTrans.map((data) => parseInt(data.final_amount));
  console.log("TOTAL", totalAmount);

  const total = totalAmount.reduce((acc, item) => {
    return acc + item;
  }, 0);

  useEffect(() => {
    getpatientTrans();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <Header />
      <div className="inner_page box">
        <div class="container">
          {/* <AddTransaction /> */}
          <div className="row">
            <div className="col-sm-6 input-group input-group-md input-group-navbar py-3">
              <h6 className="text-muted">
                Patient Name:<strong> {patient_name}</strong>
              </h6>
              {/* <input
                type="date"
                className="form-control calendar "
                name="dob"
                id="dov"
                placeholder=""
              />

              <div className="pl-2">
                {" "}
                <Button variant="contained" color="primary">
                  search by category
                </Button>
               
              </div> */}
            </div>

            <div className="col-sm-3 input-group input-group-md input-group-navbar py-3 ml-auto">
              {/* <input
                className="form-control"
                id="searchInput"
                type="search"
                placeholder=" Search Entries"
              />

              <button className="btn" type="button">
                <i className="fas fa-search" style={{ fontSize: "14px" }} />
              </button> */}
              {/* <Button variant="contained" color="primary">  </Button> */}
              <PDFDownloadLink
                document={
                  <ReactToPdf
                    patient_name={patient_name}
                    patientTrans={patientTrans}
                    total={total}
                    reportingDate={reportingDate}
                    singleData={singleData}
                  />
                }
                fileName={patient_name}
              >
                {({ loading }) =>
                  loading ? (
                    <Button variant="outlined" color="secondary">
                      Loading Pdf...
                    </Button>
                  ) : (
                    <Button variant="outlined" color="primary">
                      Download Pdf
                    </Button>
                  )
                }
              </PDFDownloadLink>
            </div>
          </div>
          <br />
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr className="table-bordered">
                  {/* <th scope="col">Transaction Type</th> */}
                  <th scope="col">Test Name</th>
                  <th scope="col">Price</th>

                  <th scope="col">Discount</th>

                  <th scope="col">Payable Amount</th>
                </tr>
              </thead>
              <tbody>
                {patientTrans.map((data, index) => (
                  <tr key={index}>
                    {/* <td>{data.transaction_type} </td> */}
                    <td>{data.test_name}</td>

                    <td>{data.mrp}</td>

                    <td>{data.discount}</td>
                    <td>{data.final_amount}</td>
                  </tr>
                ))}
              </tbody>
              <div style={{ position: "absolute", right: "7%" }}>
                <h5 className="text-muted">Total Amount: {total}/-</h5>
              </div>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LabPatientTransaction;
