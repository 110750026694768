import React from "react";
import { Text, Image, View, StyleSheet } from "@react-pdf/renderer";
import doc from "../../../src/docs.png";

const styles = StyleSheet.create({
  signContainer: {
    //marginTop: "10px",
    fontSize: 9,
  },
  image: {
    marginRight: "3%",
    width: "90px",
  },
  sign1: {
    fontWeight: "extrabold",
  },
});

const InvoiceTableFooter = () => {
  return (
    <>
      <View style={styles.signContainer} fixed>
        <Image style={styles.image} src={doc}></Image>
        <Text style={styles.sign1}>Dr. B.N. Prasad</Text>
        <Text style={styles.sign}>DCP, DCH, TUTOR PATHO. </Text>
        <Text style={styles.sign}>PMCH, PATNA</Text>
      </View>
    </>
  );
};

export default InvoiceTableFooter;
