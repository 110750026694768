import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export default function PatientEntrySkeleton() {
  const skeletonNumber = [
    { card: "1" },
    { card: "2" },
    { card: "3" },
    { card: "4" },
  ];
  return (
    <div>
      <table class="table">
        {skeletonNumber.map((id) => (
          <>
            <div key={id}>
              <tbody>
                <tr>
                  <td>
                    <Skeleton width="140px" height="40px" />{" "}
                  </td>

                  <td>
                    {" "}
                    <Skeleton width="140px" height="40px" />{" "}
                  </td>
                  <td>
                    {" "}
                    <Skeleton width="140px" height="40px" />{" "}
                  </td>
                  <td>
                    {" "}
                    <Skeleton width="140px" height="40px" />{" "}
                  </td>
                  <td>
                    {" "}
                    <Skeleton width="140px" height="40px" />{" "}
                  </td>
                  <td>
                    {" "}
                    <Skeleton width="140px" height="40px" />{" "}
                  </td>
                </tr>
              </tbody>
            </div>
          </>
        ))}
      </table>
    </div>
  );
}
