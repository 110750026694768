import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

// const borderColor = "#90e5fc";
const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    alignItems: "center",
    fontStyle: "bold",
    color: "white",
  },

  groupName: {
    fontSize: 9,
    width: "40%",
    textAlign: "center",
    // borderRightColor: borderColor,
    // borderRightWidth: 1,
    paddingRight: 8,
  },
  mrp: {
    fontSize: 9,
    textAlign: "center",
    width: "20%",
    paddingRight: 8,
    // borderRightColor: borderColor,
    // borderRightWidth: 1,
  },

  discount: {
    width: "40%",
    textAlign: "center",
    paddingRight: 8,
    fontSize: 9,
  },
  paidAmount: {
    width: "20%",
    textAlign: "center",
    fontSize: 9,
    paddingRight: 8,
  },
});

const InvoiceTableBlankSpace = ({ rowsCount }) => {
  const blankRows = Array(rowsCount).fill(0);
  const rows = blankRows.map((x, i) => (
    <View style={styles.row} key={`BR${i}`}>
      <Text style={styles.groupName}>-</Text>
      <Text style={styles.mrp}>-</Text>

      <Text style={styles.discount}>-</Text>
    </View>
  ));
  return <Fragment>{rows}</Fragment>;
};

export default InvoiceTableBlankSpace;
