import React, { useState } from "react";
import { Link } from "react-router-dom";
import { TextField } from "@material-ui/core";
import { AiOutlineSearch } from "react-icons/ai";
import Tooltip from "@mui/material/Tooltip";
import AddPatientModal from "../AddPatientModal/Index";
import ServiceSkeleton from "../SkeletonComponents/ServiceSkeleton";
import "./Header.css";

function Header({
  leftbar,
  showNetworkHeader,
  showSettingsHeader,
  showDealFlowHeader,
  showStartUpHeader,
  getPatientData,
}) {
  // const [homeHeader] = useState(mainHeader);
  const [userData, setUserData] = useState("");
  const [open, setOpen] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [current] = useState(window.location.pathname);
  const [user] = useState(localStorage.getItem("userDetail"));
  const [showSkeleton] = useState(true);
  const [nav, showNav] = useState(true);
  const removeToken = () => {
    localStorage.removeItem("userDetail");
    localStorage.removeItem("roles");
    localStorage.removeItem("loginToken");
  };

  function btn_toggle() {
    showNav(!nav);
  }
  setTimeout(() => {
    setUserData(localStorage.getItem("roles"));
  }, 0);
  return (
    <div>
      <AddPatientModal
        open={open}
        close={setOpen}
        // getPatientData={getPatientData}
      />
      <div>
        <nav className="navbar navbar-expand-md  navbar-light o-topbar1">
          <div className="container-fluid topbar_padding">
            <Link
              to="/"
              className="navbar-brand o-topbar1__brand d-none d-md-none d-lg-block"
            >
              <img
                style={{
                  width: "250px",
                  height: "55px",
                  objectFit: "contain",
                }}
                src="/pathlabs.png"
                alt="HealthX Logo"
              />
            </Link>
            {/* <h4 className="kubers_text"> HealthX </h4> */}

            <div>
              <ul className="navbar-nav flex-row nav o-topbar1__nav">
                <li
                  className={
                    showSearch
                      ? "nav-item d-none d-sm-block"
                      : "nav-item d-block d-sm-block"
                  }
                >
                  <div class="dropdown " className="py-4 px-3 px-sm-4">
                    {userData && userData.includes("ap") && (
                      <Tooltip title="Add Patient">
                        <div
                          onClick={() => setOpen(true)}
                          style={{
                            cursor: "pointer",
                            fontSize: "20px",
                            marginTop: "-2px",
                          }}
                        >
                          <i className="fa fa-user-plus text-primary"></i>
                        </div>
                      </Tooltip>
                    )}
                  </div>
                </li>
                <li
                  onClick={() => {
                    setShowSearch(!showSearch);
                    console.log("value", showSearch);
                  }}
                  className={
                    showSearch
                      ? "nav-item d-none d-sm-none d-lg-none"
                      : "nav-item d-block d-sm-none d-lg-none "
                  }
                >
                  <div class="dropdown">
                    <div class="py-4 px-3 px-sm-4" data-toggle="dropdown">
                      <AiOutlineSearch
                        style={{ fontSize: "1.5rem", color: "#aaaaaa" }}
                      />
                      <span class="caret"></span>
                    </div>
                  </div>
                </li>
                <li
                  className={
                    showSearch
                      ? "nav-item pt-2 d-block d-sm-none"
                      : "nav-item pt-2 d-none d-sm-none"
                  }
                >
                  <form
                    style={{ position: "relative" }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      //   onChange={(e) => setSearch(e.target.value)}
                      className="search_bar"
                      id="standard-basic"
                      label="search here"
                    />
                    <i class="fas fa-times times_icon"></i>
                  </form>
                </li>

                <form
                  className="ms-auto me-4 d-none d-lg-block"
                  id="searchForm"
                >
                  <div className="input-group input-group-sm input-group-navbar">
                    <input
                      className="form-control"
                      id="searchInput"
                      type="search"
                      placeholder=" Search Patient"
                    />

                    <button className="btn" type="button">
                      <i className="fas fa-search" />
                    </button>
                  </div>
                </form>

                <li className="nav-item" style={{ cursor: "pointer" }}>
                  <div class="dropdown">
                    <div style={{ padding: "0.869rem" }} data-toggle="dropdown">
                      <img
                        alt="avatar"
                        className="avatar p-1"
                        src="https://d19m59y37dris4.cloudfront.net/bubbly/1-2/img/avatar-6.jpg"
                      />
                      <span class="caret"></span>
                    </div>
                    <div
                      class="dropdown-menu dropdown-menu-end dropdown-menu-animated"
                      aria-labelledby="userInfo"
                      data-bs-popper="none"
                    >
                      <div class="dropdown-header text-gray-700">
                        <h6
                          class="text-uppercase font-weight-bold"
                          style={{ fontSize: "12px" }}
                        >
                          {user}
                        </h6>
                        {/* <small
                          className="text-muted"
                          style={{ fontSize: "0.875rem" }}
                        >
                          investor
                        </small> */}
                      </div>
                      {/* <div class="dropdown-divider"></div>
                      <Link class="dropdown-item" to="/settings">
                        Settings
                      </Link> */}
                      {/* <a class="dropdown-item" href="#">
                        Activity log
                      </a> */}
                      <div class="dropdown-divider"></div>
                      <Link to="/login">
                        <div
                          onClick={() => removeToken()}
                          class="dropdown-item"
                        >
                          Logout
                        </div>
                      </Link>
                    </div>
                  </div>
                </li>
                {/* <li className="nav-item">
                <a
                  className="nav-a o-topbar1__nava font-weight-bold"
                  href="#!"
                >
                  Menu2
                </a>
              </li> */}
                {/* <li className="nav-item">
                <a
                  className="nav-a o-topbar1__nava font-weight-bold"
                  href="#!"
                >
                  Menu3
                </a>
              </li> */}
              </ul>
            </div>

            {/* script in assets/js/theme-scripts.js */}
            <button
              onClick={() => btn_toggle()}
              id="js-navbarToggler"
              style={{ margin: "10px" }}
              className="navbar-toggler d-block d-md-block d-lg-none o-topbar1__navbarToggler js-navbarToggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span
                className={
                  nav
                    ? "a-navbarToggler1 js-navbarTogglerIcon"
                    : "a-navbarToggler1 js-navbarTogglerIcon open"
                }
              >
                <span />
                <span />
                <span />
              </span>
            </button>
          </div>
        </nav>
      </div>
      {leftbar ? (
        <></>
      ) : (
        <>
          <div
            className={
              nav ? "vertical-nav bg-white" : "vertical-nav bg-white active"
            }
            id="sidebar"
          >
            {userData === null || userData.length === 0 ? (
              <>
                <ServiceSkeleton />
              </>
            ) : (
              <div>
                <p
                  style={{ marginTop: "29%" }}
                  className="text-gray bg-light font-weight-bold text-uppercase px-3 small p-3 mb-0"
                >
                  Dashboard
                </p>
                <ul className="nav flex-column bg-white mb-0">
                  {userData && userData.includes("pl") && (
                    <li
                      className="nav_item d-flex justify-content-between align-items-center"
                      style={
                        current === "/patient-entry"
                          ? {
                              color: "#7579e7",
                              fontWeight: "600",
                              backgroundColor: "#f3f3ff",
                              borderLeft: "4px solid #7579e7",
                            }
                          : null
                      }
                    >
                      <Link to="/patient-entry" className="nav-link text-dark">
                        <i className="fas fa-users mr-3 text-primary" />
                        Patients List
                      </Link>
                    </li>
                  )}
                  {userData && userData.includes("lr") && (
                    <li
                      className="nav_item d-flex justify-content-between align-items-center"
                      style={
                        current === "/lab/view/labreports"
                          ? {
                              color: "#7579e7",
                              fontWeight: "600",

                              backgroundColor: "#f3f3ff",
                              borderLeft: "4px solid #7579e7",
                            }
                          : null
                      }
                    >
                      <Link
                        to="/lab/view/labreports"
                        className="nav-link text-dark"
                      >
                        <i className="fas fa-users mr-3 text-primary" />
                        Lab Reporting
                      </Link>
                    </li>
                  )}
                  {userData && userData.includes("lpa") && (
                    <li
                      className="nav_item d-flex justify-content-between align-items-center"
                      style={
                        current === "/lab/pending-approved/labreports"
                          ? {
                              color: "#7579e7",
                              fontWeight: "600",
                              backgroundColor: "#f3f3ff",
                              borderLeft: "4px solid #7579e7",
                            }
                          : null
                      }
                    >
                      <Link
                        to="/lab/pending-approved/labreports"
                        className="nav-link text-dark"
                      >
                        <i className="fas fa-users mr-3 text-primary" />
                        Lab Pending Approved
                      </Link>
                    </li>
                  )}
                  {userData && userData.includes("vpe") && (
                    <li
                      className="nav_item d-flex justify-content-between align-items-center"
                      style={
                        current === "/lab/patients/entry-wise"
                          ? {
                              color: "#7579e7",
                              fontWeight: "600",
                              backgroundColor: "#f3f3ff",
                              borderLeft: "4px solid #7579e7",
                            }
                          : null
                      }
                    >
                      <Link
                        to="/lab/patients/entry-wise"
                        className="nav-link text-dark"
                      >
                        <i className="fas fa-users mr-3 text-primary" />
                        View Patient By Entry
                      </Link>
                    </li>
                  )}

                  {userData && userData.includes("te") && (
                    <li
                      className="nav_item d-flex justify-content-between align-items-center"
                      style={
                        current === "/lab/transactions/entry-wise"
                          ? {
                              color: "#7579e7",
                              fontWeight: "600",
                              backgroundColor: "#f3f3ff",
                              borderLeft: "4px solid #7579e7",
                            }
                          : null
                      }
                    >
                      <Link
                        to="/lab/transactions/entry-wise"
                        className="nav-link text-dark"
                      >
                        <i className="fas fa-users mr-3 text-primary" />
                        Transaction By Entry
                      </Link>
                    </li>
                  )}
                  {userData && userData.includes("th") && (
                    <li
                      className="nav_item d-flex justify-content-between align-items-center"
                      style={
                        current === "/lab/transactions-history"
                          ? {
                              color: "#7579e7",
                              fontWeight: "600",
                              backgroundColor: "#f3f3ff",
                              borderLeft: "4px solid #7579e7",
                            }
                          : null
                      }
                    >
                      <Link
                        to="/lab/transactions-history"
                        className="nav-link text-dark"
                      >
                        <i className="fa fa-money check mr-3 text-primary fa-fw" />
                        Transaction History
                      </Link>
                    </li>
                  )}
                  {userData && userData.includes("lta") && (
                    <li
                      className="nav_item d-flex justify-content-between align-items-center"
                      style={
                        current === "/lab/transactions"
                          ? {
                              color: "#7579e7",
                              fontWeight: "600",
                              backgroundColor: "#f3f3ff",
                              borderLeft: "4px solid #7579e7",
                            }
                          : null
                      }
                    >
                      <Link
                        to="/lab/transactions"
                        className="nav-link text-dark"
                      >
                        <i className="fa fa-money check mr-3 text-primary fa-fw" />
                        Lab Transaction All
                      </Link>
                    </li>
                  )}
                  {userData && userData.includes("ldt") && (
                    <li
                      className="nav_item d-flex justify-content-between align-items-center"
                      style={
                        current === "/view/dues-transactions"
                          ? {
                              color: "#7579e7",
                              fontWeight: "600",

                              backgroundColor: "#f3f3ff",
                              borderLeft: "4px solid #7579e7",
                            }
                          : null
                      }
                    >
                      <Link
                        to="/view/dues-transactions"
                        className="nav-link text-dark"
                      >
                        <i className="fa fa-money check mr-3 text-primary fa-fw" />
                        Lab Dues Transactions
                      </Link>
                    </li>
                  )}

                  {/* {userData && userData.includes("tbb") && (
                    <li
                      className="nav_item d-flex justify-content-between align-items-center"
                      style={
                        current === "/view/lab-transactions"
                          ? {
                              color: "#7579e7",
                              fontWeight: "600",
                              backgroundColor: "#f3f3ff",
                              borderLeft: "4px solid #7579e7",
                            }
                          : null
                      }
                    >
                      <Link
                        to="/view/lab-transactions"
                        className="nav-link text-dark"
                      >
                        <i className="fa fa-money check mr-3 text-primary fa-fw" />
                        Transaction By Business
                      </Link>
                    </li>
                  )} */}
                  {userData && userData.includes("ma") && (
                    <li
                      className="nav_item d-flex justify-content-between align-items-center"
                      style={
                        current === "/settings/manage-accounts"
                          ? {
                              color: "#7579e7",
                              fontWeight: "600",
                              backgroundColor: "#f3f3ff",
                              borderLeft: "4px solid #7579e7",
                            }
                          : null
                      }
                    >
                      <Link
                        to="/settings/manage-accounts"
                        className="nav-link text-dark"
                      >
                        <i className="fas fa-users mr-3 text-primary" />
                        Account Manage
                      </Link>
                    </li>
                  )}
                  {userData.includes("tl") && (
                    <li
                      className="nav_item d-flex justify-content-between align-items-center"
                      style={
                        current === "/lab/view/test-list"
                          ? {
                              color: "#7579e7",
                              fontWeight: "600",
                              backgroundColor: "#f3f3ff",
                              borderLeft: "4px solid #7579e7",
                            }
                          : null
                      }
                    >
                      <Link
                        to="/lab/view/test-list"
                        className="nav-link text-dark"
                      >
                        <i className="fas fa-users mr-3 text-primary" />
                        Test List
                      </Link>
                    </li>
                  )}
                  {/* {hideHomeHeader ? null : (
                  <>
                    {homeHeader.map((menu, id) => (
                      <div key={id}>
                        <li className="nav_item d-flex justify-content-between align-items-center">
                          <Link to={menu.link} className="nav-link text-dark">
                            <i className={menu.icon} />
                            {menu.value}
                          </Link>
                          {menu.plusIcon ? (
                            <Link to={menu.iconLink}>
                              <IconButton>
                                <AddIcon
                                  style={{ fontSize: "1.3rem" }}
                                  color="primary"
                                />
                              </IconButton>
                            </Link>
                          ) : (
                            ""
                          )}
                        </li>
                      </div>
                    ))}
                  </>
                )} */}

                  {showNetworkHeader ? (
                    <>
                      <div>
                        {showSkeleton ? (
                          <>
                            <li>
                              <divs
                                width="70%"
                                height="30px"
                                style={{
                                  marginLeft: "10px",
                                  marginBottom: "10px",
                                  marginTop: "17px",
                                }}
                              />{" "}
                            </li>
                            <li>
                              <divs
                                count={7}
                                width="70%"
                                height="30px"
                                style={{
                                  marginLeft: "10px",
                                  marginBottom: "10px",
                                }}
                              />{" "}
                            </li>
                          </>
                        ) : (
                          <>
                            {/* <li className="nav_item d-flex justify-content-between align-items-center">
                            <a to={"/network/"} className="nav-link text-dark">
                              <i className="fa fa-th-large mr-3 text-primary fa-fw" />
                              Home
                            </a>
                          </li> */}
                          </>
                        )}
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  {showDealFlowHeader ? (
                    <>
                      {/* {dealFlowHeader.map((menu, id) => (
                      <div key={id}>
                        <li className="nav_item d-flex justify-content-between align-items-center">
                          <a to={menu.link} className="nav-link text-dark">
                            <i className={menu.icon} />
                            {menu.value}
                          </a>
                        </li>
                      </div>
                    ))} */}
                    </>
                  ) : (
                    ""
                  )}

                  {/* {showStartUpHeader ? (
                  <>
                    {startUpHeader.map((menu, id) => (
                      <div key={id}>
                        <li className="nav_item d-flex justify-content-between align-items-center">
                          <a
                            to={menu.link}
                            id={menu.id}
                            className="nav-link text-dark"
                          >
                            <i className={menu.icon} />
                            {menu.value}
                          </a>
                        </li>
                      </div>
                    ))}
                  </>
                ) : (
                  ""
                )} */}

                  {showStartUpHeader ? (
                    <>
                      <div>
                        <li className="nav_item d-flex justify-content-between align-items-center">
                          <a href="/startup/" className="nav-link text-dark">
                            <i className="fa fa-th-large mr-3 text-primary fa-fw" />
                            Home
                          </a>
                        </li>
                        {/* <li className="nav_item d-flex justify-content-between align-items-center">
                        <a
                          to={"/startup/" + slug + "/newpage"}
                          className="nav-link text-dark"
                        >
                          <i className="fa fa-area-chart mr-3 text-primary fa-fw" />
                          Investors
                        </a>
                      </li> */}
                        {/* <li className="nav_item d-flex justify-content-between align-items-center">
                          <a
                            to={"/startup/" + slug + "/events"}
                            className="nav-link text-dark"
                          >
                            <i className="fa fa-area-chart mr-3 text-primary fa-fw" />
                            Events
                          </a>
                        </li> */}
                        <li className="nav_item d-flex justify-content-between align-items-center">
                          <div className="nav-link text-dark">
                            <i className="fa fa-area-chart mr-3 text-primary fa-fw" />
                            All Bills
                          </div>
                        </li>
                        <li className="nav_item d-flex justify-content-between align-items-center">
                          <div className="nav-link text-dark">
                            <i className="fa fa-area-chart mr-3 text-primary fa-fw" />
                            Add Services
                          </div>
                        </li>
                        <li className="nav_item d-flex justify-content-between align-items-center">
                          <div className="nav-link text-dark">
                            <i className="fa fa-area-chart mr-3 text-primary fa-fw" />
                            Patients Q
                          </div>
                        </li>
                        <li className="nav_item d-flex justify-content-between align-items-center">
                          {/* <div className="nav-link text-dark">
                          <i className="fa fa-area-chart mr-3 text-primary fa-fw" />
                          Settings
                        </div> */}
                        </li>
                      </div>
                    </>
                  ) : (
                    ""
                  )}

                  {showSettingsHeader ? (
                    <>
                      {/* {settingsHeader.map((menu, id) => (
                      <div key={id}>
                        <li className="nav_item d-flex justify-content-between align-items-center">
                          <a to={menu.link} className="nav-link text-dark">
                            <i className={menu.icon} />
                            {menu.value}
                          </a>
                        </li>
                      </div>
                    ))} */}
                    </>
                  ) : (
                    ""
                  )}

                  {/* <li className="nav_item d-flex justify-content-between align-items-center">
                  <a to="/startup" className="nav-link text-dark">
                    <i className="fa fa-cubes mr-3 text-primary fa-fw" />
                    Startups
                  </a>
                  <a to="/create/startup">
                    <IconButton>
                      <AddIcon style={{ fontSize: "1.3rem" }} color="primary" />
                    </IconButton>
                  </a>
                </li>
                <li className="nav_item d-flex justify-content-between align-items-center">
                  <a to="/investor" className="nav-link text-dark">
                    <i className="fa fa-picture-o mr-3 text-primary fa-fw" />
                    Investment <span className="p-4">Opportunities</span>
                  </a>
                  <a to="/create/investor">
                    <IconButton>
                      <AddIcon style={{ fontSize: "1.3rem" }} color="primary" />
                    </IconButton>
                  </a>
                </li>
              </ul>
              <ul className="nav flex-column bg-white mb-0">
                <li className="nav_item d-flex justify-content-between align-items-center">
                  <a to="/mentor" className="nav-link text-dark">
                    <i className="fa fa-area-chart mr-3 text-primary fa-fw" />
                    Mentor
                  </a>
                  <a to="/create/mentor">
                    <IconButton>
                      <AddIcon style={{ fontSize: "1.3rem" }} color="primary" />
                    </IconButton>
                  </a>
                </li>
                <li className="nav_item d-flex justify-content-between align-items-center">
                  <a href="" className="nav-link text-dark">
                    <i className="fa fa-bar-chart mr-3 text-primary fa-fw" />
                    Connections
                  </a>
                </li>
                <li className="nav_item d-flex justify-content-between align-items-center">
                  <a to="/feeds" className="nav-link text-dark">
                    <i className="fa fa-pie-chart mr-3 text-primary fa-fw" />
                    News Feeds
                  </a>
                </li>
                <li className="nav_item d-flex justify-content-between align-items-center">
                  <a href="#" className="nav-link text-dark">
                    <i className="fa fa-line-chart mr-3 text-primary fa-fw" />
                    Invite
                  </a>
                </li>
                <li className="nav_item d-flex justify-content-between align-items-center">
                  <a to="/settings/profile" className="nav-link text-dark">
                    <i className="fa fa-line-chart mr-3 text-primary fa-fw" />
                    Settings
                  </a>
                </li> */}
                </ul>
              </div>
            )}
          </div>
        </>
      )}
      {/* End vertical navbar */}
      {/* Page content holder */}
      <div className="page-content" id="content"></div>

      {/* <div
        className={
          nav ? "vertical-nav bg-white" : "vertical-nav bg-white active"
        }
        id="sidebar"
      >
        <div>
          <p
            style={{ marginTop: "29%" }}
            className="text-gray bg-light font-weight-bold text-uppercase px-3 small p-3 mb-0"
          >
            Dashboard
          </p>
          <ul className="nav flex-column bg-white mb-0">
            <li className="nav_item d-flex justify-content-between align-items-center">
              <a className="nav-link text-dark">
                <i />
              hello
              </a>

              <a>
                <IconButton>
                  <AddIcon style={{ fontSize: "1.3rem" }} color="primary" />
                </IconButton>
              </a>
            </li>
          </ul>
        </div>
      </div> */}
    </div>
  );
}

export default Header;
