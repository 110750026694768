import React, { useState, useEffect } from "react";
import Header from "../Components/Header/Header";
import { getRequest, postRequest } from "../Components/Constants/ApiCall";
import { Button, IconButton } from "@material-ui/core";
import ReactToPdf from "./LabReactToPdf";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import LocalPrintshopRoundedIcon from "@mui/icons-material/LocalPrintshopRounded";
import SubjectRoundedIcon from "@mui/icons-material/SubjectRounded";
import MenuItem from "@mui/material/MenuItem";
import AccountBalanceWalletRoundedIcon from "@mui/icons-material/AccountBalanceWalletRounded";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import "../App.css";
import { Link } from "react-router-dom";
import { PDFDownloadLink } from "@react-pdf/renderer";
import DueAmountModal from "../Components/DueAmountModal/Index";
import RefundAmountModal from "../Components/RefundAmountModal/Index";
import PatientEntrySkeleton from "../Components/SkeletonComponents/PatientEntrySkeleton";
import Tooltip from "@mui/material/Tooltip";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
const LabTransactionHistory = (props) => {
  const [transactiondata, setTransactionData] = useState([]);
  const [transactionDataFilter, setTransactionDataFilter] = useState([]);
  const [transactionDataFilterType, setTransactionDataFilterType] = useState(
    []
  );
  const [isToggle, setIsToggle] = useState("");
  const [query, setQuery] = useState(new Date().toISOString().slice(0, 10));
  const [searchTerm, setSearchTerm] = useState("");
  const [expanded, setExpanded] = useState(false);
  const [dueId, setDueId] = useState("");
  const [totalPrice, setTotalPrice] = useState();
  const [businessData, setBusinessData] = useState([]);
  const [referral, setReferral] = useState();
  const [openC, setOpenC] = useState(false);
  const [degreeReferral, setDegree] = useState();
  const [open, setOpen] = useState(false);
  const [opens, setOpenR] = useState(false);
  const [discountMain, setDiscount] = useState();
  const [showskeleton, setShowSkeleton] = useState(true);
  const [patientTrans, setPatientTrans] = useState([]);
  const [singleData, setSingleData] = useState({
    patient_name: "",
    gender: "",
    test_name: "",
    //  reffred_by: "",
    business: "",
    patient: "",
    test: "",
    billing_type: "",
    billing_stage: "",
    mrp: "",
    transaction: "",
    discount: "",
    final_amount: "",
    discount_description: "",
    user: "",
    quantity: "",
    id: "",
  });

  const [reportingDate] = useState({
    todayDate: new Date().toDateString().slice(0, 10),
    todayTime: new Date().toLocaleTimeString().slice(0, 11),
  });

  // console.log(searchTerm);
  const getTransactionData = async () => {
    try {
      //setShowSkeleton(true);
      const response = await getRequest(`/lab/transaction?search=${query}`);
      const data = await response.json();
      if (response.status === 200 || response.status === 201) {
        setShowSkeleton(false);

        let transaction_data = [];
        data.map((item) =>
          transaction_data.push({
            ...item,
            // eslint-disable-next-line no-useless-computed-key
            ["expanded_data"]: [],
            // eslint-disable-next-line no-useless-computed-key
            ["expand"]: false,
          })
        );

        setTransactionData(transaction_data.reverse());
        setTransactionDataFilter(transaction_data);
        setTransactionDataFilterType(transaction_data);
      }
      console.log(data);
    } catch (err) {
      console.log(err);
    }
  };
  //console.log("t type", transactiondata);
  const collapseBody = async (
    id,
    index,
    discountMain,
    paid_amountMain,
    doctorName,
    degree
  ) => {
    try {
      setReferral(doctorName);
      setDegree(degree);
      setDiscount(discountMain);
      setTotalPrice(paid_amountMain);
      if (transactiondata[index]["expand"]) {
        transactiondata[index]["expand"] = false;
        setTransactionData(transactiondata);
        setExpanded(!expanded);
        return;
      } else {
        transactiondata[index]["expand"] = true;
        setTransactionData(transactiondata);
        setExpanded(!expanded);
      }
      const response = await getRequest(`/lab/billing/transaction/${id}`);
      const data = await response.json();
      if (response.status === 200) {
        setPatientTrans(data);

        setExpanded(!expanded);
        transactiondata[index]["expanded_data"] = data;

        setTransactionData(transactiondata);

        console.log("PATIENT TRANSACTION", data);
        if (data.length >= 0) {
          setSingleData(data[0]);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  const getBusiness = async () => {
    try {
      const response = await getRequest(`/business`);
      const data = await response.json();
      if (response.status === 200) {
        setBusinessData(data);
        console.log("business", data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  // const totalAmounts = transactiondata.map((data) => {
  //   if (data["disabled"] === false) {
  //     console.log("totals", totalAmounts);
  //   }
  // });

  // const totalNewAmount = transactiondata.map((data) => {
  //   if(data.disabled === true) console.log("data", data)
  // } )

  const totalNew = transactiondata.filter((item) => item.disabled === false);
  console.log("total_new", totalNew);

  const totalFinalData = totalNew.map((item) => parseInt(item.paid_amount));
  console.log("finalAmount", totalFinalData);

  const totalAmount = transactiondata.map((data) => parseInt(data.paid_amount));
  if (totalAmount.length > 0) {
    console.log("TOTAL", totalAmount);
  }

  const total = totalFinalData.reduce((acc, item) => {
    return acc + item;
  }, 0);

  //filter by business
  const dueAmountFunc = (id) => {
    console.log("dueee", id);
    setDueId(id);
    setOpen(true);
  };

  const refundAmountFunc = (id) => {
    console.log("refund", id);
    setDueId(id);
    setOpenR(true);
  };

  const filterType = (value) => {
    console.log("filterType", value);
    if (value === "combine") {
      const filterData = [...transactionDataFilterType];
      setTransactionData(filterData);
    } else {
      const filterData = [...transactionDataFilterType];
      const filterTypeData = filterData.filter((item) => {
        return item.transaction_type.toLowerCase() === value;
      });
      setTransactionDataFilter(filterData);
      setTransactionData(filterTypeData);
    }
  };
  const handleCheck = (id) => {
    setOpenC(true);
    setIsToggle(id);
  };
  const activeDisabledKey = async () => {
    if (isToggle) {
      const response = await postRequest(
        `/lab/transaction/${isToggle}`,
        {},
        "DELETE",
        true
      );
      const data = await response.json();
      if (response.status === 201 || response.status === 200) {
        toast.success("Deleted Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        console.log(data);
        setOpenC(false);
        alert("Successfully Deleted");
        getTransactionData();
      }
    }
  };
  const filterClinic = (value) => {
    // console.log("filter", value);
    if (value === "all") {
      const allDataFilter = [...transactionDataFilter];
      setTransactionData(allDataFilter);
    } else {
      const filterData = [...transactionDataFilter];
      const filterBusiness = filterData.filter((item, index) => {
        return item.business_name === value;
      });
      setTransactionData(filterBusiness);
    }
  };

  useEffect(() => {
    getTransactionData();
    getBusiness();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <ToastContainer />

      <Dialog
        open={openC}
        onClose={() => setOpenC(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Delete?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this transactions.
            <br /> This process cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenC(false)}>Disagree</Button>
          <Button onClick={() => activeDisabledKey()} AutoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
      <DueAmountModal
        open={open}
        close={setOpen}
        dueId={dueId}
        getTransactionData={getTransactionData}
      />
      <RefundAmountModal
        open={opens}
        close={setOpenR}
        dueId={dueId}
        getTransactionData={getTransactionData}
      />
      <Header />
      <div className="inner_page box">
        <div class="container">
          {/* <AddTransaction /> */}
          <div className="row"></div>
          <div className="row">
            <div className="col-sm-6 input-group input-group-md input-group-navbar ">
              <input
                type="date"
                data-date-format="dd-mm-yyyy"
                className="form-control calendar "
                name="dob"
                id="dov"
                onChange={(event) => setQuery(event.target.value)}
                value={query}
                placeholder=""
              />

              <div className="pl-2">
                {" "}
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => getTransactionData()}
                >
                  search by date
                </Button>
              </div>
            </div>

            <div className="col-sm-4 input-group input-group-md input-group-navbar py-3 ml-auto">
              <input
                className="form-control"
                id="searchInput"
                type="search"
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                }}
                placeholder=" Search by patient"
              />

              <button className="btn" type="button">
                <i className="fas fa-search" style={{ fontSize: "14px" }} />
              </button>
            </div>
          </div>
          <br />
          <div
            class="table px-2"
            style={{
              fontSize: "15px",
              width: "100%",
              height: transactiondata.length === 0 && "380px",
              overflowX: "scroll",
              whiteSpace: "nowrap",
            }}
          >
            {showskeleton ? (
              <>
                <PatientEntrySkeleton />
              </>
            ) : (
              <>
                <table class="table">
                  <thead>
                    <tr className="table-bordered">
                      <th scope="col">S. No</th>
                      <th scope="col">ID</th>
                      <th scope="col">Date & Time</th>

                      <th scope="col">Patient Name</th>

                      <th scope="col">
                        <FormControl variant="standard" sx={{ minWidth: 120 }}>
                          <InputLabel id="demo-simple-select-label">
                            clinic
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            onChange={(e) => filterClinic(e.target.value)}
                          >
                            {businessData.map((item, index) => (
                              <MenuItem key={index} value={item.name}>
                                {item.name}
                              </MenuItem>
                            ))}
                            <MenuItem value="all">All</MenuItem>
                          </Select>
                        </FormControl>
                      </th>

                      <th scope="col">Referred By</th>
                      <th scope="col">Total Amount</th>
                      <th scope="col">Discount</th>
                      <th scope="col">Paid Amount</th>

                      <th scope="col">Due Amount</th>
                      <th scope="col">Actions</th>
                      <th scope="col">
                        <FormControl variant="standard" sx={{ minWidth: 100 }}>
                          <InputLabel id="demo-simple-select-label">
                            Type
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            onChange={(e) => filterType(e.target.value)}
                          >
                            <MenuItem value="offline">Offline</MenuItem>
                            <MenuItem value="cash">Cash</MenuItem>

                            <MenuItem value="online">Online</MenuItem>

                            <MenuItem value="combine">No Filter</MenuItem>
                          </Select>
                        </FormControl>
                      </th>
                      <th scope="col">User</th>

                      {/* <th scope="col">Actions</th> */}
                    </tr>
                  </thead>
                  {transactiondata.length > 0 ? (
                    <>
                      <tbody>
                        {transactiondata

                          // eslint-disable-next-line array-callback-return
                          .filter((data) => {
                            if (searchTerm === "") {
                              return data;
                            } else if (
                              Object.values(data)
                                .join("")
                                .toLowerCase()
                                .includes(searchTerm.toLowerCase())
                            ) {
                              return data;
                            }
                          })
                          .map((data, index) => (
                            <>
                              {
                                <tr
                                  style={{
                                    backgroundColor:
                                      data.disabled === true
                                        ? "#e6ebfc"
                                        : "transparent",
                                    color: "black",
                                  }}
                                  key={index}

                                  //className="tables-css"
                                >
                                  <td
                                    style={{
                                      borderLeft:
                                        data.disabled === true
                                          ? "5px solid red"
                                          : "0px solid green",
                                    }}
                                  >
                                    {index + 1}
                                  </td>{" "}
                                  <td>{data.id} </td>{" "}
                                  <td>
                                    {" "}
                                    {new Date(
                                      data.transaction_date
                                    ).toLocaleString()}
                                  </td>
                                  <td>{data.patient_name} </td>{" "}
                                  <td>{data.business_name} </td>
                                  <td>{data.doctor_name}</td>
                                  <td>{data.total_amount}</td>
                                  <td>{data.discount}</td>
                                  <td>
                                    {data.paid_amount > 0 ? (
                                      <Tooltip title="Refund" size={12}>
                                        <Button
                                          variant="outlined"
                                          onClick={() =>
                                            refundAmountFunc(data.id)
                                          }
                                          color="primary"
                                          size="small"
                                          startIcon={
                                            <AccountBalanceWalletRoundedIcon />
                                          }
                                        >
                                          {data.paid_amount}
                                        </Button>
                                      </Tooltip>
                                    ) : (
                                      <Tooltip
                                        //  title="You have no Dues"
                                        size={10}
                                      >
                                        <Button
                                          disabled
                                          variant="outlined"
                                          color="default"
                                          size="small"
                                        >
                                          {data.paid_amount}
                                        </Button>
                                      </Tooltip>
                                    )}
                                  </td>
                                  <td>
                                    {data.due_amount > 0 ? (
                                      <Tooltip
                                        title="Pay Dues Amount"
                                        size={10}
                                      >
                                        <Button
                                          variant="outlined"
                                          onClick={() => dueAmountFunc(data.id)}
                                          color="secondary"
                                          size="small"
                                          startIcon={<CurrencyRupeeIcon />}
                                        >
                                          {data.due_amount}
                                        </Button>
                                      </Tooltip>
                                    ) : (
                                      <Tooltip
                                        title="You have no Dues"
                                        size={20}
                                      >
                                        <Button
                                          disabled
                                          variant="outlined"
                                          color="default"
                                          size="small"
                                        >
                                          {data.due_amount}
                                        </Button>
                                      </Tooltip>
                                    )}
                                  </td>
                                  <div className="d-flex justify-content space-between p-2">
                                    <span>
                                      <Tooltip title="show bill" size={20}>
                                        <Button
                                          onClick={() =>
                                            collapseBody(
                                              data.id,
                                              index,
                                              data.discount,
                                              data.paid_amount,
                                              data.doctor_name,
                                              data.degree
                                            )
                                          }
                                          variant="outlined"
                                          color="primary"
                                          style={{ fontSize: "10px" }}
                                          size="small"
                                        >
                                          <LocalPrintshopRoundedIcon />
                                        </Button>
                                      </Tooltip>
                                    </span>
                                    <Link to={`/report/${data.id}`}>
                                      <span className="ml-2">
                                        <Tooltip title="view Report" size={15}>
                                          <Button
                                            variant="outlined"
                                            color="primary"
                                            size="small"
                                          >
                                            <SubjectRoundedIcon />
                                          </Button>
                                        </Tooltip>
                                      </span>
                                    </Link>
                                  </div>
                                  <td>{data.transaction_type}</td>
                                  <td>
                                    {" "}
                                    {data.disabled === false && (
                                      <IconButton
                                        size="small"
                                        // className="deleteBTN"
                                        onClick={() => {
                                          handleCheck(data.id, index);
                                        }}
                                      >
                                        <DeleteRoundedIcon color="default" />
                                      </IconButton>
                                    )}
                                    {data.user_name}{" "}
                                  </td>
                                </tr>
                              }
                              <tr>
                                <td colSpan={8} style={{ padding: "0%" }}>
                                  {data.expand && (
                                    <>
                                      <div>
                                        <div class="table-responsive">
                                          {data.expand &&
                                          data.expanded_data.length === 0 ? (
                                            <>
                                              <PatientEntrySkeleton />
                                            </>
                                          ) : (
                                            <>
                                              <table class="table">
                                                <thead>
                                                  <tr className="table-bordered">
                                                    {/* <th scope="col">Transaction Type</th> */}
                                                    <th scope="col">
                                                      Test Name
                                                    </th>
                                                    <th scope="col">Price</th>

                                                    <th scope="col">
                                                      {" "}
                                                      <PDFDownloadLink
                                                        document={
                                                          <ReactToPdf
                                                            patientTrans={
                                                              patientTrans
                                                            }
                                                            discountMain={
                                                              discountMain
                                                            }
                                                            totalPrice={
                                                              totalPrice
                                                            }
                                                            degreeReferral={
                                                              degreeReferral
                                                            }
                                                            total={total}
                                                            transactiondata={
                                                              transactiondata
                                                            }
                                                            referral={referral}
                                                            reportingDate={
                                                              reportingDate
                                                            }
                                                            singleData={
                                                              singleData
                                                            }
                                                          />
                                                        }
                                                        fileName={
                                                          singleData.patient_name
                                                        }
                                                      >
                                                        {({ loading }) =>
                                                          loading ? (
                                                            <Button
                                                              variant="outlined"
                                                              color="secondary"
                                                            >
                                                              Pdf...
                                                            </Button>
                                                          ) : (
                                                            <Button
                                                              variant="outlined"
                                                              color="primary"
                                                            >
                                                              Billing Pdf
                                                            </Button>
                                                          )
                                                        }
                                                      </PDFDownloadLink>
                                                    </th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {data.expanded_data.map(
                                                    (data, index) => (
                                                      <tr key={index}>
                                                        <td>
                                                          {data.test_name}
                                                        </td>

                                                        <td>{data.price}</td>
                                                      </tr>
                                                    )
                                                  )}
                                                </tbody>
                                              </table>
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </td>
                              </tr>
                            </>
                          ))}
                      </tbody>
                    </>
                  ) : (
                    <>
                      <div
                        className="text-center text-dark d-none d-sm-block"
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                        }}
                      >
                        <h2 className="mt-5">No Data Found !</h2>
                      </div>
                    </>
                  )}

                  {/* <div style={{ position: "absolute", right: "5%" }}>
                    <h5 className="text-muted mt-5">Total Amount: {total}/-</h5>
                  </div> */}
                </table>
              </>
            )}
            <div></div>
          </div>
          <h5
            className="text-muted

              "
            style={{ float: "right" }}
          >
            Total Amount: {total}/-
          </h5>
        </div>
      </div>
    </div>
  );
};

export default LabTransactionHistory;
