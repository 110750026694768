import React, { useState, useEffect } from "react";
import Header from "../Components/Header/Header";
import { getRequest } from "../Components/Constants/ApiCall";
import { Button } from "@material-ui/core";
//import { useParams } from "react-router";
import PatientEntrySkeleton from "../Components/SkeletonComponents/PatientEntrySkeleton";

const DuesTransactions = () => {
  const [duesList, setDuesList] = useState([]);
  const [query, setQuery] = useState(new Date().toISOString().slice(0, 10));
  const [showskeleton, setShowSkeleton] = useState(true);

  const getDuesTransaction = async () => {
    try {
      const response = await getRequest(`/lab/due_transaction?search=${query}`);
      const data = await response.json();
      setShowSkeleton(false);
      if (response.status === 200) setDuesList(data);
      console.log("Dues Transactions", data);
    } catch (err) {
      console.log(err);
    }
  };
  const getDuesTransactionAll = async () => {
    try {
      const response = await getRequest(`/lab/due_transaction`);
      const data = await response.json();
      setShowSkeleton(false);
      if (response.status === 200) setDuesList(data);
      console.log("Dues Transactions", data);
    } catch (err) {
      console.log(err);
    }
  };
  const dueAmount = duesList.map((data) => parseInt(data.due_amount));
  if (dueAmount.length > 0) {
    console.log("Total Dues", dueAmount);
  }

  const total = dueAmount.reduce((acc, item) => {
    return acc + item;
  }, 0);

  const getAllData = () => {
    getDuesTransactionAll();
  };
  useEffect(() => {
    getDuesTransaction();
    if (getAllData) getDuesTransactionAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <Header />
      <div className="inner_page box">
        <div class="container">
          <h2 className="text-center py-2">Dues History</h2>
          <hr className="primary" />
          <div className="row">
            <div className="col-sm-6 input-group input-group-md input-group-navbar ">
              <input
                type="date"
                data-date-format="dd-mm-yyyy"
                className="form-control calendar "
                name="dob"
                id="dov"
                onChange={(event) => setQuery(event.target.value)}
                value={query}
                placeholder=""
              />
              <div className="pl-2">
                {" "}
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => getDuesTransaction()}
                >
                  search by date
                </Button>
              </div>
              <div className="pl-2">
                {" "}
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => getAllData()}
                >
                  show all
                </Button>
              </div>
            </div>
          </div>
          <br />
          <div class="table-responsive">
            {showskeleton ? (
              <>
                <PatientEntrySkeleton />
              </>
            ) : (
              <>
                <div
                  class="table px-2"
                  style={{
                    fontSize: "15px",
                    width: "100%",
                    height: "330px",
                    overflowX: "scroll",
                    whiteSpace: "nowrap",
                  }}
                >
                  <table class="table">
                    <thead>
                      <tr className="table-bordered">
                        <th scope="col">ID</th>
                        <th scope="col">Date & Time</th>
                        <th scope="col">Patient Name</th>
                        <th scope="col">Clinic</th>
                        <th scope="col">Referred By</th>{" "}
                        <th scope="col">Total Amount</th>
                        <th scope="col">Discount</th>
                        <th scope="col">Paid Amount</th>
                        <th scope="col">Due Amount</th>
                        <th scope="col">User</th>
                      </tr>
                    </thead>
                    {duesList.length > 0 ? (
                      <tbody>
                        {duesList // eslint-disable-next-line array-callback-return

                          .map((data, index) => (
                            <tr key={index} className="tables-css">
                              <td>{data.id}</td>
                              <td>
                                {" "}
                                {new Date(
                                  data.transaction_date
                                ).toLocaleString()}
                              </td>
                              <td>
                                {data.patient_name} ({data.age} / {data.gender})
                              </td>
                              <td>{data.business_name}</td>
                              <td>{data.doctor_name}</td>
                              <td>{data.total_amount}</td>
                              <td>{data.discount}</td>
                              <td>{data.paid_amount}</td>
                              <td>{data.due_amount}</td>
                              <td>{data.user_name}</td>

                              {/* </Link> */}
                            </tr>
                          ))}
                      </tbody>
                    ) : (
                      <div className="ml-sm-5">
                        <div className="ml-sm-5">
                          {/* <FaNetworkWired
                                 className=""
                                 style={{ fontSize: "120px", opacity: "0.4" }}
                               /> */}
                          {/* <img
                            style={{
                              width: "30%",
                              opacity: "0.8",
                              marginLeft: "30%",
                            }}
                            className="img-fluid mb-4 mt-5 mr-5"
                            src="https://i.ibb.co/197k5Gb/inbox.png"
                            alt="dae"
                          /> */}
                          <div
                            className="text-center text-dark d-none d-sm-block"
                            style={{
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                            }}
                          >
                            <h2 className="mt-5">No Data Found !</h2>
                          </div>
                        </div>
                      </div>
                    )}
                  </table>{" "}
                </div>
              </>
            )}
          </div>
        </div>
        <h5
          className="text-muted mr-2

              "
          style={{ float: "right" }}
        >
          Total Amount: {total}/-
        </h5>
      </div>
    </div>
  );
};

export default DuesTransactions;
