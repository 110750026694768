/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Header from "../Components/Header/Header";
import { getRequest } from "../Components/Constants/ApiCall";
import { Button } from "@material-ui/core";
import { GrTransaction } from "react-icons/gr";
import ReactToPdf from "./LabReactToPdf";
import { BsFilter, BsFilterLeft } from "react-icons/bs";
// import { useParams } from "react-router";
import { PDFDownloadLink } from "@react-pdf/renderer";
import DueAmountModal from "../Components/DueAmountModal/Index";
import RefundAmountModal from "../Components/RefundAmountModal/Index";
import PatientEntrySkeleton from "../Components/SkeletonComponents/PatientEntrySkeleton";
import { useHistory } from "react-router-dom";
//import AddTransaction from "../Components/AddTransaction/Index";
import {
  business_id_name,
} from "../Components/Constants/constant";

const LabTransactionByBusiness = (props) => {
  const [transactiondata, setTransactionData] = useState([]);
  const [transactionDataFilter, setTransactionDataFilter] = useState([]);
  const [query, setQuery] = useState(new Date().toISOString().slice(0, 10));
  const [searchTerm, setSearchTerm] = useState("");
  const [expanded, setExpanded] = useState(false);
  const [dueId, setDueId] = useState("");
  const [totalPrice, setTotalPrice] = useState();
  const [businessData, setBusinessData] = useState([]);
  const [referral, setReferral] = useState();

  const [degreeReferral, setDegree] = useState();
  const [open, setOpen] = useState(false);
  const [opens, setOpenR] = useState(false);
  const [discountMain, setDiscount] = useState();
  const [showskeleton, setShowSkeleton] = useState(true);
  const router = useHistory();
  const [patientTrans, setPatientTrans] = useState([]);
  const [singleData, setSingleData] = useState({
    patient_name: "",
    gender: "",
    test_name: "",
    //  reffred_by: "",
    business: "",
    patient: "",
    test: "",
    billing_type: "",
    billing_stage: "",
    mrp: "",
    transaction: "",
    discount: "",
    final_amount: "",
    discount_description: "",
    user: "",
    quantity: "",
    id: "",
  });

  const [reportingDate] = useState({
    todayDate: new Date().toDateString().slice(0, 10),
    todayTime: new Date().toLocaleTimeString().slice(0, 11),
  });

  console.log(searchTerm);
  const getTransactionData = async () => {
    try {
      const response = await getRequest(
        `/lab/transaction/business/${business_id_name}?search=${query}`,
        true
      );
      const data = await response.json();
      if (response.status === 200) {
        let transaction_data = [];
        data.map((item) =>
          transaction_data.push({
            ...item,
            "expanded_data": [],
            // eslint-disable-next-line no-useless-computed-key
            ["expand"]: false,
          })
        );
        setTransactionData(transaction_data.reverse());
        setTransactionDataFilter(transaction_data);
      }
      console.log(data);
    } catch (err) {
      console.log(err);
    }
  };

  const collapseBody = async (
    id,
    index,
    discountMain,
    paid_amountMain,
    doctorName,
    degree
  ) => {
    try {
      setReferral(doctorName);
      setDegree(degree);
      setDiscount(discountMain);
      setTotalPrice(paid_amountMain);
      if (transactiondata[index]["expand"]) {
        transactiondata[index]["expand"] = false;
        setTransactionData(transactiondata);
        setExpanded(!expanded);
        return;
      } else {
        transactiondata[index]["expand"] = true;
        setTransactionData(transactiondata);
        setExpanded(!expanded);
      }
      const response = await getRequest(`/lab/billing/transaction/${id}`);
      const data = await response.json();
      if (response.status === 200) {
        setPatientTrans(data);

        setExpanded(!expanded);
        transactiondata[index]["expanded_data"] = data;

        setTransactionData(transactiondata);

        console.log("PATIENT TRANSACTION", data);
        if (data.length >= 0) {
          setSingleData(data[0]);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  const getBusiness = async () => {
    try {
      const response = await getRequest(`/business`);
      const data = await response.json();
      if (response.status === 200) {
        setBusinessData(data);
        console.log("business", data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const totalAmount = transactiondata.map((data) => parseInt(data.paid_amount));
  console.log("TOTAL", totalAmount);

  const total = totalAmount.reduce((acc, item) => {
    return acc + item;
  }, 0);

  //filter by business
  const dueAmountFunc = (id) => {
    console.log("dueee", id);
    setDueId(id);
    setOpen(true);
  };

  const refundAmountFunc = (id) => {
    console.log("refund", id);
    setDueId(id);
    setOpenR(true);
  };

  const filterClinic = (id, value) => {
    console.log("filter", id, value);
    const filterData = [...transactionDataFilter];
    const filterBusiness = filterData.filter((item, index) => {
      return item.business_name === value;
    });

    setTransactionData(filterBusiness);
  };
  const changePage = (id, name) => {
    router.push(`/lab/dues-history/${name}/${id}`);
  };
  useEffect(() => {
    getTransactionData();
    getBusiness();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);
  return (
    <div>
      <DueAmountModal
        open={open}
        close={setOpen}
        dueId={dueId}
        getTransactionData={getTransactionData}
      />
      <RefundAmountModal
        open={opens}
        close={setOpenR}
        dueId={dueId}
        getTransactionData={getTransactionData}
      />
      <Header />
      <div className="inner_page box">
        <div class="container">
          <h2 className="text-center py-2">
            <GrTransaction style={{ marginRight: "4px" }} />
            Lab Transactions
          </h2>
          <div className="row">
            <div className="col-sm-6 input-group input-group-md input-group-navbar py-3">
              <input
                type="date"
                data-date-format="dd-mm-yyyy"
                className="form-control calendar "
                name="dob"
                id="dov"
                onChange={(event) => setQuery(event.target.value)}
                value={query}
                placeholder=""
              />

              <div className="pl-2">
                {" "}
                <Button variant="contained" color="primary">
                  search by date
                </Button>
              </div>
            </div>

            <div className="col-sm-4 input-group input-group-md input-group-navbar py-3 ml-auto">
              <input
                className="form-control"
                id="searchInput"
                type="search"
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                }}
                placeholder=" Search by patient"
              />

              <button className="btn" type="button">
                <i className="fas fa-search" style={{ fontSize: "14px" }} />
              </button>
            </div>
          </div>
          {/* <AddTransaction /> */}

          <br />
          {transactiondata.length > 0 ? (
            <>
              <div
                class="table"
                style={{ fontSize: "15px", overflowX: "scroll" }}
              >
                <table class="table">
                  <thead>
                    <tr className="table-bordered">
                      <th scope="col">S. No</th>
                      <th scope="col">ID</th>
                      <th scope="col">Date</th>
                      <th scope="col">Time</th>
                      <th scope="col">Patient Name</th>
                      <th scope="col">Clinic</th>
                      <th scope="col">Referred By</th>

                      <th scope="col">Total Amount</th>
                      <th scope="col">Discount</th>

                      <th scope="col">Paid Amount</th>
                      <th scope="col">Due Amount</th>

                      <th scope="col">User</th>

                      {/* <th scope="col">Actions</th> */}
                    </tr>
                  </thead>
                  {transactiondata.length > 0 ? (
                    <>
                      <tbody>
                        {transactiondata
                          // eslint-disable-next-line array-callback-return
                          .filter((data) => {
                            if (searchTerm === "") {
                              return data;
                            } else if (
                              Object.values(data)
                                .join("")
                                .toLowerCase()
                                .includes(searchTerm.toLowerCase())
                            ) {
                              return data;
                            }
                          })
                          .map((data, index) => (
                            <>
                              {
                                <tr
                                  style={{
                                    backgroundColor: " #f7f7f7",
                                    color: "black",
                                  }}
                                  key={index}

                                //className="tables-css"
                                >
                                  <td>{index + 1} </td>{" "}
                                  <td style={{ cursor: "pointer" }}>
                                    {data.id}{" "}
                                    <Button
                                      style={{ fontSize: '10px' }}
                                      variant="contained"
                                      color="primary"
                                      onClick={() =>
                                        collapseBody(
                                          data.id,
                                          index,
                                          data.discount,
                                          data.paid_amount,
                                          data.doctor_name,
                                          data.degree
                                        )
                                      }
                                    >Bill</Button>
                                  </td>{" "}
                                  <td>
                                    {new Date(data.transaction_date)
                                      .toISOString()
                                      .slice(0, 10)}
                                  </td>
                                  <td>
                                    {" "}
                                    {new Date(
                                      data.transaction_date
                                    ).toLocaleTimeString()}
                                  </td>
                                  <td>{data.patient_name} </td>{" "}
                                  <td>{data.business_name} </td>
                                  <td>
                                    {data.doctor_name}
                                    <br />
                                    {data.degree}
                                  </td>
                                  <td>{data.total_amount}</td>
                                  <td>{data.discount}</td>
                                  <td>
                                    {data.paid_amount}
                                    <div>
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        style={{ fontSize: "10px" }}
                                        onClick={() =>
                                          refundAmountFunc(data.id)
                                        }
                                      >
                                        Refund
                                      </Button>
                                    </div>
                                  </td>
                                  <td style={{ cursor: "pointer" }}>
                                    {data.due_amount}
                                    <Button
                                      variant="contained"
                                      color="default"
                                      style={{ fontSize: "12px" }}
                                      onClick={() =>
                                        changePage(data.id, data.patient_name)
                                      }
                                    >
                                      View
                                    </Button>
                                    {data.due_amount > 0 && (
                                      <div>
                                        <Button
                                          variant="contained"
                                          color="primary"
                                          style={{ fontSize: "10px" }}
                                          onClick={() => dueAmountFunc(data.id)}
                                        >
                                          Pay
                                        </Button>
                                      </div>
                                    )}
                                  </td>
                                  <td>{data.user_name} </td>
                                  {/* </Link> */}
                                </tr>
                              }
                              <tr>
                                <td colSpan={8} style={{ padding: "0%" }}>
                                  {data.expand && (
                                    <>
                                      <div>
                                        <div class="table-responsive">
                                          {data.expand &&
                                            data.expanded_data.length === 0 ? (
                                            <>
                                              <PatientEntrySkeleton />
                                            </>
                                          ) : (
                                            <>
                                              <table class="table">
                                                <thead>
                                                  <tr className="table-bordered">
                                                    {/* <th scope="col">Transaction Type</th> */}
                                                    <th scope="col">
                                                      Test Name
                                                    </th>
                                                    <th scope="col">Price</th>

                                                    <th scope="col">
                                                      {" "}
                                                      <PDFDownloadLink
                                                        document={
                                                          <ReactToPdf
                                                            patientTrans={
                                                              patientTrans
                                                            }
                                                            discountMain={
                                                              discountMain
                                                            }
                                                            totalPrice={
                                                              totalPrice
                                                            }
                                                            degreeReferral={
                                                              degreeReferral
                                                            }
                                                            total={total}
                                                            transactiondata={
                                                              transactiondata
                                                            }
                                                            referral={referral}
                                                            reportingDate={
                                                              reportingDate
                                                            }
                                                            singleData={
                                                              singleData
                                                            }
                                                          />
                                                        }
                                                        fileName={
                                                          singleData.patient_name
                                                        }
                                                      >
                                                        {({ loading }) =>
                                                          loading ? (
                                                            <Button
                                                              variant="outlined"
                                                              color="secondary"
                                                            >
                                                              Pdf...
                                                            </Button>
                                                          ) : (
                                                            <Button
                                                              variant="outlined"
                                                              color="primary"
                                                            >
                                                              Billing Pdf
                                                            </Button>
                                                          )
                                                        }
                                                      </PDFDownloadLink>
                                                    </th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {data.expanded_data.map(
                                                    (data, index) => (
                                                      <tr key={index}>
                                                        {/* <td>{data.transaction_type} </td> */}
                                                        <td>
                                                          {data.test_name}
                                                        </td>

                                                        <td>{data.price}</td>
                                                      </tr>
                                                    )
                                                  )}
                                                </tbody>
                                                {/* <div style={{ position: "absolute", right: "7%" }}>
                <h5 className="text-muted">Total Amount: {total}/-</h5>
              </div> */}
                                              </table>
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </td>
                              </tr>
                            </>
                          ))}
                      </tbody>
                    </>
                  ) : (
                    <>
                      <div className="ml-sm-5">
                        <div className="ml-sm-5">
                          {/* <FaNetworkWired
               className=""
               style={{ fontSize: "120px", opacity: "0.4" }}
             /> */}
                          <img
                            style={{
                              width: "30%",
                              opacity: "0.8",
                              marginLeft: "30%",
                            }}
                            className="img-fluid mb-4 mt-4 mr-5"
                            src="https://i.ibb.co/197k5Gb/inbox.png"
                            alt="dae"
                          />
                          <p
                            className="text-muted text-center"
                            style={{ marginLeft: "-8%" }}
                          >
                            No Data !
                          </p>
                        </div>
                      </div>
                    </>
                  )}

                  <div style={{ position: "absolute", right: "5%" }}>
                    <h5 className="text-muted mt-5">Total Amount: {total}/-</h5>
                  </div>
                </table>

                <div></div>
              </div>
            </>
          ) : (
            <>
              <div className="ml-sm-5">
                <div className="ml-sm-5">
                  {/* <FaNetworkWired
               className=""
               style={{ fontSize: "120px", opacity: "0.4" }}
             /> */}
                  <img
                    style={{
                      width: "30%",
                      opacity: "0.8",
                      marginLeft: "30%",
                    }}
                    className="img-fluid mb-4 mt-4 mr-5"
                    src="https://i.ibb.co/197k5Gb/inbox.png"
                    alt="dae"
                  />
                  <p
                    className="text-muted text-center"
                    style={{ marginLeft: "-8%" }}
                  >
                    No transactions yet !
                  </p>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default LabTransactionByBusiness;
