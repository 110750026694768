import React, { useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const Index = ({ open, close, newExistData }) => {
  //   const [closemodal, setCloseModal] = useState();
  // console.log("dataof__already", newExistData);
  const router = useHistory();
  // eslint-disable-next-line no-unused-vars
  const [closemodal, setCloseModal] = useState(true);

  const sendData = (id, name) => {
    console.log("values", id, name);
    router.push(`/lab-details/${name}/${id}`);
  };
  const continueFunc = () => {
    setCloseModal(close(false));
  };
  return (
    <div>
      <Modal open={open} onClose={() => close(false)} center>
        <div className="containers">
          <h5 className="col-sm-12 text-dark">
            Patient(s) with same Phone number
          </h5>
          <hr class="hr-secondary" />

          <div
            style={{ padding: "1rem 1rem", background: "transparent" }}
            className="row px-lg-5"
          >
            <p class="text-center text-dark">
              There is already patient(s) on HealthX platform with the same
              details. If the patient you are registering is the same patient
              then just click on Add Patient
            </p>
            <table className="table">
              <thead className="bg-secondary">
                <tr className="table-bordered " style={{ fontSize: "13px" }}>
                  <th scope="col">ID</th>

                  <th scope="col">Name</th>
                  <th scope="col">Mobile</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {newExistData.map((data, index) => {
                  return (
                    <>
                      <tr key={index}>
                        <td>{data.id}</td>
                        <td>{data.name}</td>
                        <td>{data.mobile}</td>
                        <td>
                          <Button
                            variant="outlined"
                            color="default"
                            size="small"
                            onClick={() => sendData(data.id, data.name)}
                          >
                            continue
                          </Button>
                        </td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
          </div>
          <p className="text-center">
            If the patient you are registering is different from the above
            patient(s),
          </p>
          <hr />
          <div class="float-right">
            <Button
              variant="outlined"
              color="default"
              size="small"
              onClick={() => continueFunc()}
            >
              continue with new patient
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Index;
