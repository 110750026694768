import React, { useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { postRequest } from "../Constants/ApiCall";
import { Button } from "@material-ui/core";
import { Oval } from "react-loader-spinner";

const Index = ({ open, close, getTransactionData, dueId }) => {
  const [formError, setFormError] = useState({});
  const [loading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [closemodal, setCloseModal] = useState();

  const [userPatient, setUserPatient] = useState({
    amount: "",
  });

  const Validation = () => {
    setFormError({});
    var formErrorData = {};
    var formValidated = true;

    if (!userPatient.amount) {
      formErrorData["amountError"] = "Amount is required";
      formValidated = false;
    }

    setFormError(formErrorData);
    return formValidated;
  };
  // // let history = useHistory();
  const addNewEntry = async (e) => {
    e.preventDefault();

    try {
      let data = {
        amount: userPatient.amount,
      };
      if (Validation()) {
        const response = await postRequest(
          `/lab/refund/${dueId}`,
          JSON.stringify(data),
          "PUT",
          true
        );
        // console.log("dda", data);
        var responseData = await response.json();
        console.log("response", responseData);
        if (response.status === 201) {
          alert("Updated Successfully");
          setUserPatient({ amount: "" });
          setCloseModal(close(false));
          getTransactionData();
          //console.log("report-done");
        } else {
          alert(responseData.error);
          setUserPatient({ amount: "" });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <Modal open={open} onClose={() => close(false)} center>
        <div className="container">
          <form className="pt-3" onSubmit={(e) => addNewEntry(e)}>
            <h2 className="col-sm-12">Pay Refund Amount</h2>
            <hr class="hr-primary" />
            <div
              style={{ padding: "2rem 1rem", background: "transparent" }}
              className="row jumbotron px-lg-5"
            >
              <div className="col-sm-12 form-group">
                <label htmlFor="pass2">Amount:</label>
                <input
                  onChange={(e) =>
                    setUserPatient({ ...userPatient, amount: e.target.value })
                  }
                  value={userPatient.amount}
                  className="form-control calendar"
                  placeholder=""
                />
                <span className="small text-danger opacity-4">
                  {formError.amountError}
                </span>
              </div>

              <div className="col-sm-12 form-group mb-0">
                <Button
                  type="submit"
                  className="float-right mt-2 "
                  color="primary"
                  variant="outlined"
                >
                  {loading ? (
                    <Oval color="blue" height={35} width={85} />
                  ) : (
                    <>
                      
                      Get Payment
                    </>
                  )}
                </Button>
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default Index;
