import React from "react";
import { View, StyleSheet } from "@react-pdf/renderer";
import InvoiceTableHeader from "./InvoiceTableHeader";
import InvoiceTableRow from "./InvoiceTableRow";
import InvoiceTableBlankSpace from "./InvoiceTableBlankSpace";
import InvoiceTableFooter from "./InvoiceTableFooter";

// const tableRowsCount = 8;

const styles = StyleSheet.create({
  // tableContainer: {
  //   flexDirection: "row",
  //   flexWrap: "wrap",
  //   marginTop: 20,
  //   borderWidth: 1,
  //   borderColor: "#22598e",
  // },
});

const InvoiceItemsTable = ({ patientTrans, totalPrice, discountMain }) => (
  <View style={styles.tableContainer}>
    <InvoiceTableHeader />
    <InvoiceTableRow patientTrans={patientTrans} />
    <InvoiceTableBlankSpace rowsCount={1} />
    <InvoiceTableFooter
      patientTrans={patientTrans}
      totalPrice={totalPrice}
      discountMain={discountMain}
    />
  </View>
);

export default InvoiceItemsTable;
