import React, { useState, useEffect } from "react";
import Header from "../Components/Header/Header";
import { getRequest } from "../Components/Constants/ApiCall";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import PatientEntrySkeleton from "../Components/SkeletonComponents/PatientEntrySkeleton";

const PatientEntry = (props) => {
  const [patientdata, setPatientData] = useState([]);
  const [showskeleton, setShowSkeleton] = useState(true);
  // eslint-disable-next-line no-unused-vars
  // const [current] = useState(window.location.pathname);
  const [searchTerm, setSearchTerm] = useState("");

  // const getUserData = async () => {
  //   try {
  //     const response = await getRequest("/user_access", true);
  //     const data = await response.json();
  //     if (response.status === 200) {
  //       const labData = data.filter((item) => item.access_type === "lab");
  //       if (labData[0]) {
  //         const role = labData[0].access_role.role.join(",").toLowerCase();
  //         console.log("roles", role);
  //         localStorage.setItem("roles", role);
  //         setUserData(role);
  //       }
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };
  const getPatientData = async () => {
    try {
      const response = await getRequest(`/patient`);
      const data = await response.json();
      console.log("res", response.status, data);
      setShowSkeleton(false);
      if (response.status === 200 || response.status === 201) {
        setPatientData(data.reverse());
      } else {
        setPatientData([]);
      }
    } catch (err) {
      console.log(err);
    }
  };
 

  useEffect(() => {
    getPatientData();
    // getUserData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Header getPatientData={getPatientData} />

      <div className="inner_page box">
        <div class="container">
          <h3 className="text-center py-2">Patients Master List</h3>
          <div class="d-flex justify-content-between">
            {/* <div>
              <h5 className="p-2 text-primary text-center">
                <BsFillPeopleFill />
                Patients Master List
              </h5>
            </div> */}
          </div>
          <div className="col-sm-4 input-group input-group-md input-group-navbar py-3 ml-auto">
            <input
              className="form-control"
              id="searchInput"
              type="search"
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
              placeholder=" Search by patient"
            />

            <button className="btn" type="button">
              <i className="fas fa-search" style={{ fontSize: "14px" }} />
            </button>
          </div>
          <br />
          <div class="table-responsive">
            {showskeleton ? (
              <>
                <PatientEntrySkeleton />
              </>
            ) : (
              <>
                <table class="table">
                  <thead>
                    <tr className="table-bordered">
                      <th scope="col">ID</th>
                      <th scope="col">Patient Name</th>
                      <th scope="col">City</th>
                      <th scope="col">Mobile </th>

                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {patientdata
                      // eslint-disable-next-line array-callback-return
                      .filter((data) => {
                        if (searchTerm === "") {
                          return data;
                        } else if (
                          Object.values(data)
                            .join("")
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase())
                        ) {
                          return data;
                        }
                      })
                      .map((data, index) => (
                        <tr key={index}>
                          <td>HX{data.id} </td>

                          <td style={{ textTransform: "capitalize" }}>
                            {data.name.toLowerCase()} ({data.age} /{" "}
                            {data.gender.slice(0, 1)})
                          </td>

                          <td style={{ textTransform: "capitalize" }}>
                            {data.city.toLowerCase()}
                          </td>
                          <td>{data.mobile}</td>
                          <td>
                            {" "}
                            <Link to={`/lab-details/${data.name}/${data.id}`}>
                              <Button
                                variant="contained"
                                color="primary"
                                style={{ fontSize: "12px" }}
                              >
                                Add lab
                              </Button>
                            </Link>
                          </td>

                          {/* <td>
                          <Link to={`/billing/${data.name}/${data.id}`}>
                            <Button
                              variant="outlined"
                              color="default"
                              style={{ fontSize: "10px" }}
                            >
                              create bill
                            </Button>
                          </Link>
                        </td>
                        <td>
                          <Link to={`/payment-details/${data.id}`}>
                            <Button
                              variant="outlined"
                              color="default"
                              style={{ fontSize: "10px" }}
                            >
                              show bill
                            </Button>
                          </Link>
                        </td> */}
                        </tr>
                      ))}
                  </tbody>
                </table>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientEntry;
