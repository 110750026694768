import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export default function ServiceSkeleton() {
  const skeletonNumber = [{ card: "1" }];
  return (
    <div>
      <div style={{ marginTop: '45%' }}>
        <div>
          {skeletonNumber.map((id) => (

            <ul key={id}>
              <li className="px-2 py-2">
                <Skeleton width="215px" height="30px" />
              </li>
              <li className="px-2 py-2">
                <Skeleton width="215px" height="30px" />
              </li>

              <li className="px-2 py-2">
                <Skeleton width="215px" height="30px" />
              </li>

              <li className="px-2 py-2">
                <Skeleton width="215px" height="30px" />
              </li><li className="px-2 py-2">
                <Skeleton width="215px" height="30px" />
              </li>
            </ul>




            // <tr key={id}>
            //   <td>
            //     <Skeleton width="100px" height="35px" />{" "}
            //   </td>
            //   <td>
            //     {" "}
            //     <Skeleton width="140px" height="35px" />{" "}
            //   </td>
            //   <td>
            //     {" "}
            //     <Skeleton width="170px" height="35px" />{" "}
            //   </td>
            //   <td>
            //     {" "}
            //     <Skeleton width="140px" height="35px" />{" "}
            //   </td>
            //   <td>
            //     {" "}
            //     <Skeleton width="280px" height="35px" />{" "}
            //   </td>
            // </tr>
          ))}
        </div>
      </div>
    </div>
  );
}
