import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import LabDetails from "./Pages/LabDetailsNew";
import Login from "./Pages/Login/Login";
import ProtectedRoute from "./Components/ProtectedRoute/ProtectedRoute";
import ReportPage from "./Components/LabReportModal/Index";
import PatientEntry from "./Pages/PatientEntry";
import LabSection from "./Pages/LabSection";
//import UserAccess from "./Pages/UserAccess";
//import PaymentDetails from "./Pages/PaymentDetails";
// import LabPayment from "./Pages/LabPayment";
import LabTransactionHistory from "./Pages/LabTransactionHistory";
import LabDuesTransactions from "./Pages/LabDuesTransactions";
import LabHistory from "./Pages/LabHistory";
import RoutineList from "./Pages/RoutineList";
import LabPatients from "./Pages/LabPatients";
import TestList from "./Pages/TestList";
import ReportTransactionWise from "./Pages/ReportTransactionWise";
import LabPatientTransaction from "./Pages/LabPatientTransaction";
import DueHistory from "./Pages/DueHistory";
import LabTransactionByBusiness from "./Pages/LabTransactionByBusiness";
import PatientByBusiness from "./Pages/PatientByEntry";
import LabTransactionByUser from "./Pages/LabTransactionByUser";
import LabPendingApproved from "./Pages/LabPendingApproved";
import ManageAccount from "./Pages/ManageAccount";

function App() {
  return (
    <Router>
      <Switch>
        <ProtectedRoute exact path="/home" component={PatientByBusiness} />
        <ProtectedRoute exact path="/lab" component={LabSection} />
        <ProtectedRoute
          exact
          path="/lab/pending-approved/labreports"
          component={LabPendingApproved}
        />
        <ProtectedRoute
          exact
          path="/report/:id"
          component={ReportTransactionWise}
        />
        <ProtectedRoute
          exact
          path="/view/lab-transactions"
          component={LabTransactionByBusiness}
        />
        <ProtectedRoute
          exact
          path="/view/dues-transactions"
          component={LabDuesTransactions}
        />
        <ProtectedRoute exact path="/lab/view/test-list" component={TestList} />

        <ProtectedRoute exact path="/lab/routine/:id" component={RoutineList} />
        <ProtectedRoute
          exact
          path="/lab/transactions/entry-wise"
          component={LabTransactionByUser}
        />
        {/* <ProtectedRoute
          exact
          path="/lab/payment-details/:id"
          component={LabPayment}
        /> */}
        <Route exact path="/login" component={Login} />
        <ProtectedRoute exact path="/patient-entry" component={PatientEntry} />
        <ProtectedRoute
          exact
          path="/lab/view/labreports"
          component={LabPatients}
        />
        <ProtectedRoute
          exact
          path="/lab/transactions/:patient_name/:id"
          component={LabPatientTransaction}
        />
        <ProtectedRoute
          exact
          path="/labreports/:test_name/:id"
          component={ReportPage}
        />
        <ProtectedRoute
          exact
          path="/lab/patients/entry-wise"
          component={PatientByBusiness}
        />
        <ProtectedRoute
          exact
          path="/lab/dues-history/:name/:id"
          component={DueHistory}
        />
        <ProtectedRoute
          exact
          path="/settings/manage-accounts"
          component={ManageAccount}
        />
        <ProtectedRoute exact path="/" component={PatientByBusiness} />
        {/* <ProtectedRoute exact path="/user-access" component={UserAccess} /> */}
        {/* <ProtectedRoute path="/labreports" component={Lab} /> */}
        <ProtectedRoute exact path="/lab/transactions" component={LabHistory} />
        <ProtectedRoute
          exact
          path="/lab/transactions-history"
          component={LabTransactionHistory}
        />

        <ProtectedRoute
          exact
          path="/lab-details/:name/:id"
          component={LabDetails}
        />
        <ProtectedRoute exact path="*" component={PatientByBusiness} />
      </Switch>
    </Router>
    // <Router>
    //   <Routes>
    //     <Route path="/" element={<Index />} />
    //     <Route path="/home" element={<Index />} />
    //     <Route path="/our-doctors" element={<OurDoctors />} />
    //     <Route path="/login" element={<Login />} />
    //     <Route path="/bill-generate" element={<BillGenerate />} />
    //     <Route path="/settings" element={<Settings />} />
    //     <Route path="/opd-entries" element={<OpdPatientsList />} />
    //     <Route path="/labreports" element={<Lab />} />
    //     <Route path="/lab-details" element={<LabSection />} />
    //     <Route path="/opd-details" element={<OpdSection />} />
    //     <Route path="/opd" element={<OPD />} />
    //     <Route path="*" element={<Navigate to="/" />} />
    //   </Routes>
    // </Router>
  );
}

export default App;
