import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

// const borderColor = "#90e5fc";
const styles = StyleSheet.create({
  parent: {
    marginTop: 15,
    borderBottomColor: "black",
    borderTopColor: "black",
    borderTopWidth: 1,
    backgroundColor: "white",
    borderBottomWidth: 1,
    marginBottom: 10,
    padding: 10,
  },
  container: {
    flexDirection: "row",
    height: 15,
    // marginTop: 25,
    // borderBottomColor: "black",
    // borderTopColor: "black",
    // borderTopWidth: 2,
    // backgroundColor: "white",
    // borderBottomWidth: 2,
    // marginBottom: 30,
    // padding: 20,
    textAlign: "center",
    fontStyle: "bold",
    flexGrow: 1,
  },

  data: {
    width: "45%",
    textTransform: "capitalize",
    color: "black",
    fontSize: 10,
    textAlign: "left",
  },
  dataR: {
    width: "45%",
    textTransform: "capitalize",
    color: "black",
    fontSize: 10,
    marginLeft: 55,
    textAlign: "left",
  },
  dataq: {
    width: "55%",
    //textTransform: "capitalize",
    color: "black",
    fontSize: 10,
    textAlign: "right",
  },
});
const InvoiceBillName = ({
  patient_name,
  patientTrans,
  total,
  degreeReferral,
  singleData,
  referral,
  reportingDate,
}) => (
  <View style={styles.parent}>
    <View style={styles.container}>
      <Text style={styles.data}>
        <b>Bill ID : HX00{singleData.transaction}</b>
      </Text>
      <Text style={styles.dataq}>
        <Text style={styles.dataq}>Reg. No: HX0{singleData.patient}</Text>
      </Text>
    </View>
    <View style={styles.container}>
      <Text style={styles.data}>
        Patient Name :{" "}
        <strong>
          {singleData.patient_name} ({singleData.age} /{" "}
          {singleData.gender.slice(0, 1)})
        </strong>
      </Text>

      <Text style={styles.dataq}>Billing Date : {reportingDate.todayDate}</Text>
      {/* <Text style={styles.dataq}> {new Date(singleData.billing_date).toDateString()}</Text> */}
    </View>
    <View style={styles.container}>
      <Text style={styles.data}>
        Referred By: {referral} 
      </Text>
      <Text style={styles.dataq}>Billing Time: {reportingDate.todayTime}</Text>
    </View>
    <View style={styles.container}>
      <Text style={styles.dataR}>{degreeReferral}</Text>
      <Text style={styles.dataq}> </Text>
    </View>
    {/* <View style={styles.container}>
     
     
    </View> */}
  </View>
);

export default InvoiceBillName;
