import React, { useState, useEffect } from "react";
import Header from "../Components/Header/Header";
import { getRequest } from "../Components/Constants/ApiCall";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import { BsFillPeopleFill } from "react-icons/bs";
import PatientEntrySkeleton from "../Components/SkeletonComponents/PatientEntrySkeleton";

const PatientByEntry = (props) => {
  const [patientData, setpatientData] = useState([]);
  const [query, setQuery] = useState(new Date().toISOString().slice(0, 10));
  const [showskeleton, setShowSkeleton] = useState(true);
  const [userData, setUserData] = useState("");

  const getUserData = async () => {
    try {
      const response = await getRequest("/user_access", true);
      const data = await response.json();
      if (response.status === 200) {
        const labData = data.filter((item) => item.access_type === "lab");
        if (labData[0]) {
          const role = labData[0].access_role.role.join(",").toLowerCase();
          console.log("roles", role);
          localStorage.setItem("roles", role);
          setUserData(role);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  console.log("patientEntryPage", userData);
  const getPatientByData = async () => {
    try {
      setShowSkeleton(true);

      const response = await getRequest(`/patient/user?search=${query}`, true);
      const data = await response.json();
      setShowSkeleton(false);
      if (response.status === 200 || response.status === 201) {
        setpatientData(data);
        console.log("patient_Data1", data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  console.log("patient_by_user", patientData);
  useEffect(() => {
    getPatientByData();
    getUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Header />

      <div className="inner_page box">
        <div class="container">
          <div class="d-flex justify-content-between">
            <div>
              <h5 className="p-2 text-primary text-center">
                <BsFillPeopleFill />
                Patients
              </h5>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 input-group input-group-md input-group-navbar py-3">
              <input
                type="date"
                data-date-format="dd-mm-yyyy"
                className="form-control calendar "
                name="dob"
                id="dov"
                onChange={(event) => setQuery(event.target.value)}
                value={query}
                placeholder=""
              />

              <div className="pl-2">
                {" "}
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => getPatientByData()}
                >
                  search by date
                </Button>
              </div>
            </div>

            <div className="col-sm-4 input-group input-group-md input-group-navbar py-3 ml-auto">
              <input
                className="form-control"
                id="searchInput"
                type="search"
                // onChange={(e) => {
                //   setSearchTerm(e.target.value);
                // }}
                placeholder=" Search by patient"
              />

              <button className="btn" type="button">
                <i className="fas fa-search" style={{ fontSize: "14px" }} />
              </button>
            </div>
          </div>
          <br />
          <div class="table-responsive">
            {showskeleton ? (
              <>
                <PatientEntrySkeleton />
              </>
            ) : (
              <>
                <table class="table">
                  <thead>
                    <tr className="table-bordered">
                      <th scope="col">ID</th>
                      <th scope="col">Date</th>
                      <th scope="col">Patient Name</th>
                      <th scope="col">City</th>
                      {/* <th scope="col">Mobile No</th> */}

                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  {patientData.length > 0 ? (
                    <tbody>
                      {patientData?.map((data, index) => (
                        <tr key={index}>
                          <td>HX{data.id} </td>
                          <td>{new Date(data.date).toDateString()} </td>
                          <td style={{ textTransform: "capitalize" }}>
                            {data.name.toLowerCase()} ({data.age} /{" "}
                            {data.gender.slice(0, 1)})
                          </td>

                          <td style={{ textTransform: "capitalize" }}>
                            {data.city.toLowerCase()}
                          </td>
                          {/* <td>{data.mobile}</td> */}
                          <td>
                            {" "}
                            <Link to={`/lab-details/${data.name}/${data.id}`}>
                              <Button
                                variant="contained"
                                color="primary"
                                style={{ fontSize: "12px" }}
                              >
                                Add lab
                              </Button>
                            </Link>
                          </td>
                          {/* <td>
                          <Link to={`/billing/${data.name}/${data.id}`}>
                            <Button
                              variant="outlined"
                              color="default"
                              style={{ fontSize: "10px" }}
                            >
                              create bill
                            </Button>
                          </Link>
                        </td>
                        <td>
                          <Link to={`/payment-details/${data.id}`}>
                            <Button
                              variant="outlined"
                              color="default"
                              style={{ fontSize: "10px" }}
                            >
                              show bill
                            </Button>
                          </Link>
                        </td> */}
                        </tr>
                      ))}
                    </tbody>
                  ) : (
                    <>
                      <div
                        className="text-center text-dark d-none d-sm-block"
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                        }}
                      >
                        <h2 className="mt-5">No Data Found !</h2>
                      </div>
                    </>
                  )}
                </table>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientByEntry;
