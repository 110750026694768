import React, { useState, useEffect } from "react";
import Header from "../Components/Header/Header";
import { getRequest } from "../Components/Constants/ApiCall";
import PatientEntrySkeleton from "../Components/SkeletonComponents/PatientEntrySkeleton";
import { Button } from "@material-ui/core";

const LabPendingApproved = () => {
  const [pendingList, setPendingList] = useState([]);
  const [searchQuery, setSearchQuery] = useState(
    new Date().toISOString().slice(0, 10)
  );

  const [showskeleton, setShowSkeleton] = useState(true);

  const getPendingData = async () => {
    try {
      setShowSkeleton(true);

      const response = await getRequest(
        `/lab/billing_unapproved?search=${searchQuery}`
      );
      const data = await response.json();
      if (response.status === 200) {
        setShowSkeleton(false);
        setPendingList(data.reverse());
      } else {
        setPendingList([]);
      }
    } catch (err) {
      console.log(err);
    }
  };
  console.log("pendingApproved", pendingList);

  useEffect(() => {
    getPendingData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <div>
        <Header />
        <div className="inner_page box">
          <div class="container">
            <h2 className="text-center py-2">Pending Approved Bills</h2>
            <div className="row">
              <div className="col-sm-6 input-group input-group-md input-group-navbar ">
                <input
                  type="date"
                  data-date-format="dd-mm-yyyy"
                  className="form-control calendar "
                  name="dob"
                  id="dov"
                  onChange={(e) => setSearchQuery(e.target.value)}
                  value={searchQuery}
                  placeholder=""
                />
                <div className="pl-2">
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => getPendingData()}
                  >
                    search by date
                  </Button>
                </div>
              </div>
            </div>

            <br />
            <div class="table-responsive">
              {showskeleton ? (
                <>
                  <PatientEntrySkeleton />
                </>
              ) : (
                <>
                  {pendingList.length > 0 ? (
                    <div
                      style={{
                        fontSize: "15px",
                        width: "100%",
                        height: "400px",
                        overflowX: "scroll",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <table class="table">
                        <thead>
                          <tr className="table-bordered">
                            <th scope="col">Bill ID</th>
                            <th scope="col">Date & Time</th>

                            <th scope="col">Patient Name</th>
                            <th scope="col">Test Name</th>
                            <th scope="col">Clinic</th>
                            <th>Doctor</th>

                            {/* <th scope="col">Actions</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {pendingList
                            .reverse()
                            // eslint-disable-next-line array-callback-return

                            .map((data, index) => (
                              <tr key={index} className="tables-css">
                                <td>{data.id}</td>
                                <td>
                                  {" "}
                                  {new Date(data.billing_date).toLocaleString()}
                                </td>
                                {/* </Link> */}
                                <td>
                                  {data.patient_name} ({data.age}/
                                  {data.gender.slice(0, 1)})
                                </td>
                                <td>{data.test_name}</td>{" "}
                                <td>{data.business_name}</td>
                                <td>{data.doctor}</td>
                              </tr>
                            ))}
                        </tbody>
                      </table>{" "}
                    </div>
                  ) : (
                    <div className="ml-sm-5">
                      <div className="ml-sm-5">
                        {/* <FaNetworkWired
                           className=""
                           style={{ fontSize: "120px", opacity: "0.4" }}
                         /> */}
                        <img
                          style={{
                            width: "30%",
                            opacity: "0.8",
                            marginLeft: "30%",
                          }}
                          className="img-fluid mb-4 mt-5 mr-5"
                          src="https://i.ibb.co/197k5Gb/inbox.png"
                          alt="dae"
                        />
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LabPendingApproved;
