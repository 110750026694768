import React, { useState, useEffect } from "react";
import Header from "../Components/Header/Header";
import { getRequest } from "../Components/Constants/ApiCall";
// import { Button } from "@material-ui/core";
import { useParams } from "react-router";
import PatientEntrySkeleton from "../Components/SkeletonComponents/PatientEntrySkeleton";

const DueHistory = () => {
  var { id, name } = useParams();
  const [partialData, setPartialData] = useState([]);
  const [showskeleton, setShowSkeleton] = useState(true);
 
  const getpartialData = async () => {
    try {
      const response = await getRequest(`/lab/partial_pay/${id}`);
      const data = await response.json();
      setShowSkeleton(false);
      if (response.status === 200) setPartialData(data);
      console.log("partialAmount", data);
    } catch (err) {
      console.log(err);
    }
  };

  // const changePage = (id, patient_name) => {
  //   router.push(`/transactions/${patient_name}/${id}`);
  // };

  useEffect(() => {
    getpartialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <Header />
      <div className="inner_page box">
        <div class="container">
          <h2 className="text-center py-2">Payment History</h2>

          <br />
          <h5 className="ml-2 text-gray">{name}</h5>
          <div class="table-responsive">
            {showskeleton ? (
              <>
                <PatientEntrySkeleton />
              </>
            ) : (
              <>
                {partialData.length > 0 ? (
                  <>
                    <table class="table">
                      <thead>
                        <tr className="table-bordered">
                          <th scope="col">Date</th>
                          <th scope="col">Time</th>
                          <th scope="col">Amount</th>

                          {/* <th scope="col">Actions</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {partialData
                          // eslint-disable-next-line array-callback-return

                          .map((data, index) => (
                            <tr key={index} className="tables-css">
                              <td> {new Date(data.date).toDateString()}</td>
                              <td>
                                {" "}
                                {new Date(data.date).toLocaleTimeString()}
                              </td>

                              <td>{data.amount}</td>

                              {/* </Link> */}
                            </tr>
                          ))}
                      </tbody>
                    </table>{" "}
                  </>
                ) : (
                  <div className="ml-sm-5">
                    <div className="ml-sm-5">
                      {/* <FaNetworkWired
                           className=""
                           style={{ fontSize: "120px", opacity: "0.4" }}
                         /> */}
                      <img
                        style={{
                          width: "30%",
                          opacity: "0.8",
                          marginLeft: "30%",
                        }}
                        className="img-fluid mb-4 mt-5 mr-5"
                        src="https://i.ibb.co/197k5Gb/inbox.png"
                        alt="dae"
                      />
                      <p
                        className="text-muted text-center"
                        style={{ marginLeft: "-8%" }}
                      >
                        No Pending Dues !
                      </p>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DueHistory;
