import React from "react";

import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
// import doc from "../../src/docs.png";
import InvoiceItemsTable from "../Components/ReportReactToPdfComponents/InvoiceItemsTable";
import InvoiceBillName from "../Components/ReportReactToPdfComponents/InvoiceBillName";
import InvoiceTableFooter from "../Components/ReportReactToPdfComponents/InvoiceTableFooter";
// const borderColor = "#22598e";
const styles = StyleSheet.create({
  body: {
    paddingTop: 30,
    paddingBottom: 250,
    paddingHorizontal: 25,
  },
  footerContainer: {
    //marginTop:50,
    position: "absolute",
    bottom: 130,
    right: "5%",
  },
  rows: {
    marginTop: "11%",
    fontFamily: "Ubuntu",
    fontWeight: "extrabold",
  },

  title: {
    fontSize: 22,
    textAlign: "center",
  },
  text: {
    margin: 10,
    fontSize: 14,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  image: {
    position: "absolute",
    bottom: "13%",
    right: "3%",
    width: "90px",
  },
  footers: {
    marginTop: "10%",
  },
  header: {
    fontSize: 12,
    marginBottom: 10,
    textAlign: "center",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 10,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },

  titleContainer: {
    flexDirection: "row",
    marginTop: 10,
  },
  address: {
    color: "black",
    marginTop: "1",
    marginBottom: "2",
    fontSize: 10,
    textAlign: "center",
  },
  phones: {
    color: "black",
    marginBottom: "12",
    fontSize: 10,
    textAlign: "center",
  },
  reportTitle: {
    color: "#22598e",
    letterSpacing: 2,
    fontSize: 20,
    textAlign: "center",
    textTransform: "uppercase",
  },
  invoiceNoContainer: {
    flexDirection: "row",
    marginTop: 20,
    justifyContent: "flex-end",
  },
  invoiceDateContainer: {
    marginTop: 10,
    flexDirection: "row",
    justifyContent: "flex-end",
  },

  signImgContainer: {
    marginVertical: 1,
    marginHorizontal: 120,
    position: "absolute",
    bottom: "14%",
    zIndex: 41,
    right: "13%",
  },
  label: {
    fontSize: 12,
  },
  headerContainer: {
    marginTop: 25,
  },
  billTo: {
    marginTop: 20,
    paddingBottom: 3,
    // fontFamily: "Helvetica-Oblique",
  },

  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 10,
    borderWidth: 1,
    borderColor: "#22598e",
  },
});

const ReactToPdf = ({ reportData, singleDataList, date }) => {
  console.log("a", reportData);
  return (
    <Document>
      <Page style={styles.body} size="A4" wrap>
        <Text style={styles.rows} fixed></Text>
        <View fixed>
          <InvoiceBillName singleDataList={singleDataList} date={date} />
        </View>

        <View style={styles.tableContainer}>
          <InvoiceItemsTable reportData={reportData} />
        </View>

        <View style={styles.footerContainer} fixed>
          <InvoiceTableFooter />
        </View>

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

export default ReactToPdf;
