import React, { useState, useEffect } from "react";
import Header from "../Components/Header/Header";
import { getRequest } from "../Components/Constants/ApiCall";
import { Button } from "@material-ui/core";
import { GrTransaction } from "react-icons/gr";
import { Link } from "react-router-dom";
import PatientEntrySkeleton from "../Components/SkeletonComponents/PatientEntrySkeleton";

const LabPatients = (props) => {
  const [transactiondata, setTransactionData] = useState([]);
  const [query, setQuery] = useState(new Date().toISOString().slice(0, 10));
  const [searchTerm, setSearchTerm] = useState("");
  const [expanded, setExpanded] = useState(false);
  // const [modaldata, setModalData] = useState([]);
  // const [open, setOpen1] = useState(false);
  const [showskeleton, setShowSkeleton] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [patientTrans, setPatientTrans] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [singleData, setSingleData] = useState({
    patient_name: "",
    gender: "",
    test_name: "",
    reffred_by: "",
    business: "",
    patient: "",
    test: "",
    billing_type: "",
    billing_stage: "",
    mrp: "",
    transaction: "",
    discount: "",
    final_amount: "",
    discount_description: "",
    user: "",
    quantity: "",
    id: "",
  });
  //const [checked, setChecked] = useState(false);
  // const getdate = (query) => {
  //   console.log("dateformat",query)
  //   let newquery = query.split("-");
  //   console.log("newquery",newquery)
  // }

  console.log(searchTerm);
  const getTransactionData = async () => {
    try {
      setShowSkeleton(true);
      const response = await getRequest(`/lab/transaction?search=${query}`);
      const data = await response.json();
      if (response.status === 200) {
        setShowSkeleton(false);

        let transaction_data = [];
        data.map((item) =>
          transaction_data.push({
            ...item,
            expanded_data: [],
            // eslint-disable-next-line no-useless-computed-key
            ["expand"]: false,
          })
        );
        setTransactionData(transaction_data.reverse());
      }
      console.log(data);
    } catch (err) {
      console.log(err);
    }
  };
  console.log("lab_reporting", transactiondata, query);
  const collapseBody = async (id, index) => {
    try {
      if (transactiondata[index]["expand"]) {
        transactiondata[index]["expand"] = false;
        setTransactionData(transactiondata);
        setExpanded(!expanded);
        return;
      } else {
        transactiondata[index]["expand"] = true;
        setTransactionData(transactiondata);
        setExpanded(!expanded);
      }
      const response = await getRequest(`/lab/billing/transaction/${id}`);
      const data = await response.json();
      if (response.status === 200) {
        setPatientTrans(data);

        // setShowSkeleton(false);
        setExpanded(!expanded);
        transactiondata[index]["expanded_data"] = data;

        setTransactionData(transactiondata);
        console.log("aa", data.expanded_data);
        console.log("PATIENT TRANSACTION", data);
        if (data.length >= 0) {
          setSingleData(data[0]);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  // const reportModal = (id, data) => {
  //   setOpen1(true);
  //   setModalData(data);
  //   console.log("report", id, data.test_name, data);
  // };

  // const handleCheck = async (id, subindex, index) => {
  //   const tData = [...transactiondata];

  //   console.log("id", id);
  //   const body = {
  //     status: "reporting done",
  //   };
  //   const response = await postRequest(
  //     `/lab/billing/${id}`,
  //     JSON.stringify(body),
  //     "PUT",
  //     true
  //   );
  //   console.log("appr", body);

  //   const datas = await response.json();
  //   if (datas.success) {
  //     tData[index]["expanded_data"][subindex]["isapproved"] = true;
  //     setTransactionData(tData);
  //   }
  // };

  useEffect(() => {
    getTransactionData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <Header />

      <div className="inner_page box">
        <div class="container">
          <h2 className="text-center py-2">
            <GrTransaction style={{ marginRight: "4px" }} />
            Lab Transactions
          </h2>
          {/* <AddTransaction /> */}
          <div className="row"></div>
          <div className="row">
            <div className="col-sm-6 input-group input-group-md input-group-navbar py-3">
              <input
                type="date"
                className="form-control calendar "
                value={query}
                placeholder="dd-mm-yyyy"
                onChange={(e) => setQuery(e.target.value)}
              />

              <div className="pl-2">
                {" "}
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => getTransactionData()}
                >
                  search by date
                </Button>
              </div>
            </div>

            <div className="col-sm-4 input-group input-group-md input-group-navbar py-3 ml-auto">
              <input
                className="form-control"
                id="searchInput"
                type="search"
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                }}
                placeholder=" Search by patient"
              />

              <button className="btn" type="button">
                <i className="fas fa-search" style={{ fontSize: "14px" }} />
              </button>
            </div>
          </div>
          <br />
          {transactiondata.length > 0 ? (
            <>
              <div class="table-responsive">
                {showskeleton ? (
                  <>
                    <PatientEntrySkeleton />
                  </>
                ) : (
                  <>
                    <table class="table">
                      <thead>
                        <tr className="table-bordered">
                          <th scope="col">ID</th>

                          <th scope="col">Patient Name</th>
                          <th scope="col">Clinic</th>
                          <th scope="col">Referred By</th>
                          {/*  <th scope="col">Total Amount</th> */}

                          <th scope="col">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {transactiondata
                          // eslint-disable-next-line array-callback-return
                          .filter((data) => {
                            if (searchTerm === "") {
                              return data;
                            } else if (
                              Object.values(data)
                                .join("")
                                .toLowerCase()
                                .includes(searchTerm.toLowerCase())
                            ) {
                              return data;
                            }
                          })
                          .map((item, index) => (
                            <>
                              <tr
                                style={{
                                  backgroundColor:
                                    item.disabled === true
                                      ? "#e6e6f5"
                                      : "transaparent",
                                  textDecoration:
                                    item.disabled === true
                                      ? "line-through"
                                      : "",
                                }}
                                key={index}
                              >
                                <td
                                  style={{
                                    borderLeft:
                                      item.disabled === true
                                        ? "8px solid red"
                                        : "",
                                  }}
                                >
                                  HX{item.id}
                                </td>
                                <td>
                                  {item.patient_name} ({item.age} /{" "}
                                  {item.gender.slice(0, 1)}){" "}
                                </td>{" "}
                                <td>{item.business_name}</td>
                                <td>{item.doctor_name}</td>
                                {/*   */}
                                <td>
                                  <Button
                                    variant="outlined"
                                    style={{ fontSize: "12px" }}
                                    color="primary"
                                    onClick={() => collapseBody(item.id, index)}
                                  >
                                    {item.expand ? "hide" : "show"}
                                  </Button>
                                </td>
                                <td>
                                  <Link to={`/report/${item.id}`}>
                                    <Button
                                      variant="contained"
                                      style={{ fontSize: "12px" }}
                                      color="primary"
                                    >
                                      Get Report
                                    </Button>{" "}
                                  </Link>
                                </td>
                                {/* </Link> */}
                              </tr>
                              <tr>
                                <td colSpan={8} style={{ padding: "0%" }}>
                                  {item.expand && (
                                    <>
                                      <div>
                                        <div class="table-responsive">
                                          {item.expand &&
                                          item.expanded_data.length === 0 ? (
                                            <>
                                              <PatientEntrySkeleton />
                                            </>
                                          ) : (
                                            <>
                                              <table class="table">
                                                <tbody>
                                                  {item.expanded_data.map(
                                                    (data, subindex) => (
                                                      <tr key={subindex}>
                                                        <td
                                                          style={
                                                            data.isapproved ===
                                                            true
                                                              ? {
                                                                  backgroundColor:
                                                                    "white",
                                                                }
                                                              : {
                                                                  backgroundColor:
                                                                    "white",
                                                                }
                                                          }
                                                          class="ml-2"
                                                        >
                                                          {/* <button onClick={()=>alert(JSON.stringify(data))}>
                                                        click</button> */}
                                                          {!data.isapproved ? (
                                                            <div>
                                                              <Link
                                                                to={{
                                                                  pathname: `/labreports/${data.test_name}/${data.id}`,
                                                                  query: {
                                                                    test: JSON.stringify(
                                                                      item.expand
                                                                        ? item.expanded_data
                                                                        : []
                                                                    ),
                                                                  },
                                                                }}
                                                              >
                                                                <Button
                                                                  variant="outlined"
                                                                  color="default"
                                                                >
                                                                  {
                                                                    data.test_name
                                                                  }
                                                                </Button>
                                                              </Link>
                                                            </div>
                                                          ) : (
                                                            <div>
                                                              <Button
                                                                variant="outlined"
                                                                color="primary"
                                                                onClick={() =>
                                                                  alert(
                                                                    "Approved test can't be edit"
                                                                  )
                                                                }
                                                              >
                                                                {data.test_name}
                                                              </Button>
                                                            </div>
                                                          )}
                                                        </td>

                                                        <td>
                                                          <div class="fdataorm-check">
                                                            <input
                                                              class="form-check-input"
                                                              type="checkbox"
                                                              // onClick={() =>
                                                              //   handleCheck(
                                                              //     data.id,
                                                              //     subindex,
                                                              //     index
                                                              //   )
                                                              // }
                                                              checked={
                                                                data.isapproved
                                                              }
                                                              id="defaultCheck1"
                                                            />
                                                            <label
                                                              class="form-check-label"
                                                              for="defaultCheck1"
                                                            >
                                                              Approved
                                                            </label>
                                                          </div>
                                                        </td>

                                                        {data.isapproved && (
                                                          <td>
                                                            {new Date(
                                                              data.approved_date
                                                            ).toLocaleString()}
                                                          </td>
                                                        )}
                                                        {data.isapproved && (
                                                          <td>
                                                            {data.user_name}
                                                          </td>
                                                        )}
                                                      </tr>
                                                    )
                                                  )}
                                                </tbody>
                                              </table>
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </td>
                              </tr>
                            </>
                          ))}
                      </tbody>
                    </table>
                  </>
                )}
              </div>
            </>
          ) : (
            <>
              <div className="ml-sm-5">
                <div className="ml-sm-5">
                  {/* <FaNetworkWired
               className=""
               style={{ fontSize: "120px", opacity: "0.4" }}
             /> */}
                  <img
                    style={{
                      width: "30%",
                      opacity: "0.8",
                      marginLeft: "30%",
                      color:'#22598e'
                    }}
                    className="img-fluid mb-4 mt-4 mr-5"
                    src="https://i.ibb.co/197k5Gb/inbox.png"
                    alt="dae"
                  />
                  <p
                    className="text-muted text-center"
                    style={{ marginLeft: "-8%" }}
                  >
                    No transactions yet !
                  </p>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default LabPatients;
