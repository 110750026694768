import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

// const borderColor = "#90e5fc";
const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    borderBottomWidth: 2,
    alignItems: "center",
    height: 25,
    //textAlign: "center",
    fontWeight: 800,
    flexGrow: 1,
  },

  groupName: {
    fontSize: 10,
    textTransform: "capitalize",
    fontWeight: 800,
    width: "50%",
    paddingLeft: 10,
    // borderRightColor: borderColor,
    // borderRightWidth: 1,
  },
  valueUi: {
    fontSize: 10,

    textTransform: "capitalize",
    //textAlign:"center",
    width: "20%",
    fontWeight: 800,

    // borderRightColor: borderColor,
    // borderRightWidth: 1,
  },

  discount: {
    width: "30%",
    textTransform: "capitalize",
    fontWeight: "600",
    fontSize: 10,
    // textAlign:"center",
    paddingLeft: 9,
    fontStyle: "bold",
  },

  // serialNumber: {
  //   textTransform:"capitalize",
  //   width: "10%",
  //   paddingLeft: 9,
  //   fontSize: 9,
  //   fontWeight:700,
  //   color: "white",
  // },
});

const InvoiceTableHeader = () => (
  <View style={styles.container} >
    {/* <Text style={styles.serialNumber}>S</Text> */}
    <Text style={styles.groupName}>
      <b>Test Parameter</b>
    </Text>
    <Text style={styles.valueUi}>
      <b>Values</b>
    </Text>
    {/* <Text style={styles.discount}>Discount</Text> */}

    <Text style={styles.discount}>
      <b>Biological Ref. Interval</b>
    </Text>
  </View>
);

export default InvoiceTableHeader;
