import React from "react";
import Header from "../Components/Header/Header";
import { Button } from "@material-ui/core";
import { GrTransaction } from "react-icons/gr";

const LabSection = () => {
  return (
    <div>
      <Header />
      <div className="inner_page box">
        <div>
          <h2 className="text-center py-2">
            <GrTransaction style={{ marginRight: "4px" }} />
            Lab Transactions
          </h2>
        </div>
        <div className="col-sm-6 input-group input-group-md input-group-navbar py-3">
          <input
            type="date"
            data-date-format="dd-mm-yyyy"
            className="form-control calendar "
            name="dob"
            id="dov"
            //onChange={(event) => setQuery(event.target.value)}
            //value={query}
            placeholder=""
          />

          <div className="pl-2">
            {" "}
            <Button variant="contained" color="primary">
              search by date
            </Button>
          </div>
        </div>
        <div className="container">
          
        </div>
      </div>
    </div>
  );
};

export default LabSection;
