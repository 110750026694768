import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { Font } from "@react-pdf/renderer";
import font from "../../Fonts/Roboto/Roboto-Bold.ttf";
// const borderColor = "#22598e";
Font.register({
  family: "Roboto",
  format: "truetype",
  src: font,
});

Font.register({
  family: "Ubuntu",
  fonts: [
    {
      src: "https://fonts.gstatic.com/s/questrial/v13/QdVUSTchPBm7nuUeVf7EuStkm20oJA.ttf",
    },
    {
      src: "https://fonts.gstatic.com/s/questrial/v13/QdVUSTchPBm7nuUeVf7EuStkm20oJA.ttf",
      fontWeight: "bold",
    },
    {
      src: "https://fonts.gstatic.com/s/questrial/v13/QdVUSTchPBm7nuUeVf7EuStkm20oJA.ttf",
      fontWeight: "normal",
      fontStyle: "italic",
    },
  ],
});
// const borderColor = "#90e5fc";
const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    borderBottomWidth: 1,
    marginBottom: 4,
    borderTopWidth: 2,
    alignItems: "center",
    height: 20,

    flexGrow: 1,
  },

  groupName: {
    fontSize: 9,
    textTransform: "capitalize",
    fontFamily: "Roboto",

    width: "55%",
    paddingLeft: 10,
    // borderRightColor: borderColor,
    // borderRightWidth: 1,
  },
  valueUi: {
    fontSize: 9,
    fontFamily: "Roboto",
    textTransform: "capitalize",
    //textAlign:"center",
    width: "20%",

    // borderRightColor: borderColor,
    // borderRightWidth: 1,
  },

  discount: {
    fontFamily: "Roboto",
    width: "25%",
    textTransform: "capitalize",
    fontSize: 9,

    paddingLeft: 9,
  },

  // serialNumber: {
  //   textTransform:"capitalize",
  //   width: "10%",
  //   paddingLeft: 9,
  //   fontSize: 9,
  //   fontWeight:700,
  //   color: "white",
  // },
});

const InvoiceTableHeader = () => (
  <View style={styles.container} fixed>
    {/* <Text style={styles.serialNumber}>S</Text> */}
    <Text style={styles.groupName}>Test Parameter</Text>
    <Text style={styles.valueUi}>Values</Text>
    {/* <Text style={styles.discount}>Discount</Text> */}

    <Text style={styles.discount}>Biological Ref. Interval</Text>
  </View>
);

export default InvoiceTableHeader;
