import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

// const borderColor = "#22598e";
const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    borderBottomColor: "#22598e",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 25,
    fontStyle: "bold",
    flexGrow: 1,
  },

  groupName_serial: {
    fontSize: 9,
    width: "10%",
    paddingLeft: 9,
  },
  groupName: {
    fontSize: 9,
    width: "70%",
    paddingLeft: 9,
  },
  // mrp: {
  //   fontSize: 9,
  //   //textAlign: "center",
  //   width: "66%",
  //   // borderRightColor: borderColor,
  //   // borderRightWidth: 1,
  // },

  // discount: {
  //   width: "20%",
  // //  textAlign: "center",
  //   paddingLeft: 10,
  //   fontSize: 9,
  //   // borderRightColor: borderColor,
  //   // borderRightWidth: 1,
  // },
  paidAmount: {
    width: "20%",
   // textAlign: "center",
    fontSize: 9,
    paddingLeft: 9,
  },
});

const InvoiceTableRow = ({ patientTrans }) => {
  console.log("pat", patientTrans);
  const rows = patientTrans.map((item, index) => (
    <View style={styles.row} key={index}>
      <Text style={styles.groupName_serial}>{index + 1}</Text>
      <Text style={styles.groupName}>{item.test_name}</Text>
      {/* <Text style={styles.mrp}>{item.mrp}</Text>

      <Text style={styles.discount}>{item.discount}</Text> */}
      <Text style={styles.paidAmount}>{item.price}</Text>
    </View>
  ));
  return <Fragment>{rows}</Fragment>;
};

export default InvoiceTableRow;
