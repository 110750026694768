import React from "react";

import {
  Page,
  Text,
  Image,
  View,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
import logo from "../../src/pathlabs.png";
import InvoiceItemsTable from "../Components/LabReactToPdfComponents/InvoiceItemsTable";
import InvoiceBillName from "../Components/LabReactToPdfComponents/InvoiceBillName";
// const borderColor = "#22598e";
const styles = StyleSheet.create({
  body: {
    paddingTop: 0,
    paddingBottom: 2,
    paddingHorizontal: 5,
  },
  title: {
    fontSize: 22,
    textAlign: "center",
  },
  text: {
    margin: 10,
    fontSize: 14,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  image: {
    marginVertical: 1,
    marginHorizontal: 150,
  },
  header: {
    fontSize: 12,
    marginBottom: 10,
    textAlign: "center",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 10,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },

  titleContainer: {
    flexDirection: "row",
    marginTop: 10,
  },
  address: {
    color: "black",
    marginTop: "1",
    marginBottom: "2",
    fontSize: 10,
    textAlign: "center",
  },
  phones: {
    color: "black",
    marginBottom: "12",
    fontSize: 10,
    textAlign: "center",
  },
  reportTitle: {
    color: "#22598e",
    letterSpacing: 2,
    fontSize: 20,
    textAlign: "center",
    textTransform: "uppercase",
  },
  invoiceNoContainer: {
    flexDirection: "row",
    marginTop: 20,
    justifyContent: "flex-end",
  },
  invoiceDateContainer: {
    marginTop: 10,
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  invoiceDate: {
    fontSize: 12,
    fontStyle: "bold",
  },
  label: {
    fontSize: 12,
  },
  headerContainer: {
    marginTop: 25,
  },
  billTo: {
    marginTop: 20,
    paddingBottom: 3,
    fontFamily: "Helvetica-Oblique",
  },

  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 10,
    borderWidth: 1,
    borderColor: "#22598e",
  },
});

const ReactToPdf = ({
  patientTrans,
  total,
  patient_name,
  singleData,
  reportingDate,
  transactiondata,
  referral,
  discountMain,
  degreeReferral,
  totalPrice,
}) => {
  console.log(
    "av",
    transactiondata.discount,
    transactiondata,
    patientTrans.length,
    singleData.patient,
    singleData,
    transactiondata.discount
  );
  // console.log("a", patient_name);
  return (
    <Document>
      <Page style={styles.body} size="A4">
        <Text style={styles.header} fixed></Text>
        <Image style={styles.image} src={logo}></Image>
        <Text style={styles.address}>
          N-7 Patrakar Nagar, Kankarbagh, Patna - 800004
        </Text>
        <Text style={styles.phones}>
          Contact No: +91 9973024402, +91 8709023077
        </Text>
        {/* <View style={styles.titleContainer}> */}
        <Text style={styles.reportTitle}>Invoice</Text>
        {/* </View> */}
        {/* <Fragment>
          <View style={styles.invoiceNoContainer}>
            <Text style={styles.label}>Invoice No:</Text>
            <Text style={styles.invoiceDate}>Hxssss</Text>
          </View>
          <View style={styles.invoiceDateContainer}>
            <Text style={styles.label}>Date: </Text>
            <Text style={styles.invoiceDate}>18/01/2022</Text>
          </View>
        </Fragment>
        <View style={styles.headerContainer}>
          <Text style={styles.billTo}>Bill To:</Text>
          <Text>Patient Name: {name}</Text>
          <Text>99 73 02 44 02</Text>
          <Text>www.myhealthx.in</Text>
        </View> */}
        <View>
          <InvoiceBillName
            patientTrans={patientTrans}
            patient_name={patient_name}
            referral={referral}
            degreeReferral={degreeReferral}
            singleData={singleData}
            reportingDate={reportingDate}
            total={total}
          />
        </View>

        <View style={styles.tableContainer}>
          <InvoiceItemsTable
            patientTrans={patientTrans}
            discountMain={discountMain}
            totalPrice={totalPrice}
          />
        </View>

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
        />
      </Page>
    </Document>
  );
};

export default ReactToPdf;
