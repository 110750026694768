import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    borderBottomColor: "#ebecf0",
    backgroundColor: "#ebecf0",
    borderBottomWidth: 2,
    alignItems: "center",
    height: 25,
    fontStyle: "bold",
    flexGrow: 1,
    

  },
  date: {
    fontSize: 9,
    textTransform:"capitalize",
    fontWeight:"bold",
    width: "80%",
    color: "black",
    paddingLeft: 9,
    
    
  },
  // totalMrp: {
  //   fontSize: 9,
  //   color: "white",
  //   textTransform:"capitalize",
  //   width: "20%",
  //   paddingLeft: 9,
  //   fontWeight:"bold",
    
  // },
  // totalDiscount: {
  //   width: "20%",
  //   textTransform:"capitalize",
  //   fontWeight:"bold",
  //   fontSize: 9,
  //   paddingLeft: 9,
  //   color: "white",
  // },
  totalAmount: {
    textTransform:"capitalize",
    width: "20%",
    paddingLeft: 9,
    fontSize: 9,
    fontWeight:"bold",
    color: "black",  
  },
  
  discount:{
    textTransform:"capitalize",
    width: "80%",
    paddingLeft: 9,
    fontSize: 9,
    fontWeight:"bold",
    color: "black",
    
  },
  totaldiscountprice:{
    textTransform:"capitalize",
    width: "20%",
    paddingLeft: 9,
    fontSize: 9,
    fontWeight:"bold",
    color: "black",
    
    
    
  },
  reciving:{
    textTransform:"capitalize",
    width: "80%",
    paddingLeft: 9,
    fontSize: 9,
    fontWeight:"bold",
    color: "white",
    backgroundColor:"#22598e",
    alignItems: "center",
    height: 27,
    fontStyle: "bold",
    paddingTop:8,
    
    
  },
  recivingamount:{
    textTransform:"capitalize",
    width: "20%",
    paddingLeft: 9,
    fontSize: 9,
    fontWeight:"bold",
    color: "white",
    backgroundColor:"#22598e",
    alignItems: "center",
    height: 27,
    fontStyle: "bold",
    paddingTop:8,
    
    
  }

});

const InvoiceTableFooter = ({ patientTrans,totalPrice,discountMain }) => {
  const totalprice = patientTrans
    .map((item) => parseInt(item.price))
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  // const totaldiscount = patientTrans
  //   .map((item) => parseInt(item.discount))
  //   .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  // const total = patientTrans
  //   .map((item) => parseInt(item.final_amount))
  //   .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  return (
    <>
    <View style={styles.row}>
      <Text style={styles.date}>Sub-Total</Text>
      {/* <Text style={styles.totalMrp}>
        {Number.parseFloat(totalprice).toFixed(2)}
      </Text> */}
      {/* <Text style={styles.totalDiscount}>
        {Number.parseFloat(totaldiscount).toFixed(2)}
      </Text> */}
      <Text style={styles.totalAmount}>
        {Number.parseFloat(totalprice).toFixed(2)}
      </Text>
    </View>
    <View style={styles.row}>
      <Text style={styles.discount}>Discount</Text>
      {/* <Text style={styles.}>
        {Number.parseFloat(totalprice).toFixed(2)}
      </Text> */}
      {/* <Text style={styles.totalDiscount}>
        {Number.parseFloat(totaldiscount).toFixed(2)}
      </Text> */}
      <Text style={styles.totaldiscountprice}>
        {discountMain}
      </Text>
    </View>
    <View style={styles.row}>
      <Text style={styles.reciving}>Grand Total</Text>
      {/* <Text style={styles.totalMrp}>
        {Number.parseFloat(totalprice).toFixed(2)}
      </Text> */}
      {/* <Text style={styles.totalDiscount}>
        {Number.parseFloat(totaldiscount).toFixed(2)}
      </Text> */}
      <Text style={styles.recivingamount}>
        {totalPrice}
      </Text>
    </View>
    </>
    
  );
};

export default InvoiceTableFooter;
