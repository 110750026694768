import React from "react";
import { View, StyleSheet, } from "@react-pdf/renderer";
import InvoiceTableHeader from "./InvoiceTableHeader";
import InvoiceTableRow from "./InvoiceTableRow";
import InvoiceTableBlankSpace from "./InvoiceTableBlankSpace";

// const tableRowsCount = 8;

const styles = StyleSheet.create({
  // tableContainer: {
  //   flexDirection: "row",
  //   flexWrap: "wrap",
  //   marginTop: 20,
  //   borderWidth: 1,
  //   borderColor: "#22598e",
  // },
});

const InvoiceItemsTable = ({ reportData, headerPrint }) => (
  <View style={styles.tableContainer}>
    <InvoiceTableHeader />

    <InvoiceTableRow reportData={reportData} headerPrint={headerPrint} />
    <InvoiceTableBlankSpace rowsCount={1} />
  </View>
);

export default InvoiceItemsTable;
