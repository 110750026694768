import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

// const borderColor = "#22598e";
const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    // borderBottomColor: "#22598e",
    // borderBottomWidth: 1,
    alignItems: "center",
    height: 25,
    fontWeight: "bold",
    flexGrow: 1,
  },

  groupName_serial: {
    fontSize: 10,
    width: "10%",
    paddingLeft: 9,
  },
  groupName: {
    fontSize: 10,
    width: "50%",

    paddingLeft: 9,
  },
  valueUi: {
    fontWeight: "extrabold",
    fontSize: 10,
   // textAlign: "center",
    width: "20%",
    // borderRightColor: borderColor,
    // borderRightWidth: 1,
  },

  discount: {
    width: "30%",
   // textAlign: "center",
    paddingLeft: 14,
    fontSize: 10,
    // borderRightColor: borderColor,
    // borderRightWidth: 1,
  },
  paidAmount: {
    width: "20%",
    //textAlign: "center",
    fontSize: 10,
    paddingLeft: 14,
  },
});

const InvoiceTableRow = ({ reportData }) => {
  console.log("report", reportData);
  const rows = reportData.map((item, index) => (
    <View style={styles.row} key={index}>
      <Text style={styles.groupName}>{item.routine_name}</Text>
      <Text style={styles.valueUi}>
        {item.value} {item.unit}
      </Text>
      <Text style={styles.discount}>{item.refrence}</Text>
    </View>
  ));
  
  return <Fragment>{rows}</Fragment>;
};

export default InvoiceTableRow;
