/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { postRequest, getRequest } from "../Constants/ApiCall";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button } from "@material-ui/core";
import { ThreeDots } from "react-loader-spinner";
import { IoMdPersonAdd } from "react-icons/io";
import AlreadyExistPatientModal from "../AlreadyExistPatientModal/Index";
import { useHistory } from "react-router-dom";

const Index = ({ open, close, getPatientData }) => {
  const [formError, setFormError] = useState({});
  const [openA, setOpenA] = useState(false);
  const [newExistData, setExistData] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [patientSearch, setPatientSearch] = useState([]);
  const [dataUpdate, setUpdateData] = useState(getPatientData);
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [closemodal, setCloseModal] = useState();
  const history = useHistory();
  const [userPatient, setUserPatient] = useState({
    //business: 3,
    business: 2,
    name: "",
    gender: "",
    age: "",
    mobile: "",
    age_type: "",
    city: "",
    //pincode: "0",
    address: "",
  });

  const Validation = () => {
    setFormError({});
    var formErrorData = {};
    var formValidated = true;

    if (!userPatient.name) {
      formErrorData["nameError"] = "Name is required";
      formValidated = false;
    }

    if (!userPatient.city) {
      formErrorData["cityError"] = "city is required";
      formValidated = false;
    }
    if (!userPatient.address) {
      formErrorData["addressError"] = "Address is required";
      formValidated = false;
    }

    if (!userPatient.age) {
      formErrorData["ageError"] = "Age is required";
      formValidated = false;
    }
    if (!userPatient.age_type) {
      formErrorData["ageTypeError"] = "This field is required";
      formValidated = false;
      // } else if (!/^[a-zA-Z]{2,40}( [a-zA-Z]{2,40})+$/.test(userPatient.name)) {
      //   formErrorData["nameError"] = "Name is invalid";
      //   formValidated = false;
    }
    // } else if (isNaN(userPatient.age) || userPatient.age > 100) {
    //   formErrorData["ageError"] = "Please enter valid age";
    //   formValidated = false;
    // }
    // if (!userPatient.pincode) {
    //   formErrorData["pincodeError"] = "Pincode is required";
    //   formValidated = false;
    // }
    if (!userPatient.gender) {
      formErrorData["genderError"] = "Gender is required";
      formValidated = false;
    }
    if (!userPatient.mobile) {
      formErrorData["mobileError"] = "Mobile No is required";
      formValidated = false;
    } else if (!/^(\+\d{1,3}[- ]?)?\d{10}$/.test(userPatient.mobile)) {
      formErrorData["mobileError"] = "Mobile No is invalid";
      formValidated = false;
    }
    setFormError(formErrorData);
    return formValidated;
  };

  const addNewPatient = async (e) => {
    e.preventDefault();

    let data = {
      name: userPatient.name,
      business: userPatient.business,
      gender: userPatient.gender,
      age: userPatient.age + userPatient.age_type,
      address: userPatient.address,
      mobile: userPatient.mobile,
      city: userPatient.city,
      pincode: userPatient.pincode,
    };
    if (Validation()) {
      setLoading(true);
      var res = await postRequest(
        "/patient/user",
        JSON.stringify(data),
        "POST",
        true
      );
      var responseData = await res.json();
      console.log("add_patient", responseData);
      console.log("Response", res);
      if (res.status === 200 || res.status === 201) {
        //   alert("Successfully Added New Patient ");

        setCloseModal(close(false));
        //  getPatientData();
        setUpdateData(getPatientData);
        //console.log("successfully Added New Patient", res.status);
        history.push("/lab/patients/entry-wise");
        toast.success("Successfully Added New Patient", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setUserPatient({ name: "" });
        setUserPatient({ age: "" });
        setUserPatient({ gender: "" });
        setUserPatient({ address: "" });
        setUserPatient({ mobile: "" });
        setUserPatient({ city: "" });
        setUserPatient({ pincode: "" });

        // window.location.reload(true);
        // history.push("/patient-entry");
      } else {
        console.log("something went wrong", res.status);
        setFormError({});
        var formErrorData = {};
        var formValidated = true;
        if (responseData.address) {
          formErrorData["addressError"] = responseData.address[0];
          // eslint-disable-next-line no-unused-vars
          formValidated = false;
        }
        setFormError(formErrorData);
      }
    }
  };
  const getPatientD = async () => {
    try {
      const response = await getRequest(`/patient`);
      const data = await response.json();
      console.log("res", response.status, data);
      if (response.status === 200 || response.status === 201) {
        setPatientSearch(data);
      } else {
        setPatientSearch([]);
      }
    } catch (err) {
      console.log(err);
    }
  };
  // console.log("searchpatient", patientSearch);
  const searchData = (val) => {
    console.log("val", val);
    const newSearch = [...patientSearch];
    const newData = newSearch.filter((item, index) =>
      newSearch[index]["mobile"].includes(val)
    );

    if (newData.length > 0) {
      setExistData(newData);
      setOpenA(true);
      //  history.push(`/lab-details/${newData[0].name}/${newData[0].id}`);
    }
    // console.log("newd", newData);
  };
  useEffect(() => {
    if (open) getPatientD();
  }, [open]);

  return (
    <div>
      <ToastContainer />
      <AlreadyExistPatientModal
        open={openA}
        close={setOpenA}
        newExistData={newExistData}
      />
      <Modal open={open} onClose={() => close(false)} center>
        <div className="container">
          <form className="pt-3" onSubmit={(e) => addNewPatient(e)}>
            <h2 className="col-sm-12">Add Patient</h2>
            <hr class="hr-primary" />
            <div
              style={{ padding: "2rem 1rem", background: "transparent" }}
              className="row jumbotron px-lg-5"
            >
              <div className="col-sm-6 form-group">
                <label>Name:</label>
                <input
                  type="text"
                  className="form-control calendar"
                  name="name-f"
                  id="name-f"
                  value={userPatient.name}
                  onChange={(e) =>
                    setUserPatient({ ...userPatient, name: e.target.value })
                  }
                  placeholder="Enter Name.."
                />
                <span className="small text-danger opacity-4">
                  {formError.nameError}
                </span>
              </div>

              <div className="col-sm-6 form-group">
                <label>
                  Gender:{" "}
                  {/* <span className="ml-2 small text-danger">
                   
                  </span> */}
                </label>
                <select
                  className="form-control custom-select calendar"
                  onChange={(e) =>
                    setUserPatient({ ...userPatient, gender: e.target.value })
                  }
                  name="gender"
                  value={userPatient.gender}
                >
                  <option selected disabled="">
                    Choose Gender...
                  </option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </select>
                <span className="small text-danger opacity-4">
                  {formError.genderError}
                </span>
              </div>
              <div className="col-sm-3 form-group">
                <label>Age: </label>
                <input
                  type="text"
                  className="form-control calendar "
                  name="dob"
                  id="dov"
                  value={userPatient.age}
                  onChange={(e) =>
                    setUserPatient({ ...userPatient, age: e.target.value })
                  }
                  placeholder=""
                />
                <span className="small text-danger opacity-4">
                  {formError.ageError}
                </span>
              </div>
              <div className="col-sm-3 form-group">
                <label>
                  Select:
                  {/* <span className="ml-2 small text-danger">
                   
                  </span> */}
                </label>
                <select
                  className="form-control custom-select calendar"
                  onChange={(e) =>
                    setUserPatient({ ...userPatient, age_type: e.target.value })
                  }
                >
                  <option selected disabled="">
                    select
                  </option>
                  <option value="Days">Day</option>
                  <option value="Months">Month</option>
                  <option value="Y">Year</option>
                </select>
                <span className="ml-2 small text-danger">
                  {formError.ageTypeError}
                </span>
              </div>

              <div className="col-sm-6 form-group">
                <label>Mobile No:</label>
                <input
                  type="text"
                  maxLength={10}
                  value={userPatient.mobile}
                  onBlur={(e) => searchData(e.target.value)}
                  onChange={(e) =>
                    setUserPatient({ ...userPatient, mobile: e.target.value })
                  }
                  namesome
                  UI
                  Bug
                  Fixing="mobile no"
                  className="form-control calendar"
                  placeholder="Enter here.."
                />
                <span className="small text-danger opacity-4">
                  {formError.mobileError}
                </span>
              </div>
              <div className="col-sm-6 form-group">
                <label htmlFor="pass2">City:</label>
                <input
                  type="text"
                  name="city"
                  onChange={(e) =>
                    setUserPatient({ ...userPatient, city: e.target.value })
                  }
                  value={userPatient.city}
                  className="form-control calendar"
                  placeholder="Enter here.."
                />
                <span className="small text-danger opacity-4">
                  {formError.cityError}
                </span>
              </div>
              <div className="col-sm-6 form-group">
                <label>Pincode:</label>
                <input
                  type="text"
                  className="form-control calendar"
                  name="p_code"
                  value={userPatient.pincode}
                  id="p_code"
                  onChange={(e) =>
                    setUserPatient({ ...userPatient, pincode: e.target.value })
                  }
                  placeholder="Enter Pincode.."
                />

                {/* <span className="small text-danger opacity-4">
                  {formError.pincodeError}
                </span> */}
              </div>
              <div className="col-sm-12 form-group">
                <label htmlFor="pass2">Address:</label>
                <textarea
                  cols="5"
                  onChange={(e) =>
                    setUserPatient({ ...userPatient, address: e.target.value })
                  }
                  value={userPatient.address}
                  name="address"
                  type="textarea"
                  className="form-control calendar"
                  placeholder=""
                />
                <span className="small text-danger opacity-4">
                  {formError.addressError}
                </span>
              </div>

              <div className="col-sm-12 form-group mb-0">
                {!loading ? (
                  <Button
                    type="submit"
                    className="float-right mt-2 "
                    color="primary"
                    variant="outlined"
                  >
                    <IoMdPersonAdd style={{ marginRight: "4px" }} />
                    Add Patient
                  </Button>
                ) : (
                  <Button
                    //   type="submit"
                    className="float-right mt-2 "
                    color="primary"
                    variant="outlined"
                  >
                    <ThreeDots color="blue" height={25} width={80} />
                  </Button>
                )}
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default Index;
