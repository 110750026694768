import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import signinStyles from "../Login/Login.module.css";
import { api_url } from "../../Components/Constants/constant";
import { InputLabel } from "@material-ui/core";
import { FormControl } from "@material-ui/core";
import { Input } from "@material-ui/core";
import { InputAdornment } from "@material-ui/core";
import { AccountCircle } from "@material-ui/icons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ThreeDots } from "react-loader-spinner";
import { IconButton } from "@material-ui/core";
import { VisibilityOff } from "@material-ui/icons";
import { Button } from "@material-ui/core/";
import { Visibility } from "@material-ui/icons";

export default function Login() {
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState();
  const [usernameError, setUsernameError] = useState();
  // eslint-disable-next-line no-unused-vars
  const [passwordError, setPasswordError] = useState();
  const [redirectUrl] = useState("/lab/patients/entry-wise");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const submit = async (e) => {
    const url = `${api_url}/auth/login/`;
    console.log("url", url);
    console.log("username", username, "password", password);
    let data = { username: username, password: password };

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    const responseJson = await response.json();
    console.log("res", responseJson);

    if (response.status === 200 || response.status === 201) {
      toast.success("Login Successfully", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setLoading(true);
      localStorage.setItem("userDetail", responseJson.email);
      console.log("logged in successfully", response.status, responseJson);
      localStorage.setItem(
        "loginToken",
        JSON.stringify(responseJson["tokens"])
      );

      // setCookies(await JSON.stringify(responseJson["tokens"]));

      setTimeout(() => {
        setError("");
      }, 2000);
    } else {
      toast.error("Login Failed!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      console.log("logged in failed", response.status, responseJson);
      setError(responseJson.detail);
      responseJson.username?.length > 0
        ? setUsernameError("Invalid Credentials!")
        : setUsernameError("Invalid Credentials!");
      responseJson.password?.length > 0
        ? setPasswordError("false")
        : setPasswordError("Password");
    }
  };

  const isAuthenticated = localStorage.getItem("loginToken");
  if (isAuthenticated) {
    console.log("authenticated");
    return <Redirect to={redirectUrl} />;
  } else {
    return (
      <div>
        <ToastContainer />

        <div className={signinStyles.signin}>
          <div className={signinStyles.signin__bg} />
          <div className={signinStyles.signin__container}>
            <div className={signinStyles.signin__shadow}>
              <h1 className={signinStyles.signin__title}>Sign In</h1>

              <div className={signinStyles.group}></div>

              <div className={signinStyles.group}>
                <FormControl
                  style={{ width: "78%", marginBottom: "20px" }}
                  variant="standard"
                >
                  <InputLabel htmlFor="standard-adornment-password">
                    Username
                  </InputLabel>
                  <Input
                    id="standard-adornment-password"
                    onChange={(e) => setUsername(e.target.value)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton aria-label="toggle password visibility">
                          <AccountCircle />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </div>

              <div className={signinStyles.group}>
                <FormControl style={{ width: "78%" }} variant="standard">
                  <InputLabel htmlFor="standard-adornment-password">
                    Password
                  </InputLabel>
                  <Input
                    id="standard-adornment-password"
                    type={showPassword ? "text" : "password"}
                    onChange={(e) => setPassword(e.target.value)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </div>

              <div
                style={{ textAlign: "center" }}
                className={signinStyles.errorMessage}
              >
                {usernameError}{" "}
              </div>
              <div className={signinStyles.signin__btn_container}>
                <div className={signinStyles.signin__btn}>
                  {!loading ? (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={(e) => submit(e)}
                      className={signinStyles.custom_button}
                    >
                      Sign In
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      // onClick={(e) => submit(e)}
                      className={signinStyles.custom_button}
                    >
                      <ThreeDots color="white" height={50} width={50} />
                    </Button>
                  )}
                </div>
              </div>
              <br />
              <br />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
